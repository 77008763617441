import {Component, OnInit} from "@angular/core";
import {FormArray, FormGroup} from "@angular/forms";
import {IconsService} from "@amlCore/services";
import {ActivatedRoute, Router} from "@angular/router";
import {CommonService, DocumentBase, DocumentBaseService, DocumentTypeEnum} from "../../../../../../documentForm";

@Component({
  selector: "app-armfm-refuse-contract-5861U-v1",
  templateUrl: "./refuse-contract-5861U-v1.html",
  styleUrls: ['../../../../../../documentForm/base/documentForm.component.scss']
})
export class RefuseContract5861Uv1 extends DocumentBase implements OnInit {
  documentType = DocumentTypeEnum.REFUSE_CONTRACT;
  form: FormGroup;

  svedPredstavIndex: number;

  constructor(
    protected documentBaseService: DocumentBaseService,
    protected iconsService: IconsService,
    protected commonSrv: CommonService,
    protected activatedRoute: ActivatedRoute,
    protected router: Router
  ) {
    super(documentBaseService, iconsService, commonSrv, activatedRoute, router);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  initForm(): void {
    const refContract = this.documentBaseService.getSvedPredstavForm(
      this.d.informChast?.svedPredstav, this.documentType
    );
      this.fGroup("informChast").addControl("svedPredstav", refContract);
  }

  /**
   * Подготовка начальных данных для формы создания документа
   */
  prepareInitDocumentData(): any{
    let documentData = this.commonSrv.prepareInitDocumentData(this.documentType);
    documentData.document.informChast.svedPredstav = [];
    return documentData;
  }

  onSelectedPredstavRow(index) {
    this.svedPredstavIndex = index;
  }

  get svedPredstavForm(): FormArray {
    return this.form.get("informChast.svedPredstav") as FormArray;
  }

  get svedOtkazForm(): FormArray {
    return this.svedPredstavForm
      ?.at(this.svedPredstavIndex)
      ?.get("svedOtkaz") as FormArray;
  }
}
