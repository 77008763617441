import {Component, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ITable} from "@amlCore/components";
import {DocumentSubForm} from "@amlDocument/base";
import {DocumentBaseService} from "@amlDocument/services";
import {ViewSvedOperComponent} from "./view-sved-oper.component";
import {DocumentTypeEnum} from "../../../enums";

@Component({
  selector: 'app-arm-doc-table-sved-oper',
  templateUrl: './table-sved-oper.component.html',
  styleUrls: ["../../../base/documentForm.component.scss"],
})
export class TableSvedOperComponent extends DocumentSubForm implements OnInit, OnChanges, ITable {
  documentType = DocumentTypeEnum.OPERATIONS; // todo сделать универсальным
  viewForm = ViewSvedOperComponent;

  constructor(protected readonly modalService: NgbModal,
              private readonly docSrv: DocumentBaseService) {
    super(modalService);
  }

  currentGetFormGroup = (data) => this.docSrv.getOperatsiiaFm.call(this.docSrv, data);

  ngOnInit() {
    super.ngOnInit();
  }

  ngOnChanges(change: SimpleChanges) {
    super.ngOnChanges(change);
  }
}
