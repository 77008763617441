import {Component, Input} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {DocumentComponentBaseSub} from "@amlDocument/base";

@Component({
    selector: 'app-arm-doc-view-info-credit-org-to-auth-body',
    templateUrl: './info-credit-org-to-auth-body.component.html',
    styleUrls: ["../../../base/documentForm.component.scss"],
})
export class InfoCreditOrgToAuthBodyComponent extends DocumentComponentBaseSub {
    @Input() formGroupApp: FormGroup;
    @Input() documentData: any;
}
