import {Component, Input} from "@angular/core";
import {FormGroup} from "@angular/forms";
import {DocumentComponentBaseSub} from "@amlDocument/base";
import {DocumentBaseService} from "@amlDocument/services";

@Component({
    selector: "app-arm-doc-view-fio[formGroupApp][isReadOnly][submitted]",
    templateUrl: "./view-fio.component.html",
    styleUrls: ["../../../base/documentForm.component.scss"]
})
export class ViewFioComponent extends DocumentComponentBaseSub {
    @Input() formGroupApp: FormGroup;
    @Input() documentData: any;
    @Input() withFioStroka = true;
    @Input() title : string;

    constructor(readonly docSrv: DocumentBaseService) {
        super();
    }

    get isNullFieldsFio() {
        return !this.field('fam')?.value &&
               !this.field('imia')?.value &&
               !this.field('otch')?.value;
    }

    getCondition(fieldName: string): boolean {
        return !this.field('fiostroka')?.value || 
        (this.field('fiostroka')?.value && this.field(fieldName)?.value);
    }
}
