import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ITableItem} from "@amlCore/components";
import {DocumentComponentBaseSub} from "@amlDocument/base";
import {DocumentBaseService} from "@amlDocument/services";
import {DocumentTypeEnum} from "../../../enums";

@Component({
  selector: 'app-arm-doc-osnov-sov-oper',
  templateUrl: './view-osnov-sov-oper.component.html',
  styleUrls: ["../../../base/documentForm.component.scss"],
})
export class ViewOsnovSovOperComponent extends DocumentComponentBaseSub implements ITableItem, OnInit {
  formGroupApp: FormGroup;
  documentData: any;
  titlePanel = `Основание совершения операции`;
  titleNaimDoc: string;
  currentGetForm = (param) => {
    const oldForm = this.docSrv.getOsnovanieOp(param, null, this.documentType);
    if (this.is5392) {
      oldForm.removeControl('kodDok');
      oldForm.removeControl('inoeNaimDok');
      oldForm.addControl('naimDok', new FormControl(null, [Validators.maxLength(250), Validators.required]));
      oldForm.patchValue(param);
    }
    return oldForm;
  }

  constructor(private readonly docSrv: DocumentBaseService) {
    super();
  }

  ngOnInit(): void {
    this.titleNaimDoc = this.is5392 ? 'Наименование вида документа'
                      : this.isOperationsDetail ? 'Наименование иного документа'
                      : 'Наименование иного вида документа';
  }

  isValidItem(): boolean {
    return this.formGroupApp.valid;
  }

  get is5392() {
    return this.documentType === DocumentTypeEnum.OES_OTKAZ_GOZ;
  }

  get isOperationsDetail() {
    return this.documentType === DocumentTypeEnum.OPERATIONS;
  }

  onTextInput(e) {
    const textarea = e.target;
    textarea.style.height = '';
    textarea.style.height = Math.min(textarea.scrollHeight + 2, 200) + "px";
  }
}
