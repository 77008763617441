import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ResourceService, DictionaryService, TableService, StringDateAdapterService } from '@amlCore/services';
import { AmlStrategKoFilter } from 'src/app/core/models';
import { BaseFilterController } from '../../BaseFilterController';



@Component({
  selector: 'app-aml-strateg5392u-draft-list-filter',
  templateUrl: './aml-strateg5392u-draft-list-filter.component.html'
})
export class AmlStrateg5392uDraftListFilterComponent   extends BaseFilterController
implements OnInit
{
public filterObjectModel = new AmlStrategKoFilter();
placeholder = 'по идентификатору документа, по имени xml-документа';
creatorList = [];

constructor(
  private readonly fb: FormBuilder,
  private readonly resourceSrv: ResourceService,
  private dictSrv: DictionaryService,
  protected tableSrv: TableService,
  public dateSrv: StringDateAdapterService,
  protected readonly activatedRoute: ActivatedRoute,
) {
  super(tableSrv, activatedRoute);
}

ngOnInit() {
  this.init();
}

/**
 * Метод создания формы
 * @return FormGroup
 */
public createFormModel() {
  const formConfig = new AmlStrategKoFilter();
  return this.fb.group(formConfig);
}

getStartFilterModel(): AmlStrategKoFilter {
  return {
    documentType: null,
    documentStatus: null,
  } as AmlStrategKoFilter;
}

/**
 * Описания полей
 */
 public getFilterDescription(): any {
  return {
    documentType: 'Документ',
    documentStatus: 'Статус',
    dateFrom: 'Дата направления С',
    dateTo: 'Дата направления По',
    creatorId: 'Владелец документа',
    createDateFrom: 'Дата импорта C',
    createDateTo: 'Дата импорта По',
  };
}

// todo вынос на сервер или энум если оставим тут
docTypes = [
  {id: 17, name: 'OTKAZ_GOZ', title: '5392-У. ОЭС об отказе ГОЗ'}
];

docStatuses = [
  {id: 0, name: 'CREATED', title: 'Создан'},
];

get users() {
  return this.dictSrv.remoteDict.strateg.list || [];
}
}
