import {ChangeDetectorRef, Directive, Input, OnChanges, OnDestroy, OnInit, Optional, TemplateRef, ViewContainerRef} from '@angular/core';
import {AbstractControl, FormGroup, FormGroupDirective} from "@angular/forms";

/**
 * Директория для всех FormGroup отображающихся по условию (текущий)
 * @param isShow - состояние формы (показан/скрыт)
 */
@Directive({selector: '[appShowFormGroup]'})
export class ShowFormGroupDirective implements OnInit, OnChanges, OnDestroy {
  @Input() set appShowFormGroup(show: boolean) {
    this.isShow = show;
  }
  @Input() set appShowFormGroupIsReadOnly(isReadOnly: boolean) {
    this.isReadOnly = isReadOnly;
  }

  private isShow: boolean;
  private isReadOnly: boolean;
  private formGroup: FormGroup;

  constructor(private templateRef: TemplateRef<any>,
              private viewContainer: ViewContainerRef,
              private change: ChangeDetectorRef,
              @Optional() private parentForm: FormGroupDirective) { }

  ngOnInit() {
      this.initFormGroup();
      this.showFormGroup();
  }

  ngOnChanges() {
    this.initFormGroup();
    if (this.formGroup) this.showFormGroup();
  }

  ngOnDestroy() {
    this.viewContainer.clear();
  }

  private showFormGroup() {
    if (this.isShow) {
      if (!this.isReadOnly) this.enableControls(this.formGroup);
      this.showTemplate(true);
    } else {
      this.showTemplate(false);
      if (!this.isReadOnly) this.disableControls(this.formGroup);
    }
  }

  private initFormGroup() {
    // отображаем и сразу скрываем компонент чтобы получить атрибут formGroupName (если есть)
    const formGroupName =
      this.viewContainer.createEmbeddedView(this.templateRef)
        .rootNodes[0].getAttribute('formGroupName');
    this.viewContainer.clear();
    const formGroupFromName = this.parentForm?.form?.get(formGroupName) as FormGroup;
    this.formGroup = formGroupFromName ?? this.parentForm?.form;
  }

  private showTemplate(isShow: boolean) {
    isShow
      ? this.viewContainer.createEmbeddedView(this.templateRef)
      : this.viewContainer.clear();
  }

  /**
   * Всё выключаем и чистим value
   */
  private disableControls(abstractControl: FormGroup): void {
    if (abstractControl.enabled) {
      abstractControl.reset({ onlySelf: true });
      abstractControl.disable({ onlySelf: true });
    }
  }

  private enableControls(abstractControl: FormGroup): void {
    if (abstractControl.disabled)
      abstractControl.enable({ onlySelf: true });
  }

  private isFieldReq(control: AbstractControl): boolean  {
    return Boolean(control?.validator && control.validator({} as AbstractControl)?.required);
  }
}
