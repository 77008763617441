import {Component, OnInit, ChangeDetectorRef} from "@angular/core";
import {FormBuilder} from "@angular/forms";
import {FmDraftFilter} from "@amlCore/models";
import {DictionaryService, StringDateAdapterService, TableService} from "@amlCore/services";
import {ActivatedRoute} from "@angular/router";
import {BaseFilterController} from "../../BaseFilterController";

@Component({
  selector: "app-elMsgFm-sended-list-filter",
  templateUrl: "./elMsgFmSendedListFilter.component.html",
})

export class ElMsgFmSendedListFilterComponent extends BaseFilterController implements OnInit {
  public filterObjectModel = new FmDraftFilter();
  placeholder = 'Номер запроса, имя xml-документа';

  constructor(private readonly fb: FormBuilder,
              private readonly _changeDetectorRef: ChangeDetectorRef,
              protected tableSrv: TableService,
              private dictSrv: DictionaryService,
              public dateSrv: StringDateAdapterService,
              protected readonly activatedRoute: ActivatedRoute) {
    super(tableSrv, activatedRoute);
  }

  /**
   * Fix error type ExpressionChangedAfterItHasBeenCheckedError
   */
  ngAfterViewChecked(): void {
    this._changeDetectorRef.detectChanges();
  }

  ngOnInit() {
    this.init();
  }

  /**
   * Метод создания формы
   * @return FormGroup
   */
  public createFormModel() {
    const formConfig = new FmDraftFilter();

    return this.fb.group(formConfig);
  }

  getStartFilterModel(): FmDraftFilter {
    return {
      documentType: null,
    } as FmDraftFilter;
  }

  /**
   * Описания полей
   */
  public getFilterDescription(): any {
    return {
      documentType: 'Документ',
      documentStatus: 'Статус',
      dateFrom: 'Дата получения С',
      dateTo: 'Дата получения По',
      ticketDateFrom: 'Дата квитанции на запрос С',
      ticketDateTo: 'Дата квитанции По',
      requestDateFrom: 'Дата запроса С',
      requestDateTo: 'Дата запроса По',
      creatorId: 'Владелец',
    };
  }
  // todo вынос на сервер или энум если оставим тут
  docTypes = [
    {id: 9, name: 'P407_REQUEST', title: 'Квитанция о принятии/непринятии электронного сообщения Росфинмониторинга'},
    {id: 10, name: 'P407_ANSWER', title: 'Электронное сообщение'},
  ]
  docStatuses = [
    {id: 2, name: 'SENDED', title: 'Отправлен'},
    {id: 99, name: 'ACCEPTEDTU', title: 'Принят ТУ'},
    {id: 98, name: 'REJECTEDTU', title: 'Отвергнут ТУ'},
    {id: 4, name: 'ACCEPTEDPART', title: 'Принят частично'},
    {id: 5, name: 'REJECTED', title: 'Отвергнут'},
    {id: 6, name: 'UPLOADEDSHIPPING', title: 'Выгружен для отправки'},
  ]

  get users() {
    return this.dictSrv?.remoteDict?.usersSvetofor?.list || [];
  }
}
