import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ITable} from "@amlCore/components";
import {DocumentSubForm} from "@amlDocument/base";
import {DocumentBaseService} from "@amlDocument/services";
import {ViewDopClientInfoComponent} from "./view-dop-client-info.component";
import {DocumentTypeEnum} from "../../../enums";

@Component({
  selector: 'app-arm-doc-table-dop-client-info',
  templateUrl: './table-dop-client-info.html',
  styleUrls: ["../../../base/documentForm.component.scss"],
})
export class TableDopClientInfoComponent extends DocumentSubForm implements OnInit, OnChanges, ITable {
  @Input() readonly documentType: DocumentTypeEnum;
  viewForm = ViewDopClientInfoComponent;

  constructor(protected readonly modalService: NgbModal,
              private readonly docSrv: DocumentBaseService) {
    super(modalService);
  }

  currentGetFormGroup = (data) => this.docSrv.getBenefitsiar.call(this.docSrv, data);
  childGetFormGroup = (data) => this.docSrv.getSvedEIO.call(this.docSrv, data);

  ngOnInit() {
    super.ngOnInit();
  }

  ngOnChanges(change: SimpleChanges) {
    super.ngOnChanges(change);
  }
}
