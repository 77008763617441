import {Directive, Input, OnChanges, OnInit} from "@angular/core";
import {FormControl, ValidatorFn} from "@angular/forms";

interface IOptions {
    // какой FormControl нужно менять
    control: FormControl;
    // валидаторы для изменения
    validators: ValidatorFn | ValidatorFn[];
    // валидаторы для замены, если нужно менять
    secondValidators?: ValidatorFn | ValidatorFn[];
    // условие, при котором нужно менять валидаторы
    condition: boolean;
}

@Directive({ selector: "[appChangeValidationFormControl]" })
export class ChangeValidationFormControlDirective implements OnInit, OnChanges {
    @Input("options") options: IOptions[];

    ngOnInit(): void {
        this.options.forEach((option) => this.changeControlValidators(option));
    }

    ngOnChanges(): void {
        this.options.forEach((option) => this.changeControlValidators(option));
    }

    changeControlValidators(option: IOptions): void {
        const setValidators = (validator: ValidatorFn | ValidatorFn[]) => {
          setTimeout(() => {
            option.control.setValidators(validator);
            option.control.updateValueAndValidity();
          }, 0);
        };
        // Если нужно, чтобы в зависимости от состояния был определенный валидатор
        if(option.secondValidators?.length > 0){
          option.condition
            ? setValidators(option.validators)
            : setValidators(option.secondValidators);
        } else {
          option.condition
            ? setValidators(option.validators)
            : option.control.clearValidators();
          if (!option.condition) option.control.updateValueAndValidity();
        }
    }
}
