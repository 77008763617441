import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ITableItem, TableEditorComponent} from "@amlCore/components";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ConfirmComponent} from "@amlCore/modals";
import * as selectItems from '../../../../../../json/selectItems.json';
import {DocumentComponentBaseSub} from "@amlDocument/base";
import {DocumentBaseService} from "@amlDocument/services";
import {DocumentTypeEnum} from "../../../enums";
import {ICodeName} from "../../../../../dictionaries";
import { delay, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
    selector: "app-arm-doc-view-client-info",
    templateUrl: "./view-client-info.component.html",
    styleUrls: ["../../../base/documentForm.component.scss"],
})
export class ViewClientInfoComponent extends DocumentComponentBaseSub implements OnInit, ITableItem, OnDestroy {
    formGroupApp: FormGroup;
    documentData: any;
    initialClientType: number;
    titlePanel = `Сведения об участнике операции (сделки)`;
    childTitlePanel = `Сведения об участнике операции (сделки)`;
    clientSelectItems = selectItems.clientInfo;
    priznUchastnikaItemsForQrefStatic: Array<ICodeName>;
    priznakIdentifikatsiia: Array<ICodeName>;
    ogrniuLValidators = [Validators.required, Validators.maxLength(500)];
    secondOgrniuLValidators = [Validators.maxLength(500)];
    private adrRegFields = ['dom', 'indeks', 'kodOKSM', 'kodSubieektaPoOKATO', 'korp', 'of', 'punkt', 'ulitsa', 'rajon']
    private fioFlipFields = ['fam', 'imia', 'otch']
    private destroy$ = new Subject();
    constructor(readonly docSrv: DocumentBaseService,
                private readonly modalService: NgbModal) {
        super();
    }

    ngOnInit() {
        this.initialClientType = this.clientType;
        this.initReqControls();
        this.priznUchastnikaItemsForQrefStatic = (this.isRefOperations || this.isSuspiciousActivity)
            ? this.clientSelectItems.priznUchastnika.slice(0, 2)
            : this.clientSelectItems.priznUchastnika;
        this.priznakIdentifikatsiia = this.isRefOperations
        ? this.clientSelectItems.priznakIdentifikatsiia.slice(0, 2)
        : this.clientSelectItems.priznakIdentifikatsiia;

        (this.formGroupApp?.get('tipUchastnika') ?? this.formGroupApp?.get('tipKlienta')).valueChanges.pipe(
          takeUntil(this.destroy$),
          ).subscribe(value => {
            this.setupEnableFormGroup(value)
        });
    }

    currentGetForm = (data) => this.isSuspiciousActivity
        ? this.docSrv.getKlientSa(data)
        : this.docSrv.getUchastnikOpFm(data)
    childGetForm = (data) => this.docSrv.getUchastnik(data);

    isValidItem(): boolean {
        switch (this.documentType) {
            case DocumentTypeEnum.SUSPICIOUS_ACTIVITY:
                return this.isFormGroupValidExcludingControlsPath([
                    'klientFLIP.benefitsiarFLIP',
                    'klientINBOIuL.benefitsiarINBOIuL',
                    'klientIuL.benefitsiarIuL',
                    'klientIuL.svedEIO'
                ]);

            case DocumentTypeEnum.OPERATIONS:
                return this.isFormGroupValidExcludingControlsPath([
                    'uchastnikFLIP.benefitsiarFLIP',
                    'uchastnikINBOIuL.benefitsiarINBOIuL',
                    'uchastnikIuL.benefitsiarIuL',
                    'uchastnikIuL.svedEIO'
                ]);

            case DocumentTypeEnum.REFUSE_OPERATION:
                return this.formGroupApp.valid;

            default:
                console.warn('Валидация определена некорректно! Следует расширить типы поддерживаемых документов');
                return false;
        }
    }

    beforeSaveCheck(context: TableEditorComponent): boolean {
        if (!this.initialClientType || this.initialClientType == this.clientType) {
            return true
        }
        const modal = this.modalService.open(ConfirmComponent, {
            windowClass: "top-modal-editor",
            backdropClass: "top-modal-editor",
        });
        modal.componentInstance.text =
            "Вы изменили «Тип участника операции (сделки)»! Все данные о предыдущем участнике будут удалены!";
        modal.componentInstance.title = "Предупреждение";
        modal.componentInstance.okBtn = "Удалить";
        modal.result.then(
            (data) => this.submitChangeClientType(data, context),
            (cancel) => this.submitChangeClientType(cancel)
        );
        return false;
    }

    get clientType(): number {
        return Number(this.formGroupApp?.value?.tipUchastnika ?? this.formGroupApp?.value?.tipKlienta);
    }

    get strClientType(): string {
        const clientType = this.clientType;
        if (clientType === 1) return "IuL";
        if (clientType === 2 || clientType === 3 || clientType === 4)
            return "FLIP";
        if (clientType === 5) return "INBOIuL";
    }

    get isPrefixUchastnik() {
        return this.documentType === DocumentTypeEnum.OPERATIONS ||
            this.documentType === DocumentTypeEnum.NFO_OPERATIONS;
    }

    // Проверяем тип документа

    get isOperations() {
        return this.documentType === DocumentTypeEnum.OPERATIONS;
    }

    get isRefOperations() {
        return this.documentType === DocumentTypeEnum.REFUSE_OPERATION;
    }

    get isSuspiciousActivity() {
        return this.documentType === DocumentTypeEnum.SUSPICIOUS_ACTIVITY;
    }

    // Получаем путь, по которому нужно обращаться в зависимости от типа документа

    get pathPriznakIdentifikatsiiaIuL(): string {
        return  this.isSuspiciousActivity
                ? "klientIuL.priznakIdentifikatsiiaIuL"
                : "uchastnikIuL.priznakIdentifikatsiiaIuL";
    }

    get pathPriznakIdentifikatsiiaINBOIuL(): string {
        return  this.isSuspiciousActivity
                ? "klientINBOIuL.priznakIdentifikatsiiaINBOIuL"
                : "uchastnikINBOIuL.priznakIdentifikatsiiaINBOIuL";
    }

    get pathSvedIuL(): string {
        return this.isPrefixUchastnik
            ? "uchastnikIuL.svedIuL"
            : this.isSuspiciousActivity
                ? "klientIuL.svedIuL"
                : "svedIuL";
    }

    get pathSvedFLIP(): string {
        return this.isPrefixUchastnik
            ? "uchastnikFLIP.svedFLIP"
            : this.isSuspiciousActivity
                ? "klientFLIP.svedFLIP"
                : "svedFLIP";
    }

    get pathSvedINBOIuL(): string {
        return this.isPrefixUchastnik
            ? "uchastnikINBOIuL.svedINBOIuL"
            : this.isSuspiciousActivity
                ? "klientINBOIuL.svedINBOIuL"
                : "svedINBOIuL";
    }

    get dataSvedIuL() {
        return this.isPrefixUchastnik
            ? this.documentData?.uchastnikIuL?.svedIuL
            : this.documentData?.svedIuL ??
            this.documentData?.klientIuL?.svedIuL;
    }

    get dataSvedFLIP() {
        return this.isPrefixUchastnik
            ? this.documentData?.uchastnikFLIP?.svedFLIP
            : this.documentData?.svedFLIP ??
            this.documentData?.klientFLIP?.svedFLIP;
    }

    get dataSvedINBOIuL() {
        return this.isPrefixUchastnik
            ? this.documentData?.uchastnikINBOIuL?.svedINBOIuL
            : this.documentData?.svedINBOIuL ??
            this.documentData?.klientINBOIuL?.svedINBOIuL;
    }

    private submitChangeClientType(event, context?: TableEditorComponent) {
        if (event) {
            switch (String(this.clientType)) {
                case '1':
                    this.formGroup(this.pathSvedINBOIuL)?.reset()
                    this.formGroup(this.pathSvedFLIP)?.reset()
                    break;
                case '5':
                    this.formGroup(this.pathSvedIuL)?.reset()
                    this.formGroup(this.pathSvedFLIP)?.reset()
                    break;
                case '0':
                    this.formGroup(this.pathSvedINBOIuL)?.reset()
                    this.formGroup(this.pathSvedFLIP)?.reset()
                    this.formGroup(this.pathSvedIuL)?.reset()
                    break;
                default:
                    this.formGroup(this.pathSvedINBOIuL)?.reset()
                    this.formGroup(this.pathSvedIuL)?.reset()
            }
            this.initialClientType = this.clientType;
            context.save();
        }
    }

    /**
     * Отдельно дважды выставляется статус DISABLED т.к данный formGroup имеет странную особенность, когда все
     * контролы внутри уже имеют статус DISABLED, но сам formGroup имеет статус VALID или INVALID. Со второго раза
     * отрабатывает корректно
     */
    private disableSvedFLIP(): void {
        this.formGroup(this.pathSvedFLIP)?.disable()
        this.formGroup(this.pathSvedFLIP)?.disable()
    }

    private disableAllAddControls(): void {
        this.disableIul()
        this.disableFLIP()
        this.disableINBOIul()
    }

    setupEnableFormGroup(value: string) {
        if (value === '1') {
            this.isSuspiciousActivity && this.formGroup('klientIuL')?.enable()
            this.formGroup(this.pathSvedIuL)?.enable()
            this.formGroup(this.pathSvedINBOIuL)?.disable()
            this.disableSvedFLIP()
            this.disableFLIP()
            this.disableINBOIul()
            this.field(this.pathPriznakIdentifikatsiiaIuL)?.enable();
            this.field(this.pathPriznakIdentifikatsiiaINBOIuL)?.disable();
        }
        if (value === '2' || value === '3' || value === '4') {
            this.isSuspiciousActivity && this.formGroup('klientFLIP')?.enable()
            this.enableSvedFlip(value)
            this.formGroup(this.pathSvedINBOIuL)?.disable()
            this.formGroup(this.pathSvedIuL)?.disable()
            this.disableIul()
            this.disableINBOIul()
            this.field(this.pathPriznakIdentifikatsiiaIuL)?.disable();
            this.field(this.pathPriznakIdentifikatsiiaINBOIuL)?.disable();
        }
        if (value === '5') {
            this.isSuspiciousActivity && this.formGroup('klientINBOIuL')?.enable()
            this.formGroup(this.pathSvedINBOIuL)?.enable()
            this.formGroup(this.pathSvedIuL)?.disable()
            this.disableSvedFLIP()
            this.disableIul()
            this.disableFLIP()
            this.field(this.pathPriznakIdentifikatsiiaIuL)?.disable();
            this.field(this.pathPriznakIdentifikatsiiaINBOIuL)?.enable();
        }
        if (value === '0') {
            this.disableAllAddControls()
            this.formGroup(this.pathSvedINBOIuL)?.disable()
            this.formGroup(this.pathSvedIuL)?.disable()
            this.disableSvedFLIP()
            this.field(this.pathPriznakIdentifikatsiiaIuL)?.disable();
            this.field(this.pathPriznakIdentifikatsiiaINBOIuL)?.disable();
        }
    }

    initReqControls() {
        const isSuspiciousActivity = this.isSuspiciousActivity
        if (!this.isRefOperations) {
            switch (String(this.initialClientType)) {
                case '1':
                    if (isSuspiciousActivity) {
                        this.addControl('klientFLIP', this.docSrv.getUchastnikFLIP(null, [], DocumentTypeEnum.SUSPICIOUS_ACTIVITY))
                        this.addControl('klientINBOIuL', this.docSrv.getUchastnikINBOIuL(null))
                    } else {
                        this.addControl('uchastnikFLIP', this.docSrv.getUchastnikFLIP(null))
                        this.addControl('uchastnikINBOIuL', this.docSrv.getUchastnikINBOIuL(null))
                    }
                    break;
                case '5':
                    if (isSuspiciousActivity) {
                        this.addControl('klientFLIP', this.docSrv.getUchastnikFLIP(null, [], DocumentTypeEnum.SUSPICIOUS_ACTIVITY))
                        this.addControl('klientIuL', this.docSrv.getUchastnikIuL(null))
                    } else {
                        this.addControl('uchastnikFLIP', this.docSrv.getUchastnikFLIP(null))
                        this.addControl('uchastnikIuL', this.docSrv.getUchastnikIuL(null))
                    }
                    break;
                case '0':
                    if (isSuspiciousActivity) {
                        this.addControl('klientFLIP', this.docSrv.getUchastnikFLIP(null, [], DocumentTypeEnum.SUSPICIOUS_ACTIVITY))
                        this.addControl('klientIuL', this.docSrv.getUchastnikIuL(null))
                        this.addControl('klientINBOIuL', this.docSrv.getUchastnikINBOIuL(null))
                    } else {
                        this.addControl('uchastnikFLIP', this.docSrv.getUchastnikFLIP(null))
                        this.addControl('uchastnikIuL', this.docSrv.getUchastnikIuL(null))
                        this.addControl('uchastnikINBOIuL', this.docSrv.getUchastnikINBOIuL(null))
                    }
                    break;
                default:
                    if (isSuspiciousActivity) {
                        this.addControl('klientIuL', this.docSrv.getUchastnikIuL(null))
                        this.addControl('klientINBOIuL', this.docSrv.getUchastnikINBOIuL(null))
                    } else {
                        this.addControl('uchastnikFLIP', this.docSrv.getUchastnikFLIP(null))
                        this.addControl('uchastnikIuL', this.docSrv.getUchastnikIuL(null))
                        this.addControl('uchastnikINBOIuL', this.docSrv.getUchastnikINBOIuL(null))
                    }
            }
        }
    }

    disableIul() {
        this.formGroup('uchastnikIuL')?.disable()
        this.formGroup('klientIuL')?.disable()
    }

    disableFLIP() {
        this.formGroup('uchastnikFLIP')?.disable()
        this.formGroup('klientFLIP')?.disable()
    }

    disableINBOIul() {
        this.formGroup('uchastnikINBOIuL')?.disable()
        this.formGroup('klientINBOIuL')?.disable()
    }

    addControl(controlName: string, controlData: FormGroup) {
        this.formGroupApp?.addControl(controlName, controlData)
    }

    getOrgnUl(): FormControl {
        return this.formGroup(this.pathSvedIuL)?.get('ogrniuL') as FormControl
    }

    enableSvedFlip(value: string) {
        if (value === '2') {
            this.formGroup(this.pathSvedFLIP)?.removeControl('ogrnip');
            this.formGroup(this.pathSvedFLIP)?.removeControl('tipFLChastnaiaPraktika');
            this.formGroup(this.pathSvedFLIP)?.removeControl('regnNomer');
        }
        if (value === '3') {
            this.formGroup(this.pathSvedFLIP)?.removeControl('tipFLChastnaiaPraktika');
            this.formGroup(this.pathSvedFLIP)?.removeControl('regnNomer');
            this.formGroup(this.pathSvedFLIP)?.addControl('ogrnip', new FormControl(' ',
                [Validators.maxLength(15), Validators.minLength(15)]));
        }
        if (value === '4') {
            this.formGroup(this.pathSvedFLIP)?.addControl('tipFLChastnaiaPraktika',
                new FormControl(' ', [Validators.required, Validators.max(1)]));
            this.formGroup(this.pathSvedFLIP)?.addControl('regnNomer',
                new FormControl(' ', [Validators.max(250)]));
            this.formGroup(this.pathSvedFLIP)?.removeControl('ogrnip');
        }
        this.formGroup(this.pathSvedFLIP)?.enable()

        const fioFlip = this.getFioFlip()
        if (fioFlip?.get('fiostroka')?.value) {
            this.disableFioFlip()
        } else if (this.isFioFlipNotNullable()) {
            fioFlip?.get('fiostroka')?.disable()
        }

        const adrReg = this.getAdrReg()
        if (adrReg?.get('adresStroka')?.value) {
            this.disableAdrReg()
        } else if (this.isAddressFlipNotNullable) {
            adrReg?.get('adresStroka')?.disable()
        }
    }

    getFioFlip() {
        return this.formGroup(this.pathSvedFLIP)?.get('fioflip')
    }

    getAdrReg() {
        return this.formGroup(this.pathSvedFLIP)?.get('adrReg')
    }

    disableFioFlip() {
        const fioFlip = this.getFioFlip()
        this.fioFlipFields.forEach(flipField => fioFlip?.get(flipField)?.disable())
    }

    disableAdrReg() {
        const adrReg = this.getAdrReg()
        this.adrRegFields.forEach(adrRegField => adrReg?.get(adrRegField)?.disable())
    }

    isFioFlipNotNullable(): boolean {
        const fioFlip = this.getFioFlip()
        return this.fioFlipFields.some(flipField => fioFlip.get(flipField)?.value)
    }

    isAddressFlipNotNullable(): boolean {
        const adrReg = this.getAdrReg()
        return this.adrRegFields.some(adrRegField => adrReg.get(adrRegField)?.value)
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
