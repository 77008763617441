import {Component, OnInit, OnDestroy, ChangeDetectorRef, Optional, Input} from "@angular/core";
import {BaseFilterController} from "../BaseFilterController";
import {FormBuilder} from "@angular/forms";
import {FmDraftFilter} from "@amlCore/models";
import {DictionaryService, StringDateAdapterService, TableService, ValidatorService} from "@amlCore/services";
import {ActivatedRoute} from "@angular/router";
import { Strateg655PFilterService } from 'src/app/arm/strateg655p/service/strateg655p-filter.service';
import {Strateg5392UFilterService} from "src/app/arm/strateg5392U/service/strateg5392u-filter.service";
import {FmFilterService} from "src/app/arm/fm/service/fm-filter.service";
import {DocTypeSearch, getDocTypes} from "../../constants";


@Component({
  selector: "app-fm-wait-list-filter",
  templateUrl: "./fmWaitListFilter.component.html",
})

export class FmWaitListFilterComponent extends BaseFilterController implements OnInit {
  public filterObjectModel = new FmDraftFilter();
  placeholder = 'Поиск по идентификатору документа';
  isDeletedComponent: boolean;
  @Input() docType: 'strateg655p' | 'strateg5392u' | 'fm' | 'rejection' = 'fm'
  docTypes: DocTypeSearch[] = []

  constructor(private readonly fb: FormBuilder,
              private readonly _changeDetectorRef: ChangeDetectorRef,
              protected tableSrv: TableService,
              private dictSrv: DictionaryService,
              public dateSrv: StringDateAdapterService,
              protected readonly activatedRoute: ActivatedRoute,
              @Optional() private strateg655PFilterService: Strateg655PFilterService,
              @Optional() private strateg5392UFilterService: Strateg5392UFilterService,
              @Optional() private fmFilterService: FmFilterService,) {

    super(tableSrv, activatedRoute);
  }

  /**
   * Fix error type ExpressionChangedAfterItHasBeenCheckedError
   */
  ngAfterViewChecked(): void {
    this._changeDetectorRef.detectChanges();
  }

  ngOnInit() {
    this.init();
    this.isDeletedComponent = this.activatedRoute.routeConfig.path === 'deleted';
    this.loadUserList()
    this.docTypes = getDocTypes(this.docType)
    console.log('docType ', this.docType);
    this.docType === 'rejection' ? this.placeholder = 'Поиск по имени XML файла' : null;
  }

  /**
   * Метод создания формы
   * @return FormGroup
   */
  public createFormModel() {
    const formConfig = new FmDraftFilter();

    return this.fb.group(formConfig);
  }

  getStartFilterModel(): FmDraftFilter {
    return {
      documentType: null,
      documentStatus: null
    } as FmDraftFilter;
  }

  /**
   * Описания полей
   */
  public getFilterDescription(): any {
    return {
      documentType: 'Тип документа',
      dateFrom: 'Дата документа с',
      dateTo: 'Дата по',
      ...!this.isDeletedComponent && {signDateFrom: 'Дата подписи с'},
      ...!this.isDeletedComponent && {signDateTo: 'Дата по'},
      creatorId: 'Владелец',
    };
  }


  get users() {
    return this.docType === 'fm' ? this.dictSrv?.remoteDict?.usersFM?.list || [] :
        this.dictSrv.remoteDict.strateg.list || [];
  }

  private loadUserList() {
    switch (this.docType) {
      case "strateg655p":
        this.strateg655PFilterService.loadUserAccessList();
        break;
      case "strateg5392u":
        this.strateg5392UFilterService.loadUserAccessList();
        break;
      default:
        this.fmFilterService?.loadUserAccessList();
    }
  }
}
