export const docMaskConstants = {
    control6670: [
        {
            regex: /^SPO281FZ_(\d{10})_(\d{8})_(\d{3})\.xml$/,
            serviceName: 'sd/konfo/economic-measure'
        }
    ],
    fm: [],
    strateg655p: [
        {
            regex: /^SBC[0|2][1-3]\d{7}_\d{4}\d{8}_\d{4}\d{4}\d{2}\d{8}_[0-7](?:00|(?:0[1-9]|[1-6]\d|7[0-6]))\.([xX][mM][lL])$/,
            serviceName: 'otkr/zakr/chetGoz'
        },
        {
            regex: /^SBC[1|3][1-3]\d{7}_\d{4}\d{8}_\d{4}\d{4}\d{2}\d{8}_[0-7](?:00|(?:0[1-9]|[1-6]\d|7[0-6]))\.([xX][mM][lL])$/,
            serviceName: 'izm/chetGoz'
        },
        {
            regex: /^SKO443_(03|13)_\d{9}_\d{8}_\d{3}\.([xX][mM][lL])$/,
            serviceName: 'otkr/zakr/chetDepo'
        },
        {
            regex: /^SKO443_(02)_\d{9}_\d{8}_\d{3}\.([xX][mM][lL])$/,
            serviceName: 'sd/ko/securities'
        },
        {
            regex: /^SKO443_(23)_\d{9}_\d{8}_\d{3}\.([xX][mM][lL])$/,
            serviceName: 'sd/ko/izm/chet/depo'
        },
        {
            regex: /^SKO443_(01|11)_\d{9}_\d{8}_\d{3}\.([xX][mM][lL])$/,
            serviceName: 'sd/ko/otkr/zakr/akkr'
        },
        {
            regex: /^SKO443_(21)_\d{9}_\d{8}_\d{3}\.([xX][mM][lL])$/,
            serviceName: 'izm/akkred'
        },
    ],
    strateg5392u: [
        {
          regex: /^GOZKO_(\d{9})_(\d{8})_(\d{3})\.([xX][mM][lL])$/,
          serviceName: 'oes/otkaz/goz'
        }
    ],
    elMsgFm: []
};

export type docModule = 'fm' | 'control6670' | 'strateg655p' | 'strateg5392u' | 'elMsgFm';

