import {Injectable} from "@angular/core";
import {ValidatorService} from "@amlCore/services";
import {FormArray, FormBuilder, FormGroup} from "@angular/forms";
import {DocumentBaseForm} from "./documentBaseForm";
import {DocumentTypeEnum} from "../enums";
import {FormGroupGetterFn} from "../types";
import {Utils} from "@amlCore/utils";

@Injectable({ providedIn: "root" })
export class DocumentBaseService extends DocumentBaseForm {
  constructor(protected fb: FormBuilder,
              protected validationSrv: ValidatorService) {
    super(fb, validationSrv);
  }
  /**
   * Обрезает undefined на формах просмотра
   * @deprecated
   */
  v = (value): any => value || "";

  /*--------------FM------------*/
  getObstructionFacts(data: any): FormArray {
    return this.fbArray(this.getFaktVosprepiat, data, null, this.valid({ isReq: true }));
  }

  getFaktVosprepiat(data?: any): FormGroup {
    return this.fb.group({
      dataVyiavleniia: [data?.dataVyiavleniia, this.valid({ isReq: true, isDate: true })],
      formaVosprepiatstvovaniia: [data?.formaVosprepiatstvovaniia, this.valid({ isReq: true, max: 4000 })],
      kodOKSM: [data?.kodOKSM, this.valid({ isReq: true, max: 3 })],
      komment: [data?.komment, this.valid({max: 4000})],
      nomerZapisi: [data?.nomerZapisi, this.valid({ isReq: true, pattern: '([0-9]{4}_[0-9]{4}_[0-9]{4}_[0-9]{2}_[0-9]{12})'})],
      svedIdentifikatsiia: [data?.svedIdentifikatsiia, this.valid({ isReq: true, max: 2000 })],
      tipZapisi: [data?.tipZapisi, this.valid({ isReq: true, max: 1, minNumber: 1, maxNumber: 4 })],
      zakonodatelstvoRF: this.fbArray(this.getZakonodatelstvoRf, data?.zakonodatelstvoRF, null, this.valid({ isReq: true })),
    });
  }

  getZakonodatelstvoRf(data?: any): FormGroup {
    return this.fb.group({
      dataNormAkt: [data?.dataNormAkt, this.valid({ isReq: true, isDate: true })],
      naimNormAkt: [data?.naimNormAkt, this.valid({ isReq: true, max: 2000 })],
      nomerNormAkt: [data?.nomerNormAkt, this.valid({ isReq: true, max: 100 })],
      statia: this.fbArray(this.getStatia, data?.statia),
      punktPodpunkt: this.fbArray(this.getPunkty, data?.punktPodpunkt),
    });
  }

  getStatia(data: any): FormGroup {
    return this.fb.group({
      statia: [data?.statia, this.valid({ max: 250, isReq: true })],
      chast: this.fbArray(this.getChast, data?.chast),
      punkty: this.fbArray(this.getPunkty, data?.punkty),
    });
  }

  getPunkty(data: any): FormGroup {
    return this.fb.group({
      punkt: [data?.punkt, this.valid({ isReq: true, max: 250 })],
      podpunkt: this.fbArray(this.fbControl, data?.podpunkt, this.valid('podpunkt'), this.valid({ isReq: true }))
    });
  }

  getChast(data: any): FormGroup {
    return this.fb.group({
      punkty: this.fbArray(this.getPunkty, data?.punkty),
      chastStatia: [data?.chastStatia, this.valid({ isReq: true, max: 250 })],
    });
  }

  getTransactionsRejectForm(data: any, docType?): FormArray {
    return this.fbArray(this.getSvedPredstav, data, docType, this.valid({ isReq: true }) );
  }

  getSvedKoSaForm(data: any): FormArray {
    return this.fbArray(this.getSvedKoSa, data, null, this.valid({ isReq: true }));
  }

  getSvedKoSa(data: any): FormGroup {
    return this.fb.group({
      infFilial: this.getInfFilial(data.infFilial),
      podozrDeiat: this.fbArray(this.getPodozrDeiatSa, data.podozrDeiat,null, this.valid({ isReq: true })),
      priznakPredstavSved: [data.priznakPredstavSved ?? 0, this.valid({ isReq: true, max: 10 })],
    });
  }

  getPodozrDeiatSa(data: any): FormGroup {
    return this.fb.group({
      nomerZapisi: [data.nomerZapisi, this.valid({ isReq: true, pattern:'([0-9]{4}_[0-9]{4}_[0-9]{4}_[0-9]{2}_[0-9]{12})' })],
      tipZapisi: [data.tipZapisi, this.valid({ isReq: true })],
      kodPD: [data.kodPD, this.valid({ isReq: true })],
      kodZonaRisk: [data.kodZonaRisk],
      dataResheniia: [data.dataResheniia, this.valid({ isReq: true, isDate: true })],
      klient: this.fbArray(this.getKlientSa, data.klient, null, this.valid({ isReq: true })),
      skhema: [data.skhema, this.valid({ max: 4000 })],
      komment: [data.komment, this.valid({ max: 2000 })],
    });
  }

  getSvedKoForm(data: any): FormArray {
    return this.fbArray(this.getSvedKo, data, null, this.valid({ isReq: true }));
  }

  getSvedPredstavForm(data: any, docType?): FormArray {
    return this.fbArray(this.getSvedPredstav, data, docType, this.valid({ isReq: true }));
  }

  getSvedKoFormFreeze(data: any): FormArray {
    return this.fbArray(this.getSvedKoFreeze, data, null, this.valid({ isReq: true }));
  }

  getSvedKo(data: any): FormGroup {
    return this.fb.group({
      infFilial: this.getInfFilial(data.infFilial),
      proverka: this.fbArray(this.getProverka, data.proverka, null, this.valid({ isReq: true })),
      priznakPredstavSved: [data.priznakPredstavSved ?? 0, this.valid({ isReq: true, max: 10 })],
    });
  }

  getSvedKoFreeze(data: any): FormGroup {
    return this.fb.group({
      infFilial: this.getInfFilial(data?.infFilial),
      mery: this.fbArray(this.getMeryData, data?.mery, null, this.valid({ isReq: true })),
      priznakPredstavSved: [data?.priznakPredstavSved ?? 0],
    });
  }

  getSvedPredstav(data: any, docType?): FormGroup {
    return this.fb.group({
      infFilial: this.getInfFilial(data?.infFilial),
      svedOtkaz: this.fbArray(this.getSvedOtkaz, data?.svedOtkaz, null, this.valid({ isReq: true }), docType),
      priznakPredstavSved: [data?.priznakPredstavSved ?? 0, this.valid({ isReq: true })],
    });
  }


  private getKol(data: any): FormGroup {
    return this.fb.group({
      kol0000: [data?.kol0000, this.valid({isReq: true, max: 16, pattern: '((^[0])|([1-9][0-9]*))' })],
      kol0001: [data?.kol0001, this.valid({isReq: true, max: 16, pattern: '((^[0])|([1-9][0-9]*))' })],
      kol0002: [data?.kol0002, this.valid({isReq: true, max: 16, pattern: '((^[0])|([1-9][0-9]*))' })],
      kol0003: [data?.kol0003, this.valid({isReq: true, max: 16, pattern: '((^[0])|([1-9][0-9]*))' })],
    });
  }

  getSvedOtkaz: FormGroupGetterFn = (data, valid?, documentType?) => {
    const svedOtkaz = this.fbGroup(data, {
      dataOtkaza: [data?.dataOtkaza, this.valid({ isReq: true, isDate: true })],
      identifikatorPD: [data?.identifikatorPD, this.valid({isIdentifikatorPD28or30: true, pattern: '([0-9_]{28}|[0-9_]{30})'})],
      inaiaPrichinaOtkaza: [data?.inaiaPrichinaOtkaza, this.valid({ max: 2000 })],
      kharakterOp: [data?.kharakterOp, this.valid({ isReq: true, max: 2000 })],
      kodDenezhSredstv: [data?.kodDenezhSredstv, this.valid({ max: 1 })],
      kodOtkaza: [data?.kodOtkaza, this.valid({ isReq: true, max: 2 })],
      kodPriznOperatsii: [data?.kodPriznOperatsii, this.valid({ isReq: true, max: 1 })],
      kodVal: [data?.kodVal, this.valid({ isReq: true, max: 3 })],
      komment: [data?.komment, this.valid({ max: 2000 })],
      nomerZapisi: [data?.nomerZapisi, this.valid({ isReq: true, pattern:'([0-9]{4}_[0-9]{4}_[0-9]{4}_[0-9]{2}_[0-9]{10})'})],
      prichinaUdaleniia: [data?.prichinaUdaleniia, this.valid({ max: 2000 })],
      priznValOperatsii: [data?.priznValOperatsii, this.valid({ max: 2 })],
      priznakInf: [data?.priznakInf ?? 0, this.valid({ isReq: true, max: 1 })],
      priznakKlienta: [data?.priznakKlienta, this.valid({ isReq: true, max: 1 })],
      sumOperatsii: [data?.sumOperatsii, this.valid({ isReq: true, pattern: Utils.floatPattern })],
      sumRub: [data?.sumRub, this.valid({ isReq: true, pattern: Utils.floatPattern })],
      tipKlienta: [data?.tipKlienta, this.valid({ isReq: true, max: 1 })],
      tipZapisi: [data?.tipZapisi, this.valid({ isReq: true, max: 1 })],
      osnovanieOp: this.fbArray(this.getOsnovanieOp, data?.osnovanieOp),
      kodPrichinaOtkaz: [data?.kodPrichinaOtkaz],
      priznNeobOperatsii: this.fbArray(this.fbControl, data?.priznNeobOperatsii, this.valid({isReq: true}), this.valid({isReq: true})),
      uchastnik: this.fbArray(this.getUchastnik, data?.uchastnik),
      svedIuL: this.getSvedIuL(data.svedIuL),
      svedFLIP: this.getSvedFLIP(data?.svedFLIP, null, null, data?.tipKlienta),
      svedINBOIuL: this.getSvedINBOIuL(data?.svedINBOIuL)
    }, valid);
    // отсутствуют поля в 4937
    if (documentType && documentType !== DocumentTypeEnum.REFUSE_OPERATION) {
      svedOtkaz.removeControl("priznValOperatsii");
      svedOtkaz.removeControl("uchastnik");
      svedOtkaz.addControl("uchastnikOp", this.fbArray(this.getUchastnik, data?.uchastnikOp));
    }
    if (documentType && documentType === DocumentTypeEnum.REFUSE_OPERATION) {
      svedOtkaz.removeControl("tipKlienta");
      svedOtkaz.get('priznakKlienta').disable();
    } else {
      svedOtkaz.get('priznakKlienta').enable();
    }
    // отсутствуют поля в Отказ от заключения договора
    if (documentType && documentType === DocumentTypeEnum.REFUSE_CONTRACT) {
      svedOtkaz.removeControl("kharakterOp");
      svedOtkaz.removeControl("kodPriznOperatsii");
      svedOtkaz.removeControl("kodVal");
      svedOtkaz.removeControl("sumOperatsii");
      svedOtkaz.removeControl("sumRub");
      svedOtkaz.removeControl("identifikatorPD");
      svedOtkaz.removeControl("priznNeobOperatsii");
    }
    return svedOtkaz;
  }

  getProverka(data: any): FormGroup {
    return this.fb.group({
      nomerZapisi: [data?.nomerZapisi, this.valid({ isReq: true, pattern:'([0-9]{4}_[0-9]{4}_[0-9]{4}_[0-9]{2}_[0-9]{12})' })],
      tipZapisi: [data?.tipZapisi, this.valid({ isReq: true, max: 10 })],
      dataPredProv: [data?.dataPredProv, this.valid({ isReq: true, isDate: false })],
      dataTekProv: [data?.dataTekProv, this.valid({ isReq: true, isDate: false })],
      kol30: [data?.kol30, this.valid({ isReq: true, max: 16, pattern: '((^[0])|([1-9][0-9]*))' })],
      kol50: [data?.kol50, this.valid({ isReq: true, max: 16, pattern: '((^[0])|([1-9][0-9]*))' })],
      komment: [data?.komment, this.valid({ isReq: false, max: 2000 })],
      kol0Fizlits: this.getKol(data?.kol0Fizlits),
      kol0Obshchee: this.getKol(data?.kol0Obshchee),
      kol0Organizatsij: this.getKol(data?.kol0Organizatsij),
      kolFizlits: this.getKol(data?.kolFizlits),
      kolObshchee: this.getKol(data?.kolObshchee),
      kolOrganizatsij: this.getKol(data?.kolOrganizatsij),
    });
  }

  getMeryData(data: any): FormGroup {
    if (!data) return null;
    const meryForm = this.fb.group({
      nomerZapisi: [data.nomerZapisi, this.valid({ isReq: true, min: 30, max: 30, pattern: '([0-9]{4}_[0-9]{4}_[0-9]{4}_[0-9]{2}_[0-9]{12})' })],
      tipZapisi: [data.tipZapisi, this.valid({ isReq: true })],
      dataBlok: [data.dataBlok, this.valid({ isReq: true, isDate: true })],
      vremiaBlok: [data.vremiaBlok, this.valid({ isReq: true, pattern: '[0-9]{2}:[0-9]{2}:[0-9]{2} \\\((м|М)(с|С)(к|К)(\\+|-)[0-9]{2}:[0-9]{2}\\\)' })],
      vidSredstv: [data.vidSredstv, this.valid({ isReq: true })],
      kodVal: [data.kodVal, this.valid({ isReq: data?.vidSredstv === '1' || data?.vidSredstv === '2' || data?.vidSredstv === '3' ? true : false })],
      sumDenezhSredstv: [data.sumDenezhSredstv, this.valid({ isReq: data?.vidSredstv === '1' || data?.vidSredstv === '2' || data?.vidSredstv === '3' ? true : false, pattern: Utils.floatPattern })],
      sumRub: [data.sumRub, this.valid({ pattern: Utils.floatPattern })],
      tsenBumagi: [data.tsenBumagi, this.valid({ isReq: data?.vidSredstv === '4' ? true : false })],
      stoimostTsenBumag: [data.stoimostTsenBumag, this.valid({ isReq: data?.vidSredstv === '4' ? true : false, pattern: Utils.floatPattern })],
      raschetStoimost: [data.raschetStoimost, this.valid({ isReq: data?.vidSredstv === '4' ? true : false, pattern: Utils.floatPattern })],
      komment: [data.komment, this.valid({ max: 2000 })],
      meraOsnovanie: this.fbArray(this.meraOsnovanie, data?.meraOsnovanie),
      nomerSchetSejf: this.fbArray(this.fbControl, data?.nomerSchetSejf, this.valid('nomerSchetSejf'), this.valid({ isReq: true }))});
    // отсутствует поле в 4937
    if (typeof data['nomerSchetSejf'] === undefined)
      meryForm.removeControl("nomerSchetSejf");
    return meryForm;
  }

  meraOsnovanie: FormGroupGetterFn = (data, valid?) => {
    return this.fb.group({
      kodOsnovaniia: [data?.kodOsnovaniia, this.valid({ isReq: true, min: 1, max: 1 })],
      kodIuLFL: [data?.kodIuLFL, this.valid({ isReq: true, min:1, max: 10 , pattern: '[0-9]+'})],
    }, valid);
  }


  getOperationDetailsForm(data: any): FormArray {
    return this.fbArray(this.getOperationDetails, data, null,  this.valid({ isReq: true }));
  }

  getOperationDetails(data: any): FormGroup {
    return this.fb.group({
      infFilial: this.getInfFilial(data?.infFilial),
      operatsiia: this.fbArray(this.getOperatsiiaFm, data?.operatsiia,null, this.valid({ isReq: true })),
      priznakPredstavSved: [data?.priznakPredstavSved ?? 0, this.valid({ isReq: true })],
    });
  }

  getOperatsiiaFm(data: any): FormGroup {
    return this.fb.group({
      dataOperatsii: [data?.dataOperatsii, this.valid({ isDate: true, isReq: true })],
      dataVyiavleniia: [data?.dataVyiavleniia, this.valid({ isDate: true, isReq: false })],
      dopKodOperatsii: [data?.dopKodOperatsii],
      identifikatorPD: [data?.identifikatorPD, this.valid({isIdentifikatorPD28or30: true, pattern: '([0-9]{4}_[0-9]{4}_[0-9]{4}_[0-9]{2}_[0-9]{12})' })],
      kharakterOp: [data?.kharakterOp ?? '', this.valid({ max: 2000 })],
      kodOperatsii: [data?.kodOperatsii, this.valid({ isReq: true, max: 4 })],
      kodPriznOperatsii: [data?.kodPriznOperatsii, this.valid({ isReq: true, max: 1 })],
      kodVal: [data?.kodVal, this.valid({ isReq: true, max: 3 })],
      kodValKonversiia: [data?.kodValKonversiia, this.valid({ max: 3 })],
      komment: [data?.komment, this.valid({ max: 2000 })],
      naimPlatezhnaiaSistema1: [data?.naimPlatezhnaiaSistema1, this.valid({ max: 100 })],
      naimPlatezhnaiaSistema2: [data?.naimPlatezhnaiaSistema2, this.valid({ max: 100 })],
      naznacheniePlatezha: [data?.naznacheniePlatezha ?? '', this.valid({ max: 2000 })],
      nomerOperatsiia: [data?.nomerOperatsiia, this.valid({ max: 100 })],
      nomerZapisi: [data?.nomerZapisi, this.valid({ isReq: true, pattern:'([0-9]{4}_[0-9]{4}_[0-9]{4}_[0-9]{2}_[0-9]{12})'})],
      osnovanieOp: this.fbArray(this.getOsnovanieOp, data?.osnovanieOp),
      priznFTr: [data?.priznFTr, [...this.valid({ isReq: true}), this.validationSrv.arrayItemLengthValidator()]],
      priznNeobOperatsii: [data?.priznNeobOperatsii],
      priznValOperatsii: [data?.priznValOperatsii, this.valid({ max: 2 })],
      priznakOperatsiiaESP: [data?.priznakOperatsiiaESP, this.valid({ max: 1 })],
      sumKonversiia: [data?.sumKonversiia, this.valid({ pattern: /^[0-9]{1,20}\.[0-9]{2}$/ })],
      sumOperatsii: [data?.sumOperatsii, this.valid({ isReq: true, pattern: /^[0-9]{1,20}\.[0-9]{2}$/ })],
      sumRub: [data?.sumRub, this.valid({ isReq: true, pattern: /^[0-9]{1,20}\.[0-9]{2}$/ })],
      svedDragMetall: this.fbArray(this.getSvedDragMetall, data?.svedDragMetall),
      svedeniiaKartaInBank: this.getSvedeniiaKartaInBank(data?.svedeniiaKartaInBank),
      svedeniiaPerevodyDS: this.getSvedeniiaPerevodyDS(data?.svedeniiaPerevodyDS),
      svedeniiaVneseniePoluchenieNalDS: this.getSvedeniiaVneseniePoluchenieNalDS(data?.svedeniiaVneseniePoluchenieNalDS),
      tipZapisi: [data?.tipZapisi, this.valid({ isReq: true, max: 1 })],
      uchastnikOp: this.fbArray(this.getUchastnikOpFm, data?.uchastnikOp,null, this.valid({ isReq: true })),
      vremiaTranzaktsiiaESP: [data?.vremiaTranzaktsiiaESP, this.valid({  pattern:/[0-9]{2}:[0-9]{2}:[0-9]{2} \((м|М)(с|С)(к|К)(\+|-)[0-9]{2}:[0-9]{2}\)/ }) ],
    });
  }

  getOsnovanieOp: FormGroupGetterFn = (data, valid?, documentType?) => {
    return this.fb.group({
      dataDok: [data?.dataDok, this.valid({ isDate: true })],
      inoeNaimDok: [data?.inoeNaimDok, this.valid({ max: 250 })],
      kodDok: [data?.kodDok, this.valid({ isReq: true, max: 2 })],
      nomDok: [data?.nomDok, this.valid({ max: 100 })],
      sodDok: [data?.sodDok, this.valid({ isReq: documentType === DocumentTypeEnum.OPERATIONS ? false : true, max: 2000 })]
    }, valid);
  }

  getSvedDragMetall: FormGroupGetterFn = (data, valid?) => {
    return this.fb.group({
      kodDragMetall: [data?.kodDragMetall, this.valid({isReq: true})],
      naimDragMetall: [data?.naimDragMetall, this.valid({ max: 250 })],
    }, valid);
  }

  private getSvedeniiaVneseniePoluchenieNalDS: FormGroupGetterFn = (data, valid?) => {
    return this.fbGroup(data, {
      kodKharakterOp: [data?.kodKharakterOp, this.valid({ isReq: true, max: 1 })],
      nomerSchetKlient: [data?.nomerSchetKlient, this.valid({ isReq: true, max: 40 })],
      nomerKarta: [data?.nomerKarta, this.valid({ max: 40 })],
      svedPriemVydacha: this.getSvedNalDS(data?.svedPriemVydacha),
    }, valid);
  }

  private getSvedeniiaKartaInBank: FormGroupGetterFn = (data, valid?) => {
    return this.fbGroup(data, {
      kodTerInBank: [data?.kodTerInBank, this.valid({ isReq: true, max: 500 })],
      svedMestoOperatsiia: this.getSvedNalDS(data?.svedMestoOperatsiia),
      nomerKarta: [data?.nomerKarta, this.valid({ isReq: true, max: 40 })],
      svedDerzhatelKarty: [data?.svedDerzhatelKarty, this.valid({ isReq: true, max: 2000 })],
      priznakSotrudnik: [data?.priznakSotrudnik ?? 0, this.valid({ isReq: true, max: 1 })],
      naimInBank: [data?.naimInBank, this.valid({ isReq: true, max: 500 })],
      sviftInBank: [data?.sviftInBank, this.valid({ isSwift2or8or11: true })]
    }, valid);
  }

  private getSvedeniiaPerevodyDS: FormGroupGetterFn = (data, valid?) => {
    return this.fbGroup(data, {
      adresIP: [data?.adresIP, this.valid({ max: 40 })],
      adresMAC: [data?.adresMAC, this.valid({ max: 20 })],
      identESPPlatelshchik: [data?.identESPPlatelshchik, this.valid({ max: 100 })],
      identESPPoluchatelia: [data?.identESPPoluchatelia, this.valid({ max: 100 })],
      kodTerInGos: [data?.kodTerInGos, this.valid({ isReq: true, max: 500 })],
      nomerSchetPlatelshchik: [data?.nomerSchetPlatelshchik, this.valid({ max: 40 })],
      nomerSchetPoluchatel: [data?.nomerSchetPoluchatel, this.valid({ max: 40 })],
      schetBankPlatelshchik: [data?.schetBankPlatelshchik, this.valid({ max: 40 })],
      schetBankPoluchatel: [data?.schetBankPoluchatel, this.valid({ max: 40 })],
      statusPerevod: [data?.statusPerevod, this.valid({ max: 1 })],
      svedBankPlatelshchik: this.getSvedBank(data?.svedBankPlatelshchik),
      svedBankPoluchatel: this.getSvedBank(data?.svedBankPoluchatel),
      svedPriemNalDS: this.getSvedNalDS(data?.svedPriemNalDS),
      svedVydachaNalDS: this.getSvedNalDS(data?.svedVydachaNalDS),
      tipOperatorDS: [data?.tipOperatorDS, this.valid({ isReq: true, max: 1 })],
      vidPerevodDS: [data?.vidPerevodDS, this.valid({ isReq: true, max: 1 })],
    }, valid);
  }

  private getSvedBank: FormGroupGetterFn = (data, valid?) => {
    return this.fbGroup(data, {
      bikko: [data?.bikko, this.valid({ isReq: true, isBIKKO: true })],
      naimKO: [data?.naimKO, this.valid({ isReq: true, max: 250 })],
    }, valid);
  }

  private getSvedNalDS: FormGroupGetterFn = (data, valid?) => {
    return this.fbGroup(data, {
      bikko: [data?.bikko, this.valid({ isReq: true, isBIKKO: true })],
      naimKO: [data?.naimKO, this.valid({ isReq: true, max: 250 })],
      idTerminal: [data?.idTerminal, this.valid({ max: 50 })],
      adrMestaPriemaVydacha: this.getAddress(data?.adrMestaPriemaVydacha),
    }, valid);
  }

  getUchastnikOpFm: FormGroupGetterFn = (data: any) => {
    const group = this.fb.group({
      kodIuLFL: [data?.kodIuLFL, this.valid({ max: 10 })],
      kommentUchastnik: [data?.kommentUchastnik, this.valid({ max: 2000 })],
      priznKlient: [data?.priznKlient, this.valid({ isReq: true, max: 1 })],
      priznUchastnika: [data?.priznUchastnika, this.valid({ isReq: true, max: 1 })],
      statusUchastnika: [data?.statusUchastnika, this.valid({ isReq: true, max: 1 })],
      tipUchastnika: [data?.tipUchastnika, this.valid({ isReq: true, max: 1, minNumber: 0, maxNumber: 5 })],
    });

    if (data?.tipUchastnika === '1') {
      const otherParams = {
        tipUchastnika: data?.tipUchastnika,
        priznUchastnika: data?.priznUchastnika
      }

      group.removeControl('uchastnikFLIP');
      group.removeControl('uchastnikINBOIuL');
      group.addControl('uchastnikIuL', this.getUchastnikIuL(data?.uchastnikIuL, null, null, otherParams));
    }

    if (data?.tipUchastnika === '2' || data?.tipUchastnika === '3' || data?.tipUchastnika === '4') {
      const otherParams = {
        tipUchastnika: data?.tipUchastnika ?? '2',
        priznKlient: data?.priznKlient
      }

      group.removeControl('uchastnikIuL');
      group.removeControl('uchastnikINBOIuL');
      group.addControl('uchastnikFLIP', this.getUchastnikFLIP(data?.uchastnikFLIP, null, null, otherParams));
    }
    if (data?.tipUchastnika === '5') {
      group.removeControl('uchastnikFLIP');
      group.removeControl('uchastnikIuL');
      group.addControl('uchastnikINBOIuL', this.getUchastnikINBOIuL(data?.uchastnikINBOIuL, null, null, data?.tipUchastnika));
    }
    group.updateValueAndValidity();
    return group;
  }

  /*--------------ARM4937------------*/

  getInformChastSvedNFOWithOperatsiia(data: any): FormArray {
    return this.fbArray(this.getSvedNFOWithOperatsiia, data.svedNFO);
  }
  // Для 4937_09
  getInformChastSvedNFOPodozrDeiatNFO(data: any): FormArray {
    return this.fbArray(this.getSvedNFOPodozrDeiatNFO, data.svedNFO);
  }

  private getSvedNFOPodozrDeiatNFO(data: any): FormGroup {
    return this.fb.group({
      infNFO: this.getinfNFO(data.infNFO),
      podozrDeiatNFO: this.fbArray(this.getPodozrDeiatNFO, data.podozrDeiatNFO, this.valid({ isReq: true }))
    });
  }

  getPodozrDeiatNFO(data: any): FormGroup {
    return this.fb.group({
      nomerZapisi: [data.nomerZapisi, this.valid({ isReq: true, max: 30, pattern: '([0-9]{4}_[0-9]{4}_[0-9]{4}_[0-9]{2}_[0-9]{12})' })],
      tipZapisi: [data.tipZapisi, this.valid({ isReq: true })],
      kodPD: [data.kodPD, this.valid({ isReq: true, max: 5 })],
      kodZonaRisk: [data.kodZonaRisk, this.valid({ max: 5 })],
      dataResheniia: [data.dataResheniia, this.valid({ isReq: true, isDate: true })],
      klient: this.fbArray(this.getKlientSa, data.klient, this.valid({ isReq: true })),
      skhema: [data.skhema, this.valid({ isReq: true, max: 10000 })],
      komment: [data.komment, this.valid({ max: 2000 })]
    });
  }

  //для подозрительной деятельности 5861
  getKlientSa: FormGroupGetterFn = (data, valid?) => {
    const group = this.fb.group({
      nomerKlienta: [data?.nomerKlienta, this.valid({ isReq: true, max: 16, pattern: '[1-9][0-9]*' })],
      tipKlienta: [data?.tipKlienta, this.valid({ isReq: true })],
      priznakKlienta: [data?.priznakKlienta, this.valid({ isReq: true })],
      operatsiiaPerechen: this.fbArray(this.fbControl, data?.operatsiiaPerechen, this.valid('operatsiiaPerechen'), this.valid({ isReq: true }))
    }, valid);

    if (data?.tipKlienta === '1') {
      group.removeControl('klientFLIP');
      group.removeControl('klientINBOIuL');
      group.addControl('klientIuL', this.getUchastnikIuL(data?.klientIuL, null, null, data?.tipKlienta));
    }

    if (data?.tipKlienta === '2' || data?.tipKlienta === '3' || data?.tipKlienta === '4') {
      group.removeControl('klientIuL');
      group.removeControl('klientINBOIuL');
      const tipKlient = data?.tipKlienta ?? '2'
      group.addControl('klientFLIP', this.getUchastnikFLIP(data?.klientFLIP, [], DocumentTypeEnum.SUSPICIOUS_ACTIVITY, {tipKlienta: tipKlient}));
    }
    if (data?.tipKlienta === '5') {
      group.removeControl('klientFLIP');
      group.removeControl('klientIuL');
      group.addControl('klientINBOIuL', this.getUchastnikINBOIuL(data?.klientINBOIuL, null, null, data?.tipKlienta));
    }
    group.updateValueAndValidity();
    return group;
  }

  //
  // Для 4937_04
  getInformChastSvedNFOProverka(data: any): FormArray {
    return this.fbArray(this.getSvedNFOProverka, data.svedNFO);
  }

  private getSvedNFOProverka(data: any): FormGroup {
    return this.fb.group({
      infNFO: this.getinfNFO(data.infNFO),
      proverka: this.fbArray(this.getProverka, data.proverka)
    });
  }

  private getSvedNFOWithOperatsiia(data: any): FormGroup {
    return this.fb.group({
      infNFO: this.getinfNFO(data.infNFO),
      operatsiia: this.fbArray(this.getOperatsiiaArm4937, data.operatsiia)
    });
  }

  private getinfNFO(data: any): FormGroup {
    return this.fb.group({
      dopKodNFO: this.fbArray(this.fbControl, data.dopKodNFO, this.valid({ isReq: true })),
      innnfo: [data.innnfo, this.valid({ isReq: true })],
      kodNFO: [data.kodNFO, this.valid({ isReq: true })],
      kppnfo: [data.kppnfo, this.valid({ isReq: true })],
      naimNFO: this.getNaimNFO(data.naimNFO),
      ogrnnfo: [data.ogrnnfo, this.valid({ isReq: true })],
      priznakFlNFO: [data.priznakFlNFO, this.valid({ isReq: true })],
      tipNFO: [data.tipNFO, this.valid({ isReq: true })]
    });
  }

  private getNaimNFO(data: any): FormGroup {
    return this.fb.group({
      ip: this.getFio(data.ip),
      iurLitso: [data.iurLitso, this.valid({ isReq: true })]
    });
  }

  private getOperatsiiaArm4937(data: any): FormGroup {
    return this.fb.group({
      dataOperatsii: [data.dataOperatsii, this.valid({ isReq: true, isDate: true })],
      dataVyiavleniia: [data.dataVyiavleniia, this.valid({ isReq: true, isDate: true })],
      dopKodOperatsii: this.fbArray(this.fbControl, data.dopKodOperatsii, this.valid({ isReq: true })),
      identifikatorPD: [data.identifikatorPD],
      kharakterOp: [data.kharakterOp, this.valid({ isReq: true })],
      kodDenezhSredstv: this.fbArray(this.fbControl, data.kodDenezhSredstv, this.valid({ isReq: true })),
      kodOperatsii: [data.kodOperatsii, this.valid({ isReq: true, min: 4, max: 4 })],
      kodPriznOperatsii: [data.kodPriznOperatsii, this.valid({ isReq: true, max: 1 })],
      kodVal: [data.kodVal, this.valid({ isReq: true })],
      komment: [data.komment, this.valid({ isReq: true })],
      nomerZapisi: [data.nomerZapisi, this.valid({ isReq: true, pattern: '([0-9]{4}_[0-9]{4}_[0-9]{4}_[0-9]{2}_[0-9]{12})' })],
      osnovanieOp: this.fbArray(this.getOsnovanieOp, data.osnovanieOp),
      priznFTr: this.fbArray(this.fbControl, data.priznFTr, this.valid({ isReq: true })),
      priznNeobOperatsii: this.fbArray(this.fbControl, data.priznNeobOperatsii, this.valid({ isReq: true })),
      priznakNPF: [data.priznakNPF, this.valid({ isReq: true })],
      sumOperatsii: [data.sumOperatsii, this.valid({ isReq: true })],
      sumRub: [data.sumRub, this.valid({ isReq: true })],
      svedDragMetall: this.fbArray(this.getSvedDragMetall, data.svedDragMetall),
      tipZapisi: [data.tipZapisi, this.valid({ isReq: true })],
      uchastnikOp: this.fbArray(this.getUchastnikOpArm4937, data.uchastnikOp)
    });
  }

  private getUchastnikOpArm4937(data: any): FormGroup {
    return this.fb.group({
      kodIuLFL: [data.kodIuLFL, this.valid({ isReq: true })],
      kodUchastnika: [data.kodUchastnika, this.valid({ isReq: true })],
      priznKlient: [data.priznKlient, this.valid({ isReq: true })],
      priznUchastnika: [data.priznUchastnika, this.valid({ isReq: true })],
      statusUchastnika: [data.statusUchastnika, this.valid({ isReq: true })],
      svedeniiaKO: this.getSvedeniiaKO(data.svedeniiaKO),
      tipUchastnika: [data.tipUchastnika, this.valid({ isReq: true })],
      uchastnikFLIP: this.getUchastnikFLIP(data.uchastnikFLIP),
      uchastnikINBOIuL: this.getUchastnikINBOIuL(data.uchastnikINBOIuL),
      uchastnikIuL: this.getUchastnikIuL(data.uchastnikIuL)
    });
  }

  getInformChastSvedNFOWithMery(data: any): FormArray {
    return !data ? null : this.fbArray(this.getSvedNFOWithMery, data.svedNFO);
  }

  private getSvedNFOWithMery(data: any): FormGroup {
    return this.fb.group({
      infNFO: this.getinfNFO(data.infNFO),
      mery: this.fbArray(this.getMeryData, data.mery)
    });
  }

  getInformChastSvedNFOWithOtkaz(data: any): FormArray {
    return this.fbArray(this.getSvedNFOWithOtkaz, data.svedNFO);
  }

  private getSvedNFOWithOtkaz(data: any): FormGroup {
    return this.fb.group({
      infNFO: this.getinfNFO(data.infNFO),
      otkaz: this.fbArray(this.getSvedOtkaz, data.otkaz)
    });
  }

  getInfoAuthPersonOrg(data: any): FormGroup {
    return this.fb.group({
      telOs: [data.telOs],
      emailOs: [data.emailOs, this.valid({ pattern: Utils.emailPattern })],
      postOs: [data.postOs],
      fos: [data.fos, this.valid({ isReq: true })],
      ios: [data.ios, this.valid({ isReq: true })],
      oos: [data.oos],
    });
  }
}
