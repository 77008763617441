import {Injectable} from "@angular/core";
import {ApiService} from "./api.service";
import {ClientTypeEnum} from "@amlCore/enums";
import {debounceTime, map} from "rxjs/operators";
import {Observable} from "rxjs";

@Injectable()
export class DictionaryService {

  clientTypesList = [
    {
      code: ClientTypeEnum.LEGAL_ENTITY,
      name: 'Юридическое лицо'
    },
    {
      code: ClientTypeEnum.INDIVIDUAL_PERSON,
      name: 'Физическое лицо'
    },
    {
      code: ClientTypeEnum.INDIVIDUAL_ENTREPRENEUR,
      name: 'Индивидуальный предприниматель'
    },
    {
      code: ClientTypeEnum.PRIVATE_PRACTITIONER,
      name: 'Физическое лицо, занимающееся частной практикой'
    },
    {
      code: ClientTypeEnum.FOREIGN_STRUCTURE,
      name: 'Иностранная структура без образования юридического лица'
    }
  ];

  /**
   * Статичные справочники
   */
  private staticData = {
    // Тип организации/лица
    clientType: [
      {
        code: ClientTypeEnum.LEGAL_ENTITY,
        name: 'Юридическое лицо'
      },
      {
        code: ClientTypeEnum.INDIVIDUAL_PERSON,
        name: 'Физическое лицо'
      },
      {
        code: ClientTypeEnum.INDIVIDUAL_ENTREPRENEUR,
        name: 'Индивидуальный предприниматель'
      },
      {
        code: ClientTypeEnum.PRIVATE_PRACTITIONER,
        name: 'Физическое лицо, занимающееся частной практикой'
      },
      {
        code: ClientTypeEnum.FOREIGN_STRUCTURE,
        name: 'Иностранная структура без образования юридического лица'
      }
    ],
    residentPriznak : [
      {
        code: 1,
        name: 'для резидента'
      },
      {
        code: 2,
        name: 'для нерезидента'
      }
    ],

    platKartaPriznak: [
      {
        code: '0',
        name: 'операция осуществлена без использования платежной карты'
      },
      {
        code: 1,
        name: 'операция осуществлена с использованием платежной карты'
      }
    ],
    doverPriznak: [
      {
        code: '0',
        name: 'операция осуществлена без участия представителя, действующего по доверенности'
      },
      {
        code: 1,
        name: 'операция осуществлена с участием представителя, действующего по доверенности'
      }
    ],
    // type tabs
    svedClient: this.clientTypesList,
    svedPredst: this.clientTypesList,
    svedVPR: this.clientTypesList,
    partner: this.clientTypesList,
    svedBV: {
        code: ClientTypeEnum.INDIVIDUAL_PERSON,
        name: 'Физическое лицо'
    },
    svedIOU: [
        {
          code: ClientTypeEnum.LEGAL_ENTITY,
          name: 'Юридическое лицо'
        },
        {
          code: ClientTypeEnum.INDIVIDUAL_PERSON,
          name: 'Физическое лицо'
        },
        {
          code: ClientTypeEnum.INDIVIDUAL_ENTREPRENEUR,
          name: 'Индивидуальный предприниматель'
        }
    ],
    svedAU: [
        {
          code: ClientTypeEnum.LEGAL_ENTITY,
          name: 'Юридическое лицо'
        },
        {
          code: ClientTypeEnum.INDIVIDUAL_PERSON,
          name: 'Физическое лицо'
        },
    ],
    svedUDS: [
        {
          code: ClientTypeEnum.LEGAL_ENTITY,
          name: 'Юридическое лицо'
        },
        {
          code: ClientTypeEnum.INDIVIDUAL_PERSON,
          name: 'Физическое лицо'
        },
    ],
    // Наличие запрашиваемой информации
    nalZaprInf: [
      {
        code: '0'
      },
      {
        code: '1'
      },
      {
        code: '2'
      },
    ],
    // Вид гражданства
    nationality: [
      {
        code: '1',
        name: 'для граждан Российской Федерации'
      },
      {
        code: '2',
        name: 'Для иностранных граждан'
      },
      {
        code: '3',
        name: 'для лиц без гражданства, если они постоянно проживают на территории Российской Федерации'
      },
      {
        code: '4',
        name: 'для иных лиц без гражданства'
      },
      {
        code: '5',
        name: 'для беженцев'
      },
    ],
    // Справочник кодов видов документов.
    docType: [
      {
        code: "21",
        name: "Паспорт гражданина Российской Федерации (для гражданина Российской Федерации, достигшего 14 лет)"
      },
      {
        code: "22",
        name: "Основной документ, удостоверяющий личность гражданина Российской Федерации за пределами территории Российской Федерации"
      },
      {
        code: "23",
        name: "Удостоверение личности моряка/паспорт моряка"
      },
      {
        code: "24",
        name: "Удостоверение личности военнослужащего (для офицеров, прапорщиков и мичманов)"
      },
      {
        code: "25",
        name: "Военный билет военнослужащего (для сержантов, старшин, солдат и матросов," +
          " а также курсантов военных образовательных учреждений профессионального образования)"
      },
      {
        code: "26",
        name: "Временное удостоверение личности гражданина Российской Федерации, выдаваемое органом внутренних дел до оформления паспорта"
      },
      {
        code: "27",
        name: "Свидетельство органов ЗАГС, органа исполнительной власти или органа местного " +
          "самоуправления о рождении гражданина (для гражданина Российской Федерации, не достигшего 14 лет)"
      },
      {
        code: "28",
        name: "Иные документы, признаваемые в соответствии с законодательством Российской Федерации документами, удостоверяющими личность"
      },
      {
        code: "31",
        name: "Паспорт иностранного гражданина либо иной документ, установленный федеральным" +
          " законом или признаваемый в соответствии с международным договором Российской" +
          " Федерации в качестве документа, удостоверяющего личность иностранного гражданина"
      },
      {
        code: "32",
        name: "Документ, выданный иностранным государством и признаваемый в соответствии " +
          "с международным договором Российской Федерации в качестве документа, удостоверяющего личность иностранного гражданина"
      },
      {
        code: "33",
        name: "Вид на жительство (для лиц без гражданства, если они постоянно проживают на территории Российской Федерации)"
      },
      {
        code: "34",
        name: "Разрешение на временное проживание (для лиц без гражданства)"
      },
      {
        code: "35",
        name: "Иные документы, предусмотренные федеральными законами или признаваемые в соответствии " +
          "с международным договором Российской Федерации в качестве документов, удостоверяющих личность лица без гражданства"
      },
      {
        code: "36",
        name: "Свидетельство о рассмотрении ходатайства о признании лица беженцем, выданное " +
          "дипломатическим или консульским учреждением Российской Федерации, или постом" +
          " иммиграционного контроля, или территориальным органом федеральной исполнительной власти по миграционной службе"
      },
      {
        code: "37",
        name: "Удостоверение беженца"
      },
      {
        code: "38",
        name: "Удостоверение вынужденного переселенца"
      },
      {
        code: "39",
        name: "Миграционная карта"
      },
      {
        code: "40",
        name: "Иной документ, подтверждающий в соответствии с законодательством Российской Федерации право иностранного гражданина " +
          "на пребывание (проживание) в Российской Федерации"
      },
      {
        code: "41",
        name: "Свидетельство о предоставлении временного убежища на территории Российской Федерации"
      },
      {
        code: "42",
        name: "Справка о рассмотрении заявления о предоставлении временного убежища"
      },
    ],
    // Вид деятельности частного предпринимателя
    vid: [
      {
        code: "1",
        name: "Нотариус"
      },
      {
        code: "2",
        name: "Адвокат"
      },
      {
        code: "3",
        name: "Арбитражный управляющий"
      }
    ],
    // Организационная форма ИНБОЮЛ
    opfInbUL: [
      {
        code: "0",
        name: "Траст или иная иностранная структура без образования юридического лица с аналогичной структурой или функцией"
      },
      {
        code: "1",
        name: "Фонд"
      },
      {
        code: "2",
        name: "Партнерство"
      },
      {
        code: "3",
        name: "Товарищество"
      },
      {
        code: "4",
        name: "Иная форма осуществления коллективных инвестиций и (или) доверительного управления"
      },
    ],
    // Сведения о доходах
    dochods: [
      {
        code: "1",
        name: "Доход по основному месту работы"
      },
      {
        code: "2",
        name: "Пенсия"
      },
      {
        code: "3",
        name: "Доход от вкладов (депозитов)"
      },
      {
        code: "4",
        name: "Доход от ценных бумаг"
      },
      {
        code: "5",
        name: "Доход от предпринимательской деятельности"
      },
      {
        code: "6",
        name: "Личные сбережения"
      },
      {
        code: "7",
        name: "Наследство"
      },
      {
        code: "8",
        name: "Иные доходы (указать вид дохода)"
      },
    ],
    // Высший орган управления
    organUpr: [
      {
        code: "1",
        name: "Общее собрание акционеров (Акционер)"
      },
      {
        code: "2",
        name: "Общее собрание участников"
      },
      {
        code: "3",
        name: "Иное (указать)"
      },
    ],
    // Тип субъекта
    typeSubject: [
      {
        code: "1",
        name: "Юридическое лицо"
      },
      {
        code: "2",
        name: "Физическое лицо"
      }
    ],
    // Статус ИСБОЮЛ
    stateIsboUL: [
      {
        code: "1",
        name: "Учредитель"
      },
      {
        code: "2",
        name: "Доверительный собственник"
      }
    ],
    // Коллегиальный исполнительный орган
    kolOU: [
      {
        code: "1",
        name: "Совет директоров (Наблюдательный совет)"
      },
      {
        code: "2",
        name: "Правление"
      },
      {
        code: "3",
        name: "Дирекция"
      },
      {
        code: "4",
        name: "Иное (указать)"
      }
    ],
    // Исполнительный орган управления
    ispOrg: [
      {
        code: "1",
        name: "Единоличный исполнительный орган"
      },
      {
        code: "2",
        name: "Коллегиальный исполнительный орган"
      },
      {
        code: "3",
        name: "Управляющая организация"
      }
    ],
    // Характер отношений
    charractDo: [
      {
        code: "1",
        name: "Разовые операции"
      },
      {
        code: "2",
        name: "Долговременные отношения"
      }
    ],
    // Тип счета
    accountType: [
      {
        code: "1",
        name: "Рублевый счет"
      },
      {
        code: "2",
        name: "Валютный счет"
      }
    ],
    // Виды услуг банка
    servicesBank: [
      {
        code: "1",
        name: "Аренда сейфовых ячеек"
      },
      {
        code: "2",
        name: "Валютообменные операции"
      },
      {
        code: "3",
        name: "Вклады"
      },
      {
        code: "4",
        name: "Денежные переводы"
      },
      {
        code: "5",
        name: "Депозиты"
      },
      {
        code: "6",
        name: "Документарные операции (аккредитивы и гарантии, инкассо)"
      },
      {
        code: "7",
        name: "Зарплатный проект"
      },
      {
        code: "8",
        name: "Кредитование"
      },
      {
        code: "9",
        name: "Операции с ценными бумагами"
      },
      {
        code: "10",
        name: "Платежные карты"
      },
      {
        code: "11",
        name: "Расчетно-кассовое обслуживание"
      },
      {
        code: "12",
        name: "Внешнеэкономическая деятельность"
      },
      {
        code: "13",
        name: "Безналичные расчеты в рублях"
      },
      {
        code: "14",
        name: "Безналичные расчеты в иностранной валюте"
      },
      {
        code: "15",
        name: "Прием и выдача наличных денежных средств"
      },
      {
        code: "16",
        name: "Размещение свободных денежных средств"
      },
      {
        code: "17",
        name: "Факторинг"
      },
      {
        code: "18",
        name: "Эквайринг"
      },
      {
        code: "19",
        name: "Инкассация"
      }
    ],
    // Категория предприятия
    companyCategory: [
      {
        code: '1',
        name: 'Крупное'
      },
      {
        code: '2',
        name: 'Среднее'
      },
      {
        code: '3',
        name: 'Малое'
      },
      {
        code: '4',
        name: 'Микро'
      }
    ],
    // Тип бухалтерской службы
    accountingServiceType: [
      {
        code: '1',
        name: 'Ведение бухгалтерского учета осуществляется руководителем'
      },
      {
        code: '2',
        name: 'Ведение бухгалтерского учета осуществляется бухгалтерским работником'
      },
      {
        code: '3',
        name: 'Договор о бухгалтерском сопровождении со сторонней организацией'
      }
    ],
    // Вид применяемой системы налогообложения
    taxationSystem: [
      {
        code: '1',
        name: 'ОСН — общая система налогообложения'
      },
      {
        code: '2',
        name: 'УСН — упрощенная система налогообложения'
      },
      {
        code: '3',
        name: 'ПАТЕНТ — патентная система налогообложения'
      },
      {
        code: '4',
        name: 'ЕНВД — вмененная система налогообложения или единый налог на вмененный доход'
      },
      {
        code: '5',
        name: 'ЕСХН — единый сельскохозяйственный налог'
      }
    ],
    // Вид идентификации
    identificationType: [
      {
        code: '0',
        name: 'Не проводится'
      },
      {
        code: '1',
        name: 'Упрощенный'
      },
      {
        code: '2',
        name: 'Стандартный'
      },
      {
        code: '3',
        name: 'Углубленный'
      }
    ],
    // Уровень идентификации
    identificationLevel: [
      {
        code: '0',
        name: 'Не идентифицирован'
      },
      {
        code: '1',
        name: 'Полная идентификация'
      },
      {
        code: '2',
        name: 'Частичная идентификация'
      }
    ],
    // Копии документов клиента
    copyDocClient: [
      {
        code: '1',
        name: 'Устав'
      },
      {
        code: '2',
        name: 'Учредительный договор'
      },
      {
        code: '3',
        name: 'Свидетельство о регистрации юридического лица'
      },
      {
        code: '4',
        name: 'Свидетельство о внесении записи в ЕГРЮЛ'
      },
      {
        code: '5',
        name: 'Действующая выписка из ЕГРЮЛ'
      },
      {
        code: '6',
        name: 'Свидетельство о постановке на учет в налоговом органе (Свидетельство ИНН)'
      },
      {
        code: '7',
        name: 'Лицензия на право осуществления деятельности, подлежащей лицензированию'
      },
      {
        code: '8',
        name: 'Доверенность представителя на заключение договоров и (или) открытие банковского счета и (или) ' +
          'распоряжения денежными средствами'
      },
      {
        code: '9',
        name: 'Документ, удостоверяющий личность представителя'
      },
      {
        code: '10',
        name: 'Трудовой договор (контракт) заключаемый с руководителем'
      },
      {
        code: '11',
        name: 'Приказ на лицо, указанное в карточке с образцами подписей и оттиска печати'
      },
      {
        code: '12',
        name: 'Приказ о назначении бухгалтерского работника, указанного в образцах подписей'
      },
      {
        code: '13',
        name: 'Документ, подтверждающий полномочия единоличного исполнительного органа'
      },
      {
        code: '14',
        name: 'Документ, подтверждающий полномочия коллегиального исполнительного органа'
      },
      {
        code: '15',
        name: 'Документ, подтверждающий полномочия управляющей организации'
      },
      {
        code: '16',
        name: 'Документ, удостоверяющий личность исполнительного органа'
      },
      {
        code: '17',
        name: 'Удостоверение адвоката'
      },
      {
        code: '18',
        name: 'Документ, подтверждающая учреждение адвокатского кабинета'
      },
      {
        code: '19',
        name: 'Приказ органа юстиции о назначении нотариусом'
      },
      {
        code: '20',
        name: 'Документ, подтверждающий местонахождение организации'
      },
      {
        code: '21',
        name: 'Копия годовой бухгалтерской отчётности (бухгалтерский баланс, отчёт о финансовом результате)'
      },
      {
        code: '22',
        name: 'Копия годовой (либо квартальной) налоговой декларации'
      },
      {
        code: '23',
        name: 'Копия аудиторского заключения на годовой отчёт за прошедший год'
      },
      {
        code: '24',
        name: 'Справка об исполнении налогоплательщиком обязанности по уплате налогов, сборов, пеней, штрафов'
      },
      {
        code: '25',
        name: 'Иной документ (указать)'
      }
    ],
    // Статус ПДЛ
    statePDL: [

      {
        code: '1',
        name: 'Иностранное публичное должностное лицо'
      },
      {
        code: '2',
        name: 'Российское публичное должностное лицо'
      },
      {
        code: '3',
        name: 'Должностное лицо публичной международной организации'
      }
    ],
    // Степень родства с ПДЛ
    rodPDL: [

      {
        code: '1',
        name: 'Супруг/супруга'
      },
      {
        code: '2',
        name: 'Отец/мать'
      },
      {
        code: '3',
        name: 'Сын/дочь'
      },
      {
        code: '4',
        name: 'Дедушка/бабушка'
      },
      {
        code: '5',
        name: 'Внук/внучка'
      },
      {
        code: '6',
        name: 'Брат/сестра (в том числе неполнородные)'
      },
      {
        code: '7',
        name: 'Отчим/мачеха'
      },
      {
        code: '8',
        name: 'Пасынок/падчерица'
      }
    ],
    // Уровень окружения ПДЛ
    okrPDL: [
      {
        code: '1',
        name: 'Партнер по бизнесу'
      },
      {
        code: '2',
        name: 'Советник'
      },
      {
        code: '3',
        name: 'Консультант'
      },
      {
        code: '4',
        name: 'Иное (указать)'
      }
    ],
    // Роль контрагента
    partnerRole: [
      {
        code: '1',
        name: 'Плательщик'
      },
      {
        code: '2',
        name: 'Получатель'
      }
    ],
    riskTypes: [
      {id: '1', name: 'Клиентский'},
      {id: '2', name: 'Страновой'},
      {id: '3', name: 'Операционный'}
    ],
    riskNpa: [
      {id: '1', name: 'Федеральный закон 115-ФЗ'},
      {id: '2', name: 'Положение Банка России 375-П'},
      {id: '3', name: 'Указание Банка России 4936-У'},
      {id: '4', name: 'Положение Банка России 639-П'},
      {id: '5', name: 'Указание Банка России 4077-У'}
    ],
    checkDictType: [
      {id: 'mvk', name: 'Перечень решений Межведомственной комиссии'},
      {
        id: 'terror',
        name: 'Перечень террористов и экстремистов'
      },
      {id: 'fromu', name: 'Перечень субъектов, причастных к распространению ОМУ'},
      {id: 'otkaz', name: 'Реестр 764-П'},
      {id: 'fts', name: 'Перечень должников ФТС'},
      {id: 'riskGroup', name: 'Реестр 794-П'},
      {id: 'msfound', name: 'Перечень массовых учредителей ЮЛ'},
      {id: 'mslead', name: 'Перечень массовых руководителей ЮЛ'},
      {id: 'ms_adr_info', name: 'Перечень адресов массовой регистрации ЮЛ'},
      {id: 'passport', name: 'Перечень недействительных паспортов'},
      {id: 'internal', name: 'Внутренний перечень организации'},
      {id: 'paycard', name: 'Перечень стран иностранных банков-эмитентов платежных карт'}
    ],
    transactionsStatuses: [{
      id: "NEW",
      name: "ожидает обработки",
    },
    {
      id: "PROCESSED",
      name: "обработана",
    }],
    // Тип фактора
    typeClassList: [
      {code: 'DOSSIER', name: 'Проверка субъектов Досье'},
      {code: 'FZ115', name: 'Указание Банка России 5861-У'},
      {code: 'P375', name: 'Положение Банка России 375-П'},
    ],
    // Код отказа
    kodOtkaz: [
      {
        code: '03',
        name: '(03) в случае отказа от заключения договора банковского счета (вклада) в соответствии с правилами внутреннего контроля ' +
          'кредитной организации в случае наличия подозрений о том, что целью заключения такого договора является совершение операций в ' +
          'целях легализации (отмывания) доходов, полученных преступным путем, или финансирования терроризма'
      },
      {
        code: '07',
        name: '(07) для совершения операции не представлены документы, необходимые для фиксирования информации в соответствии с ' +
          'положениями Федерального закона N 115-ФЗ'
      },
      {
        code: '08',
        name: '(08) в результате реализации правил внутреннего контроля в целях противодействия легализации (отмыванию) доходов, ' +
          'полученных преступным путем, и финансированию терроризма у работников кредитной организации (филиала кредитной организации), ' +
          'НФО (филиала НФО) возникли подозрения, что операция совершается в целях легализации (отмывания) доходов, полученных ' +
          'преступным путем<'
      },
      {
        code: '09',
        name: '(09) в случае расторжения договора банковского счета (вклада) с клиентом в случае принятия в течение календарного года ' +
          'двух и более решений об отказе в выполнении распоряжения клиента о совершении операции'
      },
      {
        code: '80',
        name: '(80) в результате реализации правил внутреннего контроля в целях противодействия легализации (отмыванию) доходов, ' +
          'полученных преступным путем, и финансированию терроризма у работников кредитной организации (филиала кредитной организации), ' +
          'НФО (филиала НФО) возникли подозрения, что операция совершается в целях финансирования терроризма'
      }
    ],
    bfTypeList: [
      {
        code: 1,
        name: 'Идентифицирован банком'
      },
      {
        code: 2,
        name: 'Информация предоставлена клиентом'
      },
      {
        code: 3,
        name: 'Иное'
      },
    ],
    filialList: [
      {
        code: 1,
        name: 'Головная организация'
      },
      {
        code: 2,
        name: 'Филиал'
      },
      {
        code: 3,
        name: 'Представительство'
      },
    ],
    PRNapr : [
      {
        code: '000',
        name: '000 Первичное направление'
      },
      {
        code: '101',
        name: '101 Направление в случае получения квитанции о непринятии эл. сообщения'
      },
      {
        code: '201',
        name: '201 Направление в случае получения квитанции о непринятии вложений'
      }
    ],
    identBenefitsiar: [
      {
        code: '11',
        name: ' - если бенефициарным владельцем является лицо, не занимающее должность (осуществляющее функции) единоличного исполнительного органа юридического лица'
      },
      {
        code: '12',
        name: ' – если бенефициарным владельцем является либо признано, ввиду не выявления в результате предпринятия предусмотренных Федеральным законом № 115-ФЗ мер, лицо, занимающее должность (осуществляющее функции) единоличного исполнительного органа юридического лица'
      },
      {
        code: '21',
        name: ' – если бенефициарным владельцем клиента является иное физическое лицо'
      },
      {
        code: '23',
        name: ' – если бенефициарным владельцем является сам клиент'
      }
    ],
    priznDolzhLits: [
      {
        code: '0',
        name: ' - физическое лицо не является лицом, указанным в <1>, <2>, <3>, <4>, <5>, <6>, <7>, <8>'
      },
      {
        code: '1',
        name: ' – физическое лицо является иностранным публичным должностным лицом'
      },
      {
        code: '2',
        name: ' – физическое лицо является супругом или близким родственником иностранного публичного должностного лица'
      },
      {
        code: '3',
        name: ' – физическое лицо является должностным лицом публичной международной организации'
      },
      {
        code: '4',
        name: ' – физическое лицо замещает (занимает) государственную должность Российской Федерации'
      },
      {
        code: '5',
        name: ' – физическое лицо замещает (занимает) должность члена Совета директоров Банка России'
      },
      {
        code: '6',
        name: ' – физическое лицо замещает (занимает) должность федеральной государственной службы, назначение на которую и освобождение от которой осуществляются Президентом Российской Федерации или Правительством Российской Федерации'
      },
      {
        code: '7',
        name: ' – физическое лицо замещает (занимает) должность в Банке России'
      },
      {
        code: '8',
        name: ' – физическое лицо замещает (занимает) должность в государственной корпорации и иной организации, созданной Российской Федерацией на основании федеральных законов'
      }
    ],
    priznNalLitsenz: [
      {
        code: '00',
        name: ' – при отсутствии лицензии'
      },
      {
        code: '11',
        name: ' – при наличии лицензии'
      }
    ],
    priznNalVP: [
      {
        code: '00',
        name: ' – при отсутствии оснований'
      },
      {
        code: '11',
        name: ' – при наличии оснований'
      }
    ],
    prisutAdrIuL: [
      {
        code: '0',
        name: ' – в случае отсутствия'
      },
      {
        code: '1',
        name: ' – в случае присутствия'
      }
    ],
    identBenefits: [
      {
        code: '11',
        name: ' – выявлен (для случаев выявления бенефициарного владельца, не являющегося лицом, занимающим должность (осуществляющим функции) единоличного исполнительного органа юридического лица)'
      },
      {
        code: '12',
        name: ' – выявлен (для случаев выявления бенефициарного владельца, являющегося одновременно лицом, занимающим должность (осуществляющим функции) единоличного исполнительного органа юридического лица)'
      },
      {
        code: '13',
        name: ' – не выявлен (для случаев, когда в результате принятия предусмотренных Федеральным законом\n' +
          '№115-ФЗ мер по идентификации бенефициарного владельца бенефициарный владелец не выявлен и бенефициарным владельцем признано  лицо, занимающее должность (осуществляющее функции) единоличного исполнительного органа юридического лица)'
      },
      {
        code: '21',
        name: ' – выявлен (в случае наличия у физического лица и индивидуального предпринимателя бенефициарного владельца)'
      },
      {
        code: '23',
        name: ' – не выявлен (в случае отсутствия у физического лица и индивидуального предпринимателя бенефициарного владельца)'
      },
    ],
    tipOperator: [
      {
        code: '1',
        name: 'банк плательщика'
      },
      {
        code: '2',
        name: 'банк получателя'
      },
    ],
    pravStatusPoluch: [
      {
        code: '1',
        name: 'для индивидуальных предпринимателей'
      },
      {
        code: '2',
        name: 'для адвокатов'
      },
      {
        code: '3',
        name: 'для нотариусов'
      },
      {
        code: '4',
        name: 'для иных физических лиц, занимающихся в установленном законодательством Российской Федерации порядке частной практикой'
      },
    ],
    statusPerevod: [
      {
        code: 'выплачен',
        name: ''
      },
      {
        code: 'не выплачен',
        name: ''
      }
    ]
  };

  remoteDict: { [name: string]: DictionaryType; } = {
    okv: {
      separateEntity: false,
      name: 'Общероссийский классификатор валют',
      list: [],
    } as DictionaryType,
    oksm: {
      separateEntity: false,
      name: 'Общероссийский классификатор стран мира',
      list: [],
    } as DictionaryType,
    participant_state: {
      separateEntity: true,
      name: 'Статусы участников',
      list: []
    } as DictionaryType,
    okato: {
      separateEntity: false,
      list: [],
    } as DictionaryType,
    okved: {
      separateEntity: true,
      list: [],
    } as DictionaryType,
    okved2: {
      separateEntity: true,
      list: [],
    } as DictionaryType,
    okfs: {
      separateEntity: true,
      name: 'Общероссийский классификатор форм собственности',
      list: [],
    } as DictionaryType,
    okopf: {
      separateEntity: true,
      list: [],
    } as DictionaryType,
    typesubjects: {
      separateEntity: true,
      list: [],
    } as DictionaryType,
    resultchecking: {
      separateEntity: true,
      list: [],
    } as DictionaryType,
    listView4936U: {
      separateEntity: true,
      list: [],
    } as DictionaryType,
    '5599u': {
      separateEntity: true,
      list: [],
    } as DictionaryType,
    rejectReasonCode639P: {
      separateEntity: true,
      list: [],
    } as DictionaryType,
    codeMessage4936U: {
      separateEntity: true,
      list: [],
    } as DictionaryType,
    codeMessage639P: {
      separateEntity: true,
      list: [],
    } as DictionaryType,
    fiasregion: {
      separateEntity: false,
      list: [],
    } as DictionaryType,
    npa: {
      separateEntity: true,
      list: [],
    } as DictionaryType,
    affiliationpdl: {
      separateEntity: true,
      list: [],
    } as DictionaryType,
    typeaccountingservice: {
      separateEntity: true,
      list: [],
    } as DictionaryType,
    kodvalosn: {
      separateEntity: true,
      list: [],
    } as DictionaryType,
    viddoc: {
      separateEntity: false,
      list: [],
    } as DictionaryType,
    bankopervid: {
      separateEntity: true,
      list: [],
    } as DictionaryType,
    currcash: {
      separateEntity: true,
      list: [],
    } as DictionaryType,
    orgforminboul: {
      separateEntity: true,
      list: [],
    } as DictionaryType,
    nfovid: {
      separateEntity: true,
      list: [],
    } as DictionaryType,
    nfotype: {
      separateEntity: true,
      list: [],
    } as DictionaryType,
    nkovid: {
      separateEntity: true,
      list: [],
    } as DictionaryType,
    notifications: {
      separateEntity: true,
      list: [],
    } as DictionaryType,
    typecitizenship: {
      separateEntity: true,
      list: [],
    } as DictionaryType,
    typeactivityprivateentrepreneur: {
      separateEntity: true,
      list: [],
    } as DictionaryType,
    bankservice: {
      separateEntity: true,
      list: [],
    } as DictionaryType,
    documenttypecodes: {
      separateEntity: true,
      list: [],
    } as DictionaryType,
    risktype: {
      separateEntity: true,
      list: [],
    } as DictionaryType,
    risklevel: {
      separateEntity: true,
      list: [],
    } as DictionaryType,
    riskgroup: {
      separateEntity: true,
      list: [],
    } as DictionaryType,
    typeulfl: {
      separateEntity: true,
      list: [],
    } as DictionaryType,
    taxationsystem: {
      separateEntity: true,
      list: [],
    } as DictionaryType,
    paycard_class: {
      separateEntity: true,
      list: [],
    } as DictionaryType,
    paycard_type: {
      separateEntity: true,
      list: [],
    } as DictionaryType,
    usersFM: {
      separateEntity: false,
      list: [],
    } as DictionaryType,
    usersSvetofor: {
      separateEntity: false,
      list: [],
    } as DictionaryType,
    usersRejection: {
      separateEntity: false,
      list: [],
    } as DictionaryType,
    strateg: {
      separateEntity: false,
      list: [],
    } as DictionaryType,
    criticallists: {
      separateEntity: true,
      list: [],
    } as DictionaryType,
    typeidentification: {
      separateEntity: true,
      list: [],
    } as DictionaryType,
    postRPDL: {
      separateEntity: true,
      list: [],
    } as DictionaryType,
    //при получении users ключ соответствует groupType
    strateg5392u : {
      separateEntity: false,
      list: [],
    } as DictionaryType,
    strateg655p: {
      separateEntity: false,
      list: [],
    } as DictionaryType,
    elMsgFm: {
      separateEntity: false,
      list: [],
    } as DictionaryType,
    fm : {
      separateEntity: false,
      list: [],
    } as DictionaryType,
    svetofor: {
      separateEntity: false,
      list: [],
    } as DictionaryType,
    control6670 : {
      separateEntity: false,
      list: [],
    } as DictionaryType,
  };

  constructor(private api: ApiService) {
  }

  /**
   * Получить статичные справочники
   * @param type - тип справочника
   */
  getStaticData(type: string): any {
    return this.staticData[type];
  }

 /**
  * Получить наименование из справочника по коду
  */
  getStaticDataValue(type: string, code: string) {
    const findObj = this.staticData[type].find(item => {
      return item['code'] === code;
    });
    if (findObj) {
      return findObj['name'];
    } else {
      return code;
    }
  }

  getNpa(id: string, npaList: any) {
    const npaObj = npaList.find(item => {
      return item['id'] === id;
    });
    if (npaObj) {
      return npaObj['name'];
    } else {
      return id;
    }
  }

  getTypeSubjectName(code: string, typeSubjects: any) {
    return this.getDictNameByCode(code, typeSubjects);
  }

  getDictNameByCode(code: string, dictItems: any[]) {
    if (code) {
      return dictItems?.find(item => item.code === code)?.name || "";
    }
    return "";
  }

  /**
   * Загрузить справочники
   * @param type - тип справочника
   * @param pagination - пагинатор
   * @deprecated (using method getRemoteDictData())
   */
  getRemoteData(type: string, pagination?: DictionaryPagination): Promise<any> {
    return new Promise<any>(resolve => {
      if (this.remoteDict[type].list.length > 0) {
        resolve(this.remoteDict[type].list);
      }
      const dictionaryDescription = this.remoteDict[type];
      // TODO: Добавить сравнение pagination и дозагрузку данных если это необходимо
      if (dictionaryDescription && dictionaryDescription.list && dictionaryDescription.list.length > 0) {
        return;
      }
      if (!pagination) {
        pagination = {
          size: 20000,
          page: 0,
          sort: 'name',
          dir: 'ASC'
        } as DictionaryPagination;
        if (['okved', 'okved2', 'bankopervid', 'currcash', 'resultchecking', 'risklevel', 'listView4936U', '5599u',
          'orgforminboul', 'typeidentification', 'typeactivityprivateentrepreneur', 'codeMessage4936U'].includes(type)) {
          pagination.sort = 'id.code';
        }
      }
      // загрузка по общей схеме через параметр dict
      let url = `/api/v1/qref/dict/entry?` +
        `page=${pagination.page}&` +
        `size=${pagination.size}&` +
        // `dir=${pagination.dir}&` +
        `dict=${type}`;
      if(pagination.sort)
        url += `&sort=${pagination.sort}`
      // separateEntity = false - грузим по своему
      if (dictionaryDescription && dictionaryDescription.separateEntity === false) {
        url = `/api/v1/qref/${type}/entry?` +
          `page=${pagination.page}&` +
          `size=${pagination.size}`;
        // +
        // `sort=${pagination.sort}&` +
        // `dir=${pagination.dir}`;
      }
      this.api.get(url).subscribe(data => {
          this.remoteDict[type].list = this.prepareRemoteData(type, data.content);
          resolve(this.remoteDict[type].list);
        }
      );
    });
  }

  private prepareRemoteData(type: String, data: Array<any>): Array<any> {
    if(type === 'codeMessage4936U'){
      //записи с кодами '01' и '02' должны быть в конце списка
      return data.filter(r => !['01', '02'].includes(r.code)).concat(data.filter(r => ['01', '02'].includes(r.code)));
    }
    return data;
  }

  getRemoteDictData(typeDict: string, pagination = {
    size: 20000,
    page: 0,
    sort: 'name',
    dir: 'ASC'
  } as DictionaryPagination, type): Observable<any> {
    return this.api.get(`/api/v1/qref/${typeDict}${type ? `/${type}` : ''}/entry`, pagination).pipe(debounceTime(300), map((data) => {
      this.remoteDict[typeDict].list = data.content;
      return this.remoteDict[typeDict].list;
    }));
  }

/**
   * получение справочника Повышающий коэффициент
   */
 getscoreDegree() {
  return this.api.get("/api/v1/qref/scoreDegree/entry");
}

  /**
   * Метод загрузки типов клиента
   */
  loadTypeClient(): Promise<any> {
    return this.getRemoteData("typeulfl", {
      size: 20000,
      page: 0,
      sort: 'id.code',
      dir: 'ASC'
    });
  }

  /**
   * Загрузка удаленного справочника с сортировкой по коду (по умолчанию - по наименованию)
   */
  getRemoteDataCodeSort(type:string): Promise<any> {
    return this.getRemoteData(type, {
      size: 20000,
      page: 0,
      sort: 'id.code',
      dir: 'ASC'
    });
  }

  getKodOtkaz() {
    return [
      {
        code: '03',
        name: '(03) в случае отказа от заключения договора банковского счета (вклада) в соответствии с правилами внутреннего контроля ' +
          'кредитной организации в случае наличия подозрений о том, что целью заключения такого договора является совершение операций в ' +
          'целях легализации (отмывания) доходов, полученных преступным путем, или финансирования терроризма'
      },
      {
        code: '07',
        name: '(07) для совершения операции не представлены документы, необходимые для фиксирования информации в соответствии с ' +
          'положениями Федерального закона N 115-ФЗ'
      },
      {
        code: '08',
        name: '(08) в результате реализации правил внутреннего контроля в целях противодействия легализации (отмыванию) доходов, ' +
          'полученных преступным путем, и финансированию терроризма у работников кредитной организации (филиала кредитной организации), ' +
          'НФО (филиала НФО) возникли подозрения, что операция совершается в целях легализации (отмывания) доходов, полученных ' +
          'преступным путем<'
      },
      {
        code: '09',
        name: '(09) в случае расторжения договора банковского счета (вклада) с клиентом в случае принятия в течение календарного года ' +
          'двух и более решений об отказе в выполнении распоряжения клиента о совершении операции'
      },
      {
        code: '80',
        name: '(80) в результате реализации правил внутреннего контроля в целях противодействия легализации (отмыванию) доходов, ' +
          'полученных преступным путем, и финансированию терроризма у работников кредитной организации (филиала кредитной организации), ' +
          'НФО (филиала НФО) возникли подозрения, что операция совершается в целях финансирования терроризма'
      }
    ];
  }
}

export interface DictionaryType {
  type: string; // Тип справочника по имени на сервере
  name?: string; // Русское описание справочника
  separateEntity: boolean; // Показатель грузить ли через общий метод или через свой
  list: Array<any>; // Место для загрузки справочника
  // pagination: DictionaryPagination; // Параметры пагинации с которыми был загружен (в дальнейшем пригодится при кешировании)
}

export interface DictionaryPagination {
  page: number;
  size: number;
  sort?: string;
  dir?: string;
  search?: string;
  type?: string;
}
