import {Component, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ITable} from 'src/app/core';
import {ViewFreezeInfoCreditOrg} from '../views';
import {DocumentBaseService, DocumentSubForm, DocumentTypeEnum} from "../../../../../../../documentForm";

@Component({
  selector: 'app-armfm-inf-freeze-table-info-org-v1',
  templateUrl: './table-if-info-credit-org.html',
  styleUrls: ['../../../../../../../documentForm/base/documentForm.component.scss']
})
export class TableIfInfoCreditOrg
  extends DocumentSubForm
  implements OnInit, ITable
{
  documentType = DocumentTypeEnum.FREEZING_FUNDS;
  viewForm = ViewFreezeInfoCreditOrg;
  keyData = 'mery';
  total = 0;
  pageSize = 10;
  page = 0;
  constructor(
    protected readonly modalService: NgbModal,
    private readonly docSrv: DocumentBaseService
  ) {
    super(modalService);
  }

  currentGetFormGroup = (data) => this.docSrv.getSvedKoFreeze.call(this.docSrv, data);
  ngOnInit(): void {
    super.ngOnInit();
  }
  onPageChange(e) {}
}
