import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ViewOsnovSovOperComponent} from "./view-osnov-sov-oper.component";
import {ITable} from "@amlCore/components";
import {DocumentSubForm} from "@amlDocument/base";
import {DocumentBaseService} from "@amlDocument/services";
import {DocumentTypeEnum} from "../../../enums";


@Component({
  selector: 'app-arm-doc-table-osnov-sov-oper',
  templateUrl: './table-osnov-sov-oper.component.html',
  styleUrls: ["../../../base/documentForm.component.scss"],
})
export class TableOsnovSovOperComponent extends DocumentSubForm implements OnInit, OnChanges, ITable {
  @Input() documentType: DocumentTypeEnum;
  viewForm = ViewOsnovSovOperComponent;

  constructor(
    protected readonly modalService: NgbModal,
    private readonly docSrv: DocumentBaseService
  ) {
    super(modalService);
  }

  currentGetFormGroup = (data) => this.docSrv.getOsnovanieOp.call(this.docSrv, data);

  ngOnInit() {
    super.ngOnInit();
  }

  ngOnChanges(change: SimpleChanges) {
    super.ngOnChanges(change);
  }

  get is5392() {
    return this.documentType === DocumentTypeEnum.OES_OTKAZ_GOZ;
  }
}
