import {Component, Input} from '@angular/core';
import {FormArray} from "@angular/forms";
import {DocumentBaseService} from "../../services";
import {map} from "rxjs/operators";
import {DictionariesService, ICodeName as dictionaryInterface} from "../../../../dictionaries";
import {Observable} from "rxjs";

@Component({
  selector: 'app-arm-doc-multiple-mera-osnovanie',
  template: `
    <div class="form-group pl-0 pr-0">
      <app-multiple-field-description
        [showLabel]="true"
        [submitted]="submitted"
        [placeholderMap]="placeholderStuffMap"
        [formArray]="formGroupApp"
        [createFormGroup]="docSrv.meraOsnovanie"
        fieldNameDescription="kodIuLFL"
        fieldNameValue="kodOsnovaniia"
        [dictionary]="dictionary | async"
        maxlengthDescription="100">
        </app-multiple-field-description>
    </div>
  `,
  styleUrls: ["../../base/documentForm.component.scss"],
})
export class MultipleMeraOsnovanieComponent {
  getDictionary = this.dictSrv.getQrefKodosnov4936List();
  dictionary: Observable<dictionaryInterface[]> = this.getDictionary.pipe(map(page => page.content));
  @Input() readonly formGroupApp: FormArray;
  @Input() readonly documentData: any;
  @Input() readonly submitted: any;
  placeholderStuffMap = new Map([
    ['kodOsnovaniia', 'Код основания применения мер'],
    ['kodIuLFL', 'Код организации или физического лица']
  ]);

  constructor(private dictSrv: DictionariesService,
              readonly docSrv: DocumentBaseService) {}
}
