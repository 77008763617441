export enum AccessEnum {
  ARM_FM_ACCESS = 'arm_fm',
  SVETOFOR_ACCESS = 'svetofor',
  REJECTION_ACCESS = 'reestr_rejection',
  REQUESTS_GA_ACCESS = 'requests-government-agencies',
  ACCESS_4937 = 'arm_4937_u',
  OES_ACCESS = 'oes-otkaz-goz',
  STRATEG_ACCESS = 'arm_strateg',
  ARM_407_ACCESS = 'arm_407',
  CONTROL_6670 = 'arm_6670',
}

export function getFMAccessList(): Array<string> {
  return Object.values(AccessEnum);
}
