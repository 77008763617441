import {Component, OnInit, OnDestroy, ChangeDetectorRef, Input} from "@angular/core";
import {BaseFilterController} from "../BaseFilterController";
import {FormBuilder} from "@angular/forms";
import {FmDraftFilter} from "@amlCore/models";
import {DictionaryService, StringDateAdapterService, TableService, ValidatorService} from "@amlCore/services";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: "app-fm-draft-list-filter",
  templateUrl: "./fmDraftListFilter.component.html",
})

export class FmDraftListFilterComponent extends BaseFilterController implements OnInit {
  public filterObjectModel = new FmDraftFilter();
  placeholder = 'Поиск по идентификатору документа';
  @Input() docTypes: []
  constructor(private readonly fb: FormBuilder,
              private readonly _changeDetectorRef: ChangeDetectorRef,
              protected tableSrv: TableService,
              private dictSrv: DictionaryService,
              public dateSrv: StringDateAdapterService,
              protected readonly activatedRoute: ActivatedRoute) {
    super(tableSrv, activatedRoute);
  }

  /**
   * Fix error type ExpressionChangedAfterItHasBeenCheckedError
   */
  ngAfterViewChecked(): void {
    this._changeDetectorRef.detectChanges();
  }

  ngOnInit() {
    this.init();
  }

  /**
   * Метод создания формы
   * @return FormGroup
   */
  public createFormModel() {
    const formConfig = new FmDraftFilter();

    return this.fb.group(formConfig);
  }

  getStartFilterModel(): FmDraftFilter {
    return {
      documentType: null,
      documentStatus: null
    } as FmDraftFilter;
  }

  /**
   * Описания полей
   */
  public getFilterDescription(): any {
    return {
      documentType: 'Тип документа',
      documentStatus: 'Статус',
      dateFrom: 'Дата с',
      dateTo: 'Дата по',
      creatorId: 'Владелец',
      createDateFrom: 'Дата импорта с',
      createDateTo: "Дата импорта по"
    };
  }
  docStatuses = [{id: 0, name: 'CREATED', title: 'Создан'},]

  get users() {
    return this.dictSrv?.remoteDict?.usersFM?.list || [];
  }
}
