import {Component, Input} from '@angular/core';
import {FormArray} from "@angular/forms";
import {DocumentBaseService} from "../../services";

@Component({
  selector: 'app-arm-doc-multiple-ident-instruct',
  template: `
    <h5 class="typography-h5 typography-blue pt-3">Идентификаторы иностранной структуры без образования ЮЛ</h5>
    <div class="form-group pl-0">
      <app-multiple-field-description [showLabel]="true"
                                      [submitted]="submitted"
                                      [placeholderMap]="placeholderStuffMap"
                                      [formArray]="formGroupApp"
                                      [createFormGroup]="docSrv.getRegINBOIuL"
                                      fieldNameDescription="kodINBOIuL"
                                      fieldNameValue="nomerINBOIuL"
                                      maxlengthDescription="50" maxlengthValue='50'></app-multiple-field-description>
    </div>
  `,
  styleUrls: ["../../base/documentForm.component.scss"],
})
export class MultipleIdentInstructComponent {
  @Input() readonly formGroupApp: FormArray;
  @Input() readonly documentData: any;
  @Input() readonly submitted: any;
  placeholderStuffMap = new Map([
    ['kodINBOIuL', 'Код ИНБОЮЛ'],
    ['nomerINBOIuL', 'Регистрационный номер ИНБОЮЛ']
  ]);

  constructor(readonly docSrv: DocumentBaseService) {}
}
