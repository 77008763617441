import {Component, OnInit} from '@angular/core';
import {FormArray, FormGroup} from "@angular/forms";
import {IconsService} from "@amlCore/services";
import {ActivatedRoute, Router} from "@angular/router";
import {CommonService, DocumentBase, DocumentBaseService, DocumentTypeEnum} from "../../../../../../documentForm";

@Component({
  selector: 'app-armfm-transaction-reject5861U-v1',
  templateUrl: './transaction-reject5861U-v1.html',
  styleUrls: ['../../../../../../documentForm/base/documentForm.component.scss']
})
export class TransactionReject5861UV1 extends DocumentBase implements OnInit {
  readonly documentType = DocumentTypeEnum.REFUSE_OPERATION;
  indexSved: number;
  indexOsnov: number;
  form: FormGroup;

  constructor(protected documentBaseService: DocumentBaseService,
              protected iconsService: IconsService,
              protected commonSrv: CommonService,
              protected activatedRoute: ActivatedRoute,
              protected router: Router) {
    super(documentBaseService, iconsService, commonSrv, activatedRoute, router);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  onSelectedSvedItem(index) {
    this.indexSved = index;
  }

  onSelectedOsnovItem(index) {
    this.indexOsnov = index;
  }

  initForm(): void {
    const transactionsReject = this.documentBaseService.getTransactionsRejectForm(this.d.informChast?.svedPredstav, this.documentType);
    this.fGroup('informChast').addControl('svedPredstav', transactionsReject);
  }

  /**
   * Подготовка начальных данных для формы создания документа
   */
  prepareInitDocumentData(): any{
    let documentData = this.commonSrv.prepareInitDocumentData(this.documentType);
    documentData.document.informChast.svedPredstav = [];
    return documentData;
  }


  get getFormSved(): FormArray {
    return this.fArray('informChast.svedPredstav')?.at(this.indexSved)?.get('svedOtkaz') as FormArray;
  }

  get getFormOsnov(): FormArray {
    return this.getFormSved?.at(this.indexOsnov)?.get('osnovanieOp') as FormArray;
  }

  get getFormUsers(): FormArray {
    return this.getFormSved?.at(this.indexOsnov)?.get('uchastnik') as FormArray;
  }
}
