import {Component, OnInit, OnDestroy, ChangeDetectorRef} from "@angular/core";
import {FormBuilder} from "@angular/forms";
import {FmDraftFilter} from "@amlCore/models";
import {DictionaryService, StringDateAdapterService, TableService, ValidatorService} from "@amlCore/services";
import {ActivatedRoute} from "@angular/router";
import { BaseFilterController } from "../../BaseFilterController";

@Component({
  selector: "app-sv-wait-list-filter",
  templateUrl: "./svetoforWaitListFilter.component.html",
})

export class SvetoforWaitListFilterComponent extends BaseFilterController implements OnInit {
  public filterObjectModel = new FmDraftFilter();
  placeholder = 'Поиск по идентификатору документа';

  constructor(private readonly fb: FormBuilder,
              private readonly _changeDetectorRef: ChangeDetectorRef,
              protected tableSrv: TableService,
              private dictSrv: DictionaryService,
              public dateSrv: StringDateAdapterService,
              protected readonly activatedRoute: ActivatedRoute) {
    super(tableSrv, activatedRoute);
  }

  /**
   * Fix error type ExpressionChangedAfterItHasBeenCheckedError
   */
  ngAfterViewChecked(): void {
    this._changeDetectorRef.detectChanges();
  }

  ngOnInit() {
    this.init();
  }

  /**
   * Метод создания формы
   * @return FormGroup
   */
  public createFormModel() {
    const formConfig = new FmDraftFilter();

    return this.fb.group(formConfig);
  }

  getStartFilterModel(): FmDraftFilter {
    return {
      documentType: null,
      documentStatus: null
    } as FmDraftFilter;
  }

  /**
   * Описания полей
   */
  public getFilterDescription(): any {
    return {
      documentType: 'Тип документа',
      documentStatus: 'Статус',
      dateFrom: 'Дата документа с',
      dateTo: 'Дата по',
      signDateFrom: 'Дата подписи с',
      signDateTo: 'Дата по',
      creatorId: 'Владелец',
    };
  }
  // todo вынос на сервер или энум если оставим тут
  docTypes = [
    {id: 7, name: 'REGISTRY_MEASURES', title: '794-П. Реестр принятых мер'},
    {id: 8, name: 'REGISTRY_CLIENTS', title: '794-П. Реестр клиентов кредитной организации'}
  ]
  docStatuses = [{id: 1, name: 'WAIT_SEND', title: 'Готов к отправке'},]

  get users() {
    return this.dictSrv?.remoteDict?.usersSvetofor?.list || [];
  }
}
