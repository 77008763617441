import {Component, OnInit} from '@angular/core';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ViewSvedFreeze} from "../views";
import {ITable} from 'src/app/core';
import {DocumentBaseService, DocumentSubForm, DocumentTypeEnum} from "../../../../../../../documentForm";


@Component({
  selector: 'app-armfm-table-sved-freeze-v1',
  templateUrl: './table-sved-freeze.html',
  styleUrls: ['../../../../../../../documentForm/base/documentForm.component.scss']
})
export class TableSvedFreeze extends DocumentSubForm implements OnInit, ITable {
  viewForm = ViewSvedFreeze;
  documentType = DocumentTypeEnum.FREEZING_FUNDS;
  keyData = '??'
  total = 0;
  pageSize = 10;
  page = 0;

  constructor(protected readonly modalService: NgbModal,
    private readonly docSrv: DocumentBaseService) {
    super(modalService)
  }
  onPageChange(e) {
  }

  ngOnInit(): void {
    super.ngOnInit();
  }
}
