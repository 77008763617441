import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {IconsService} from '@amlCore/services';
import { CommonService } from '../../services';
import {SaveFileService} from "../../../../core/services/save-file.service";

@Component({
  selector: 'app-common-send-documents',
  templateUrl: './commonSendDocuments.component.html',
  styleUrls: ['./commonSendDocuments.component.scss'],
})
export class CommonSendDocumentsComponent implements OnInit {
  icons = this._iconsService.getIcons();
  data: any;
  disabled = false;
  success: boolean;
  loader = false;
  error = '';
  stopSended = false;
  sendProgress = false;

  preparedDocs = [];      // готовые к отправке
  sendedContainers = [];  // отправленные
  docsToSend = [];        // ждут отправку
  sendedDocs = [];        // уже отправленные
  sectionType = '';

  constructor(private _activeModal: NgbActiveModal,
              private _iconsService: IconsService,
              private commonSrv: CommonService,
              private saveFileService: SaveFileService) { }

  ngOnInit(): void {
  }

  onClose(): void {
    this._activeModal.close(this.success);
  }

  open(item: any) {
    this.sectionType = item.section || '';
    this.data = item.item;
  }

  stopSend() {
    this.stopSended = true;
    this.sendProgress = false;
  }

  /* Отправка списка контейнеров, и скачивание документов по ID в зависимости от параметра download (true/false)*/
  sendDocuments(): void {
  this.disabled = true;
  this.loader = true;
  const listIds = this.data.map((item) => item.id);

  if (listIds.length === 0) {
    return;
  }

  const _iterate = this.iteratorObject(this.data);
  this.sendProgress = true;

  this.commonSrv.blockDocuments(listIds, this.sectionType).subscribe((block) => {

    this.commonSrv.sendDocuments(listIds, this.sectionType).subscribe(() => {

        if (this.stopSended) {
          this.stopSend();
          return;
        }

        const step = (iterate) => {
          const next = iterate.next();
          if (!next.done || !this.stopSended) {
            setTimeout(() => info(iterate), 1000)
          } else {
            this.sendProgress = false;
          }
        }

        const info = (iterate) => {
          // проверяем информацию по отправке
          this.commonSrv.sendDocumentsInfo(listIds, this.sectionType).pipe(
            ).subscribe((data) => {
              if (data.error !== null) {
                this.stopSend();
                this.error = data.error;
                // разблокировка всех при ошибке
                this.commonSrv.unBlockDocuments(listIds, this.sectionType,).subscribe((result) => {
                  //console.log(result);
                })
              }

              const IdsArr = data.containers;

              this.sendedDocs = data.sendedDocs;
              this.docsToSend = data.docsToSend;
              this.preparedDocs = data.preparedDocs;
              this.data.forEach((element: any) => {
                // отправленные
                element.success = IdsArr.some(container => this.sendedDocs[container.id]?.includes(element.id));
                // ожидают отправки
                element.docsToSend = this.docsToSend?.includes(element.id);
                if(element.docsToSend && !element.success)
                  return;
                // готовые к отправке
                element.preparedDocs = this.preparedDocs?.includes(element.id);
                if(element.preparedDocs)
                  return;
                // разблокировка отправленного
                this.commonSrv.unBlockDocuments([element.id], this.sectionType).subscribe((result) => {
                  //console.log(result);
                })
              });

              // если отправлен контейнер (completed=true)
              if (data.completed) {
                this.stopSend();

                const partDownloadUrl = this.sectionType === 'p407' ? 'requests/407p/receipt' : this.sectionType;
                // скачиваем все файлы по ID с сервера перебором
                for (let i=0; i <IdsArr.length; i++) {
                  this.saveFileService.saveFiles((this.sectionType ? `/api/v1/documents/${partDownloadUrl}/container/download/${IdsArr[i].id}` :  `/api/v1/documents/container/download/${IdsArr[i].id}`), IdsArr[i]?.name)
                }
                this.disabled = false; this.success = true;this.loader = false;
                this.commonSrv.unBlockDocuments(listIds, this.sectionType,).subscribe((result) => {
                  //console.log(result);
                });
              } else {
                step(iterate);
              }
            },
            err => {
              this.stopSended = true;
              this.error = err;
              this.disabled = false; this.success = true;this.loader = false;
              this.commonSrv.unBlockDocuments(listIds, this.sectionType).subscribe((result) => {
                console.log(result);
              });
            })
        }
        // информация по отправке
        info(_iterate)
    },
    (err) => {
      this.disabled = false;
      this.success = false;
      this.error = err?.error ?? err,
      this.stopSended = true;
      this.loader = false;
      this.commonSrv.unBlockDocuments(listIds, this.sectionType).subscribe((result) => {
      });
    },
    () => { }
    )
  }, (errorBlock) => {
    this.disabled = false;
    this.success = false;
    this.error = errorBlock?.error ?? errorBlock,
    this.stopSended = true;
    this.loader = false;
  });

}

iteratorObject(array) {
  let nextIndex = 0;
  return {
    next: function (): any {
      return nextIndex < array.length ?
        {object: array[nextIndex++], done: false} :
        {done: true};
    }
  }
}
}

