import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormGroup, Validators} from "@angular/forms";
import {DocumentComponentBaseSub} from "@amlDocument/base";
import {DocumentBaseService} from "@amlDocument/services";
import {QrefOkatoByOblViewComponent} from "../../../../../dictionaries/components/views/common";
import {AccordionFormGroupComponent} from "../accordion-form-group.component";

@Component({
    selector: "app-arm-doc-view-address[formGroupApp][isReadOnly][submitted]",
    templateUrl: "./address.component.html",
    styleUrls: ["../../../base/documentForm.component.scss"],
})
export class AddressComponent extends DocumentComponentBaseSub implements OnInit, OnDestroy {
    @ViewChild("okatoSelect") okatoSelect: QrefOkatoByOblViewComponent;
    @Input() formGroupApp: FormGroup;
    @Input() isAttachments = false;
    @Input() isBirthPlace = false;
    @Input() is5392 = false;
    @Input() documentData: any;
    @Input() title: string;

    @ViewChild('accordionFormGroupComponent', { static: true, read: AccordionFormGroupComponent })
    accordionFormGroupComponent: AccordionFormGroupComponent;

    constructor(readonly docSrv: DocumentBaseService) {
        super();
    }

  ngOnInit(): void {
    if (this.is5392) {
      this.formGroupApp.get('kodOKSM').setValidators([Validators.required]);
      this.formGroupApp.get('punkt').setValidators([Validators.required]);
    }
  }

  /**
     * Заполняем адрес из фиаса
     */
    fillAddress(data) {
        if (data) {
            this.formGroupApp.patchValue({
                indeks: data.postalcode,
                punkt: data.locality,
                ulitsa: data.street,
                dom: data.house,
                korp: data.building ?? '',
                of: ''
            });
            this.okatoSelect.fillByRegionCode(data.regionCode);
        }
    }

    get isNullFieldsAddress(): boolean {
      return !this.field('indeks').value &&
        !this.field('kodOKSM').value &&
        !this.field('kodSubieektaPoOKATO').value &&
        !this.field('rajon')?.value &&
        !this.field('punkt').value &&
        !this.field('ulitsa').value &&
        !this.field('dom').value &&
        !this.field('korp').value &&
        !this.field('of').value;
    }

    getCondition(fieldName: string): boolean {
        return !this.field('adresStroka')?.value ||
        (this.field('adresStroka')?.value && this.field(fieldName)?.value);
    }
}
