import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {User} from "../models";
import {ApiService} from "../services/api.service";

@Injectable()
export class ResourceService {

  constructor(private api: ApiService) {}

  public getMvkReshIdBySubjectId(subjectId: string): Observable<any> {
    return this.api.get(`/api/v1/qref/mvk/resh/${subjectId}`);
  }

  public getMsLead(id: string): Observable<any> {
    return this.api.get("/api/v1/qref/mslead/" + id);
  }

  public getMsFound(id: string): Observable<any> {
    return this.api.get("/api/v1/qref/msfound/" + id);
  }

  public getFts(id: string): Observable<any> {
    return this.api.get("/api/v1/qref/fts/" + id);
  }
  
  public getRiskGroup(id: string): Observable<any> {
    return this.api.get("/api/v1/cls/kyc/" + id);
  }

  public getMsAdr(id: string): Observable<any> {
    return this.api.get("/api/v1/qref/msadr/" + id);
  }

  public getPassport(id: string): Observable<any> {
    return this.api.get("/api/v1/qref/passport/" + id);
  }

  public getPassportInfo(params: any): Observable<any> {
    return this.api.get("/api/v1/qref/passport/entries", params, 'id');
  }

  public getMassFoundInfo(params: any): Observable<any> {
    return this.api.get("/api/v1/qref/msfound/entries", params, 'id');
  }

  public getMassLeadInfo(params: any): Observable<any> {
    return this.api.get("/api/v1/qref/mslead/entries", params, 'id');
  }

  public getMassAdrInfo(params: any): Observable<any> {
    return this.api.get("/api/v1/qref/msadr/entries", params, 'id');
  }

  public getFtsInfo(params: any): Observable<any> {
    return this.api.get("/api/v1/qref/fts/entries", params, 'id');
  }

  public getRiskGroupInfo(params: any): Observable<any> {
    return this.api.get("/api/v1/cls/kyc/info/", params, 'id');
  }
  
  public getBounceInfoList(params: any): Observable<any> {
    return this.api.get('/api/v1/cls/refusal/accepted/', params, 'id');
  }

  public getFromuInfoList(params: any): Observable<any> {
    return this.api.get('/api/v1/qref/fromu/info', params, 'id');
  }

  public getMvkInfoList(params: any): Observable<any> {
    return this.api.get('/api/v1/qref/mvk/info', params, 'id');
  }

  /**
   * Получить список файлов террористов
   */
  public getTerroristFiles(params: object): Observable<any> {
    return this.api.get("/api/v1/qref/terror/info/full", params, 'id');
  }

  /**
   * Получение списка пользователей для фильтрации
   */
  public getUserList(params: any): Observable<any> {
    return this.api.get("/api/v1/authad/users", params);
  }

  /**
   * Получить список событий
   */
  public getEventList(params: any): Observable<any> {
    return this.api.get("/api/v1/audit/events", params);
  }

  /**
   * Получить список пользователей по заданным разрешениям
  */
 public getUsersByPermissions(params: String[]): Observable<User[]> {
   return this.api.post("/api/v1/authad/users/permission", params);
 }

}
