import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ViewTrSvedReject} from "../views";
import {ITable} from "@amlCore/components";
import {DocumentBaseService, DocumentSubForm, DocumentTypeEnum} from "../../../../../../../documentForm";


@Component({
  selector: 'app-armfm-table-sved-reject-v1',
  templateUrl: './table-sved-reject.html',
  styleUrls: ['../../../../../../../documentForm/base/documentForm.component.scss']
})
export class TableSvedReject extends DocumentSubForm implements OnInit, OnChanges, ITable {
  @Input() documentType: DocumentTypeEnum;
  viewForm = ViewTrSvedReject;

  constructor(
    protected readonly modalService: NgbModal,
    private readonly docSrv: DocumentBaseService
  ) {
    super(modalService);
  }

  currentGetFormGroup = (data) => this.docSrv.getSvedOtkaz.apply(this.docSrv, [data, null, this.documentType]);

  ngOnInit() {
    super.ngOnInit();
  }

  ngOnChanges(change: SimpleChanges) {
    super.ngOnChanges(change);
  }
}
