import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {BehaviorSubject} from "rxjs";
import {DocumentBaseService} from "@amlDocument/services";
import {DocumentComponentBaseSub} from "@amlDocument/base";
import {DictionariesService} from "src/app/dictionaries";
import {takeUntil} from "rxjs/operators";
import {DocumentTypeEnum} from "../../../enums";

@Component({
    selector: "app-arm-doc-info-auth-person-org",
    templateUrl: "./info-auth-person-org.component.html",
    styleUrls: ["../../../base/documentForm.component.scss"],
})
export class InfoAuthPersonOrgComponent
    extends DocumentComponentBaseSub
    implements OnInit, OnDestroy
{
    @Input() formGroupApp: FormGroup;
    @Input() documentData: any;
    @Input() documentType: DocumentTypeEnum;
    @Input() isReadOnly: boolean;
    @Input() submitted: boolean;
    //полученный список сотрудников
    persons$ = new BehaviorSubject(null);
    //Для формы добавления сотрудника
    authorizedPersonFormGroup: FormGroup;
    selectControl = new FormControl();
    is5392 = false;

    constructor(
        private dictSrv: DictionariesService,
        private modalService: NgbModal,
        readonly docSrv: DocumentBaseService
    ) {
        super();
    }

    ngOnInit(): void {
        this.dictSrv
            .getQrefAuthorizedPersonList()
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe((data) => this.persons$.next(data.content));

        if (this.documentType === DocumentTypeEnum.OES_OTKAZ_GOZ) this.is5392 = true;
        if (this.isReadOnly) this.selectControl.disable();
        this.selectControl.valueChanges.subscribe(value => {
            this.setValueToForm(value);
        })
    }

    setValueToForm(value: any): void {
        this.formGroupApp.get("telUpolnSotrudn").setValue(value?.telOs);
        this.formGroupApp.get("elektronnaiaPochta").setValue(value?.emailOs);
        this.formGroupApp.get("upolnSotrudn").setValue(value?.postOs);

        const fioWorker = this.formGroupApp.get("fioupolnSotrudn");

        fioWorker.get("fam").setValue(value?.fos);
        fioWorker.get("imia").setValue(value?.ios);
        fioWorker.get("otch").setValue(value?.oos);
        fioWorker.get("fiostroka").setValue(value?.fiostroka);

        this.formGroupApp.updateValueAndValidity();
        this.formGroupApp.markAsDirty();
    }

    deleteAuthorizedPerson(): void {
        this.dictSrv
            .deleteAuthorizedPerson(this.selectControl.value?.id)
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe();

        const resultContent = this.persons$.value.filter(
            (element) => element.id != this.selectControl.value?.id
        );

        this.persons$.next(resultContent);
        this.selectControl.reset();
    }

    open(content) {
        this._createFormGroup();
        this.modalService.open(content, { ariaLabelledBy: "modal-basic-title" }).result.then(
            (result) => {
                this.setValueToForm(result);
                result.id = Math.floor(Math.random() * Date.now()).toString();
                this.dictSrv
                    .addNewAuthorizedPerson(result)
                    .pipe(takeUntil(this.componentDestroyed$))
                    .subscribe();
                this.selectControl.setValue(result);

                //Проверка на наличие данных в массиве. Если их нет, то просто отправляем result
                this.persons$.value
                    ? this.persons$.next([...this.persons$.value, result])
                    : this.persons$.next(result);
            },
            (reason) => {}
        );
    }

    getControl(controlName: string): FormControl {
        return this.authorizedPersonFormGroup.get(controlName) as FormControl;
    }

    private _createFormGroup(): void {
        this.authorizedPersonFormGroup = this.docSrv.getInfoAuthPersonOrg({});
    }
}
