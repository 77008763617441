import {Component, Input} from "@angular/core";
import {FormGroup} from '@angular/forms';

@Component({
  selector: 'app-common-max-length-error',
  template:`<div *ngIf="formGroup.get(formControlName).errors?.maxlength" class="alert-danger rounded mt-1 p-1 app-valid ng-star-inserted" >
                <div class="customValidator-block">
                  <span class="customValidator-text">
                    Количество символов в строке должно быть {{ formGroup.get(formControlName).errors?.maxlength.requiredLength }},
                    текущее количество символов - {{ formGroup.get(formControlName).errors?.maxlength.actualLength }}!
                  </span>
                </div>
            </div>`
})

export class CommonMaxLengthErrorComponent {
  @Input() formGroup: FormGroup;
  @Input() formControlName?: string = 'creatorFio';
}
