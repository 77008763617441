import {Component} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {ITableItem} from "@amlCore/components";
import {DocumentComponentBaseSub} from "@amlDocument/base";
import {DocumentBaseService} from "@amlDocument/services";

@Component({
  selector: 'app-arm-doc-view-norm-act-obstruction-fact',
  templateUrl: './view-normative-act-obstruction-fact.component.html',
  styleUrls: ['../../../base/documentForm.component.scss'],
})
export class ViewNormativeActObstructionFactComponent extends DocumentComponentBaseSub implements ITableItem {
  formGroupApp: FormGroup;
  documentData: any;
  isParts: boolean;
  titlePanel = 'Сведения о статьях нормативных актов';
  childTitlePanel = 'Сведения о части статьи';
  currentGetForm = (param) => this.docSrv.getStatia(param);
  childGetForm = (param) => this.docSrv.getChast(param);

  isValidItem(): boolean {
    return this.formGroupApp.valid;
  }

    constructor(private readonly docSrv: DocumentBaseService) {
        super();
  }
}
