import {AbstractControl, FormArray, FormControl, FormGroup} from "@angular/forms";
import {Input, OnDestroy} from "@angular/core";
import {ITableEditorItem} from "@amlCore/components";
import {Subject} from 'rxjs';
import {IDictionarys} from "../model";
import {DocumentTypeEnum} from "../enums";

/**
 * Базовый класс для всех под-форм
 */
export abstract class DocumentComponentBaseSub implements OnDestroy {
    /** Под-форма */
    @Input() abstract formGroupApp: FormGroup | FormArray;
    /**
     * Данные по документу
     */
    @Input() abstract documentData: any;

    /**
     * Признак - форма просмотра/редактирования вложения
     */
     @Input() attrForm?: any;


     /**
      * Признак - форма в режиме просмотра
      */
    @Input() isReadOnly = false;
    /**
     * Признак - форма в режиме отображения ошибок
     */
    @Input() submitted = false;
    index: number | null;
    isTableItem = true;
    documentType: DocumentTypeEnum;
    titlePanel = '';
    childTitlePanel = '';
    showTitle = true;
    isParts = false;
  /**
   * @deprecated справочники todo delete
   */
    dictionarys: IDictionarys = {};
    // отписка от потока в момент уничтожения компонента
    componentDestroyed$ = new Subject<void>();

    currentGetForm: (model) => FormGroup | FormArray;
    childGetForm: (model) => FormGroup | FormArray;

    getTitle(): string | null {
        if (this.titlePanel) {
            return (this.index === null)
                ? this.titlePanel
                : this.titlePanel + ': Строка № ';
        }
        return null;
    }
    /**
     * Для tableEditorComp
     */
    getSaveBtnText() {
        return this.index == null ? 'Добавить' : 'Сохранить';
    }
    /**
     * Метод инициализации формы по полученным данным
     * * Преобразование данных в вид для формы
     * * Реализация подписок
     * * Инициалищация таблиц
     * * Установка данных в формы
     */
    initForm() { }
    /**
     * Получить поле
     * можно использовать вместо formGroup(), formArray()
     * @param fieldName - название поля
     */
    field<T extends AbstractControl>(fieldName: string): T {
        return this.formGroupApp.get(fieldName) as T;
    }

    formGroup(groupName: Array<string> | string): FormGroup {
        return this.formGroupApp.get(groupName) as FormGroup;
    }

    formArray(arrayName: string): FormArray {
        return this.formGroupApp.get(arrayName) as FormArray;
    }

    /**
     * Проверка установлен ли валидатор required
     * @param fieldName - название поля
     */

     isFieldReq(fieldName: string | AbstractControl): boolean {
      const control = typeof fieldName === 'string'
        ? this.field(fieldName)
        : fieldName;
      const getRequired = (inputControl) => Boolean(inputControl?.validator && inputControl.validator({} as AbstractControl)?.required);

      return control instanceof FormArray
        ? control.controls.some(abstractControl => getRequired(abstractControl)) || getRequired(control)
        : getRequired(control);
    }

    /**
     * Инициализируем компонент как элемент формы просмотра таблицы
     * @param param.form - форма для связи
     * @param param.params - дополнительные параметры
     */
    initTableItem(param: ITableEditorItem): FormGroup | FormArray {
        param.params && param.params.typePage
            ? this.documentType = param.params.typePage
            : console.log('Необходимо указать typePage у компонента');
        this.index = param.editIndex ?? null;
        this.showTitle = false;
        this.isReadOnly = param.isReadOnly;
        this.isParts = param?.params?.isParts;
        this.formGroupApp = param.form;

        if (this.isParts)
            this.titlePanel = this.childTitlePanel;

        if (param.model) {
            this.documentData = param.model;
            const formData = this.isTableItem ? param.model : {};
            if (!param.form)
                this.formGroupApp = !this.isParts
                    ? this.currentGetForm(formData)
                    : this.childGetForm(formData);
        }
        if (this.isReadOnly)
            this.formGroupApp.disable();
        return this.formGroupApp;
    }

    setSubmitted(value: boolean): void {
      if (value)
        this.logInvalidFields(this.formGroupApp);
      this.submitted = value;
    }

    ngOnDestroy() {
      this.componentDestroyed$.next();
      this.componentDestroyed$.complete();
    }

    /**
     * Возвращает корректную валидацию открытой записи в таблице без учёта форм групп
     * @param excludedControlNames - имена невалидируемых дочерних групп ['name']
     */
    protected isFormGroupValidExcludingControls(excludedControlNames: string[]): boolean {
      return Object.keys(this.formGroupApp.controls).every(controlName =>
        !excludedControlNames.includes(controlName)
          ? !this.formGroupApp.get(controlName).invalid
          : true
      );
    }

  /**
   * Возвращает корректную валидацию открытой записи в таблице без учёта форм групп
   * @param excludedControlNames - группы не влияющие на валидацию ['name.name'] // todo сделать для любой вложенности
   */
    protected isFormGroupValidExcludingControlsPath(excludedControlNames: string[]): boolean {
      const nameLastArr = excludedControlNames.map(name => name.split('.').pop());
      const nameExceptLastArr = excludedControlNames.reduce((acc, name) => {
        const nameArr = name.split('.');
        nameArr.pop();
        return [...acc, ...nameArr];
      }, []);

      return Object.keys(this.formGroupApp.controls).every(
        controlName =>
          nameExceptLastArr.includes(controlName)
            ? Object.keys((this.formGroupApp.get(controlName) as FormGroup).controls).every(
              controlChildName =>
                !nameLastArr.includes(controlChildName)
                  ? !this.formGroupApp.get(controlName + '.' + controlChildName).invalid
                  : true
            )
            : !this.formGroupApp.get(controlName).invalid
      );
    }

    // todo вывод невалидных полей (в дальнейшем сделать чтобы переходить по найденному пути)
    logInvalidFields(control: AbstractControl, controlPath = '') {
      if (control instanceof FormControl && control.invalid) {
        console.log(`Локальное сохранение, поле невалидно: ${controlPath}`, control.errors);
      } else if (control instanceof FormGroup) {
        for (const key in control.controls) {
          if (control.controls.hasOwnProperty(key)) {
            const nestedControl = control.get(key);
            this.logInvalidFields(nestedControl, controlPath ? `${controlPath}.${key}` : key);
          }
        }
      } else if (control instanceof FormArray) {
        for (let i = 0; i < control.length; i++) {
          const arrayControl = control.at(i);
          this.logInvalidFields(arrayControl, `${controlPath}[${i}]`);
        }
      }
    }
}
