import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormGroup, Validators} from "@angular/forms";
import {DocumentComponentBaseSub} from "@amlDocument/base";
import {DocumentTypeEnum} from '../../../enums';
import {filter, map, switchMap, takeUntil, tap} from "rxjs/operators";
import {DictionariesService} from "../../../../../dictionaries";
import {DictionaryService} from 'src/app/core';

@Component({
    selector: 'app-arm-doc-view-passport-ul',
    templateUrl: './passport.component.html',
    styleUrls: ["../../../base/documentForm.component.scss"],
})
export class PassportComponent extends DocumentComponentBaseSub implements OnInit, OnDestroy {
  @Input() readonly formGroupApp: FormGroup;
  @Input() readonly documentData: any;
  @Input() readonly title: string;
  @Input() readonly documentType: DocumentTypeEnum;
  @Input() readonly isAttachments = false;
  @Input() readonly isElMsg = false;
  @Input() readonly hasDocUdlichn = true;
  @Input() readonly pathRoot = 'svedDokUdLichn';
  @Input() readonly withAdditionalInfoBlock: boolean = false;

  validators = [Validators.required, Validators.maxLength(500)];
  secondValidators = [Validators.maxLength(500)];
  getDictionary = this.dictSrv.getQrefDokt4936List().pipe(map(page => page.content));
  firstEmit: boolean = true;

  constructor(private dictSrv: DictionariesService,
              private dictionarySrv: DictionaryService) {
    super();
  }

  nationality = this.dictionarySrv.getStaticData('nationality');
  codeVidDocAutoFill = [28, 35, 40] // AML-2020 для таких типов, иное наименование заполняется из vidDokKod

  ngOnInit() {
    const inoeNaimenovanieDokData = this.field(this.pathRoot + '.inoeNaimenovanieDok')?.value;

    this.getDictionary.pipe(
      switchMap(dictionaries => this.field(this.pathRoot + '.vidDokKod')?.valueChanges.pipe(
        map(dictionariesCode => dictionaries.find(dictionary => dictionary?.code === dictionariesCode)),
        filter(currentDictionary => Boolean(currentDictionary?.name)),
        tap(currentDictionary => {
          if (this.isAttachments) {
            this.field(this.pathRoot + '.vidDokNaimenovanie').patchValue(currentDictionary?.name.slice(0, 251))
          } else {

            if (inoeNaimenovanieDokData && this.firstEmit) {
              this.firstEmit = false;
              return;
            }

            if(!inoeNaimenovanieDokData || !this.firstEmit) {
              const name = this.codeVidDocAutoFill.includes(Number(currentDictionary?.code)) ? currentDictionary?.name : null;
              this.field(this.pathRoot + '.inoeNaimenovanieDok')?.patchValue(name);
            }
          }
        }),
        takeUntil(this.componentDestroyed$)
      )),
    ).subscribe();

    this.field(this.pathRoot + '.vidDokKod')?.valueChanges.pipe(takeUntil(this.componentDestroyed$)).subscribe(value => {
      const inoeNaimenovanieDok = this.field(this.pathRoot + '.inoeNaimenovanieDok');
      if ([28, 35, 99].includes(+value)) {
        inoeNaimenovanieDok?.setValidators([Validators.required, Validators.maxLength(500)])
      } else {
        inoeNaimenovanieDok?.clearValidators();
        inoeNaimenovanieDok?.setErrors(null);
        inoeNaimenovanieDok?.setValidators([Validators.maxLength(500)]);
      }
      inoeNaimenovanieDok?.updateValueAndValidity();
    });

  }

  get showVidDokKod(): boolean {
      return Boolean(this.priznKlient !== '0' || Boolean(this.field(this.pathRoot + '.vidDokKod').value));
    }
  
    get priznKlient() {
       switch (this.documentType) {
        case DocumentTypeEnum.OPERATIONS:
          return this.formGroupApp.parent?.parent?.get('priznKlient')?.value;

        case DocumentTypeEnum.REFUSE_CONTRACT:
          return this.formGroupApp.parent?.get('priznakKlienta')?.value;

        case DocumentTypeEnum.SUSPICIOUS_ACTIVITY:
          return this.formGroupApp.parent?.parent?.get('priznakKlienta')?.value;

        case DocumentTypeEnum.OES_OTKAZ_GOZ:
          return this.formGroupApp.get('tipVladelets')?.value ?? this.formGroupApp.get('tipPoluchatel')?.value;

        default:
          return this.formGroupApp.parent?.get('priznKlient')?.value;
       }
    }
}
