import {Component} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {ITableItem} from "@amlCore/components";
import {DocumentBaseService, DocumentComponentBaseSub} from "../../../../../../../documentForm";


@Component({
  selector: 'app-armfm-v-sved-result-verify-v1',
  templateUrl: './view-sved-result-verify.html',
  styleUrls: ['../../../../../../../documentForm/base/documentForm.component.scss']
})
export class ViewSvedResultVerify extends DocumentComponentBaseSub implements ITableItem{
  formGroupApp: FormGroup;
  documentData: any;
  isReadOnly: boolean;
  submitted: boolean;
  item: any = {};
  titlePanel = `Информация о сведениях проверки`;

  constructor(public docSrv: DocumentBaseService) {
    super();
  }

  currentGetForm = (param) => this.docSrv.getProverka(param);

  isValidItem(): boolean {
    return this.formGroupApp.valid;
  }

  genIdentify(data) {
    this.field('nomerZapisi').setValue(data);
    this.field('nomerZapisi').markAsDirty();
  }
}
