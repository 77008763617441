import {Component, Input, OnInit} from '@angular/core';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ViewSvedResultVerify} from "../views";
import {FormArray} from '@angular/forms';
import {ITable} from "@amlCore/components";
import {DocumentBaseService, DocumentSubForm, DocumentTypeEnum} from "../../../../../../../documentForm";


@Component({
  selector: 'app-armfm-table-sved-result-verify-v1',
  templateUrl: './table-sved-result-verify.html',
  styleUrls: ['../../../../../../../documentForm/base/documentForm.component.scss']
})
export class TableSvedResultVerify extends DocumentSubForm implements OnInit, ITable {
  @Input() readonly formGroupApp: FormArray;
  @Input() readonly documentData: any;
  @Input() readonly isReadOnly: boolean;
  @Input() readonly submitted: boolean;
  documentType = DocumentTypeEnum.VERIFICATION_RESULT;
  viewForm = ViewSvedResultVerify
  total = 0;
  pageSize = 10;
  page = 0;

  constructor(protected readonly modalService: NgbModal,
    private readonly docSrv: DocumentBaseService) {
    super(modalService)
  }

  currentGetFormGroup = (data) => this.docSrv.getProverka.call(this.docSrv, data);

  ngOnInit(): void {
    super.ngOnInit();
  }

  onPageChange(e) {
  }
}
