import {Component, OnDestroy} from "@angular/core";
import {FormGroup} from "@angular/forms";
import {ITableItem} from "src/app/core";
import {DocumentBaseService, DocumentComponentBaseSub} from "../../../../../../../documentForm";

@Component({
    selector: "app-armfm-v-sa-sved-oper-v1",
    templateUrl: "./view-sa-sved-oper.html",
  styleUrls: ['../../../../../../../documentForm/base/documentForm.component.scss']
})
export class ViewSaSvedOper extends DocumentComponentBaseSub implements ITableItem, OnDestroy {
    formGroupApp: FormGroup;
    documentData: any;
    titlePanel = `Сведения о подозрительной деятельности`;

    currentGetForm = (param) => this.docSrv.getPodozrDeiatSa(param);

    isValidItem(): boolean {
        return this.isFormGroupValidExcludingControls(['klient']);
    }

    constructor(private readonly docSrv: DocumentBaseService) {
        super();
    }

    genIdentify(data) {
        this.field('nomerZapisi').setValue(data);
        this.field('nomerZapisi').markAsDirty();
    }
}
