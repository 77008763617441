import {AbstractControl, FormArray, FormGroup} from "@angular/forms";
import {IconsService} from "@amlCore/services";

/**
 * Базовый класс для всех документов
 * * save() - сохранение документа
 */
export abstract class DocumentComponentBase {
  /**
   * Форма документа
   */
  abstract form: FormGroup;
  /**
   * Признак - форма в режиме просмотра
   */
  isReadOnly = false;
  /**
   * Признак - форма в режиме отображения ошибок
   */
  submitted = false;

  icons = this.iconsService.getIcons();

  protected constructor(protected iconsService: IconsService) {
  }

  /**
   * Метод инициализации формы по полученным данным
   * * Преобразование данных в вид для формы
   * * Реализация подписок
   * * Инициалищация таблиц
   * * Установка данных в формы
   */
  abstract initForm(): void;

  /**
   * Получить AbstractControl или FormGroup, FormArray
   * @param field - путь до поля
   */
  f(field: string): AbstractControl {
    return this.form.get(field);
  }

  fGroup(field: string): FormGroup {
    return this.form.get(field) as FormGroup;
  }

  fArray(field: string): FormArray {
    return this.form.get(field) as FormArray;
  }
  /**
   * Обновить состояние disable всей формы
   * вызывать после добавления под-форм
   */
  disableForm() {
    if (this.isReadOnly) {
      this.form.disable();
    }
  }
}
