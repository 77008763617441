import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormGroup, Validators} from "@angular/forms";
import {DocumentComponentBaseSub} from "@amlDocument/base";
import {DictionariesService} from "../../../../../dictionaries";
import {filter, map, switchMap, takeUntil, tap} from "rxjs/operators";

@Component({
    selector: 'app-arm-doc-view-passport-nr',
    templateUrl: './passport-nr.component.html',
    styleUrls: ["../../../base/documentForm.component.scss"],
})
export class PassportNrComponent extends DocumentComponentBaseSub implements OnInit, OnDestroy {
    @Input() readonly formGroupApp: FormGroup;
    @Input() readonly documentData: any;
    @Input() readonly isAttachments = false;
    @Input() readonly title: string;
    @Input() readonly pathRoot = 'svedDokPreb';
    getDictionary = this.dictSrv.getQrefDokt4936List().pipe(map(page => page.content));
    codeVidDocAutoFill = [28, 35, 40]; // AML-2020 для таких типов, иное наименование заполняется из vidDokKod
    firstEmit: boolean = true;
  
    constructor(private dictSrv: DictionariesService) {
      super();
    }

  ngOnInit() {
    const inoeNaimenovanieDokData = this.field(this.pathRoot + '.inoeNaimenovanieDok')?.value;

      this.getDictionary.pipe(
        switchMap(dictionaries => this.field(this.pathRoot + '.vidDokKod')?.valueChanges.pipe(
          map(dictionariesCode => dictionaries.find(dictionary => dictionary?.code === dictionariesCode)),
          filter(currentDictionary => Boolean(currentDictionary?.name)),
          tap(currentDictionary => {
            if (this.isAttachments) {
              this.field(this.pathRoot + '.vidDokNaimenovanie').patchValue(currentDictionary?.name)
            } else {

              if (inoeNaimenovanieDokData && this.firstEmit) {
                this.firstEmit = false;
                return;
              }

              if(!inoeNaimenovanieDokData || !this.firstEmit) {
                const name = this.codeVidDocAutoFill.includes(Number(currentDictionary?.code)) ? currentDictionary?.name : null
                this.field(this.pathRoot + '.inoeNaimenovanieDok')?.patchValue(name)
              }
            }
          }),
          takeUntil(this.componentDestroyed$)
        )),
      ).subscribe();
      
      this.field(this.pathRoot + '.vidDokKod')?.valueChanges.pipe(takeUntil(this.componentDestroyed$)).subscribe(value => {
        
        const inoeNaimenovanieDok = this.field(this.pathRoot + '.inoeNaimenovanieDok');
        if ([28, 35, 99].includes(+value)) {
          inoeNaimenovanieDok?.setValidators([Validators.required, Validators.maxLength(500)])
        } else {
          inoeNaimenovanieDok?.clearValidators();
          inoeNaimenovanieDok?.setErrors(null);
          inoeNaimenovanieDok?.setValidators([Validators.maxLength(500)]);
        }
        inoeNaimenovanieDok?.updateValueAndValidity();
      });
  }
  
  
}
 