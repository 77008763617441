import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';

import {UserService} from '@amlCore/services';
import {AlertService} from "@amlCore/components";
import {Utils} from "@amlCore/utils";
import { ProfileService } from '../client';
import { animate, style, transition, trigger } from '@angular/animations';
import moment from "moment";

@Component({
  selector: 'app-auth-page',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({opacity:0}),
        animate(300, style({opacity:1}))
      ])
    ])
  ]
})
export class AuthComponent implements OnInit {
  title = 'Авторизация';
  isSubmitting = false;
  authForm: FormGroup;
  public userRoleDesc: string;
  activeTab: 'caml' | 'ldap';
  isLDAPEnabled: boolean;

  constructor(private readonly route: ActivatedRoute,
              private readonly router: Router,
              private readonly userService: UserService,
              private readonly fb: FormBuilder,
              private readonly alertSrv: AlertService,
              private readonly profileService: ProfileService) {
    /**
     * Форма регистрации
     */
    this.authForm = this.fb.group({
      login: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    this.userService.checkLDAPEnabled().subscribe(data => (data.enabled === true) ? (this.activeTab = 'ldap', this.isLDAPEnabled = true) : (this.activeTab = 'caml', this.isLDAPEnabled = false));
  }

  submitForm(): void {
    this.isSubmitting = true;

    const credentials = this.authForm.value;
    this.userService
      .attemptAuth(credentials, this.activeTab)
      .subscribe(
        (data: any) => {
          const authData = {...data.body, sessionId: data?.headers?.get('Session-Id'), sessionTimeout: data?.headers?.get('Session-Timeout') * 60}
          if (authData.needChangePassword) {
            this.router.navigateByUrl('/changePassword');
            return;
          }
          this.userService.isExpiredPassword(data.id).subscribe((response: { error: string }) => {
            if (response && response.error) {
              this.alertSrv.error(response.error, undefined, undefined, 10 * 1000);
            }
          });
          authData.lastSystemLogon && this.alertSrv.primary(`Последний вход был в ${moment(authData.lastSystemLogon)
              .format('HH:MM:SS, DD-MM-YYYY')}`, undefined, undefined, 60000, true)
          this.getUserRoleInfo();
          this.router.navigateByUrl(this.userService.getStartPageUser(authData));
        },
        err => {
          this.isSubmitting = false;
        }
      );
  }
  getUserRoleInfo() {
    this.profileService.getUserInfo().subscribe((data) => {
      this.profileService.userRoleDesc$.emit(data.userRole.name);
      sessionStorage.setItem('userRole', data.userRole.name);
    });
  }

  getCustomPatterns(): object {
    return Utils.customPatterns;
  }

  changeTab(type: 'caml' | 'ldap'): void {
    this.activeTab = type;
    this.authForm.reset();
  }
}
