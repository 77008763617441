import {Component, OnDestroy, OnInit} from '@angular/core';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ITable} from 'src/app/core';
import {ViewSaSvedOper} from "../view";
import {DocumentBaseService, DocumentSubForm, DocumentTypeEnum} from "../../../../../../../documentForm";

@Component({
    selector: "app-armfm-table-sa-sved-oper-v1",
    templateUrl: "./table-sa-sved-oper.html",
  styleUrls: ['../../../../../../../documentForm/base/documentForm.component.scss']
})
export class TableSaSvedOper extends DocumentSubForm implements OnInit, OnDestroy, ITable {
    viewForm = ViewSaSvedOper;
    pageSize = 10;
    page = 0;
    dictionarys;
    documentType = DocumentTypeEnum.SUSPICIOUS_ACTIVITY;

    loaded = false;
    constructor(protected readonly modalService: NgbModal,
        private readonly docSrv: DocumentBaseService
    ) {
        super(modalService);
    }

    currentGetFormGroup = (data) => this.docSrv.getPodozrDeiatSa.call(this.docSrv, data);

    ngOnInit() {
        super.ngOnInit();
    }

    onPageChange(e) { }
}
