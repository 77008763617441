import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {IconsService} from '@amlCore/services';
import {FmService} from '../../service';
import {CheckDocsResponseModel} from './CheckDocsResponseModel';
import {Utils} from "@amlCore/utils";
import {FileSaverService} from "ngx-filesaver";
import {DocumentTypeEnum, getCheckConfig} from "src/app/arm/documentForm/enums/documentTypeEnum";


@Component({
  selector: 'app-check-documents',
  templateUrl: './checkDocuments.component.html',
  styleUrls: ['./checkDocuments.component.scss'],
})
export class CheckDocumentsComponent implements OnInit {

  icons = this._iconsService.getIcons();
  data: any;
  checkResults: any;
  disabled = false;
  success: boolean;
  loader = false;
  downloadFiles: boolean;
  error = '';
  group = '';
  constructor(private _activeModal: NgbActiveModal,
              private _iconsService: IconsService,
              private fmSrv: FmService,
              private fSaver: FileSaverService) { }

  ngOnInit(): void {
  }

  onClose(): void {
    this._activeModal.close();
  }

  open(item: any) {
    this.data = item.item;
  }

 /* Отправка списка контейнеров, и скачивание документов по ID в зависимости от параметра download (true/false)*/
 checkDocuments(): void {
  this.disabled = true;
  this.loader = true;
  const listIds = this.data.map((item) => item.id);
  // Определение в какой эндпоинт будет обращение в checkDocuments по первому элементу
  this.group = getCheckConfig(this.data[0].type)
  this.fmSrv.checkDocuments(listIds, this.group).subscribe((data) => {

    this.checkResults = data;
    let result: CheckDocsResponseModel[] = []
    if (this.checkResults && Object.keys(this.checkResults).length > 0) {
      result = Object.keys(this.checkResults).map(key => {
        let msg = this.checkResults[key].message;
        let success = false;
        let check = this.checkResults[key].check;
        let onlyWarn = false;
        let fatals = [];
        let errors = [];
        let warnings = [];
        if(this.checkResults[key].check){
	        fatals = check.fatals||[];
	        errors = check.errors||[];
	        warnings = check.warnings||[];
	        onlyWarn = (warnings.length > 0 || errors.length > 0)
	        && fatals.length == 0;
          if (!fatals.length || !errors.length || !warnings.length) {
            success = true;
          }
        }
        if (this.checkResults[key].message === 'success') {
          success = true;
          msg = onlyWarn ? 'Файл проверен с замечаниями.' : 'Файл успешно проверен.'
        }
        return {
          fileName: key,
          msg,
          success,
          fatals,
          errors,
          warnings,
          onlyWarn
        };
      })
    }

    this.data.forEach((data) => {
      result.forEach((res) => {
        if(data.id === res.fileName) {
          data.checkRes = res
        }
      })
    });

    this.disabled = false;
    this.success = true;
    this.loader = false;
  },
  (err) => {
    this.disabled = false;
    this.success = false;
    this.error = err;
    this.loader = false;
  },
  () => { }
  )

}
  exportCheckDocuments(): void {
    this.fmSrv.exportCheckDocuments(this.checkResults, this.group).subscribe((value) => {
        if (value.type === 4) {
          this.fSaver.save(value.body, Utils.getFileNameFromContentDisposition(value));
        }
      }
    );
  }

}
