import {Component, OnInit, ChangeDetectorRef, Input} from "@angular/core";
import {BaseFilterController} from "../BaseFilterController";
import {FormBuilder} from "@angular/forms";
import {FmDraftFilter} from "@amlCore/models";
import {DictionaryService, StringDateAdapterService, TableService} from "@amlCore/services";
import {ActivatedRoute} from "@angular/router";
import {DocTypeSearch, getDocStatuses, getDocTypes} from "../../constants";

@Component({
  selector: "app-fm-send-list-filter",
  templateUrl: "./fmSendListFilter.component.html",
})

export class FmSendListFilterComponent extends BaseFilterController implements OnInit {
  public filterObjectModel = new FmDraftFilter();
  placeholder = 'Поиск по идентификатору документа';
  docTypes: DocTypeSearch[] = []
  docStatuses: DocTypeSearch[] = []
  
  @Input() docType: 'fm' | 'strateg5392u' = 'fm'
  @Input() statuses: 'fmSend' | 'strategSend' = 'fmSend'
  constructor(private readonly fb: FormBuilder,
              private readonly _changeDetectorRef: ChangeDetectorRef,
              private dictSrv: DictionaryService,
              protected tableSrv: TableService,
              public dateSrv: StringDateAdapterService,
              protected readonly activatedRoute: ActivatedRoute) {
    super(tableSrv, activatedRoute);
  }

  /**
   * Fix error type ExpressionChangedAfterItHasBeenCheckedError
   */
  ngAfterViewChecked(): void {
    this._changeDetectorRef.detectChanges();
  }

  ngOnInit() {
    this.init();
    this.docTypes = getDocTypes(this.docType)
    this.docStatuses = getDocStatuses(this.statuses)
  }

  /**
   * Метод создания формы
   * @return FormGroup
   */
  public createFormModel() {
    const formConfig = new FmDraftFilter();

    return this.fb.group(formConfig);
  }

  getStartFilterModel(): FmDraftFilter {
    return {
      documentType: null,
      documentStatus: null
    } as FmDraftFilter;
  }

  /**
   * Описания полей
   */
  public getFilterDescription(): any {
    return {
      documentType: 'Тип документа',
      documentStatus: 'Статус',
      dateFrom: 'Дата документа с',
      dateTo: 'Дата по',
      signDateFrom: 'Дата подписи с',
      signDateTo: 'Дата по',
      containerName: 'Имя транспортного контейнера',
      creatorId: 'Владелец',
      sendDateFrom: 'Дата отправки с',
      sendDateTo: 'Дата отправки по'
    };
  }

  get users() {
    return this.docType === "fm" ? this.dictSrv?.remoteDict?.usersFM?.list || [] : this.dictSrv?.remoteDict?.strateg?.list || [];
  }
}
