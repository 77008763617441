import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ITableItem} from "@amlCore/components";
import {DocumentComponentBaseSub} from "@amlDocument/base";
import {DocumentBaseService} from "@amlDocument/services";
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-armfm-v-trast-info-v1',
  templateUrl: './view-trast-info.component.html',
  styleUrls: ["../../../base/documentForm.component.scss"],
})
export class ViewTrastInfoComponent extends DocumentComponentBaseSub implements OnInit, ITableItem, OnDestroy{
  formGroupApp: FormGroup;
  documentData: any;
  clientType = new FormControl(1);
  titlePanel = `Сведения в отношении трастов и иных ИНБОЮЛ`;
  private destroy$ = new Subject();
  constructor(private readonly docSrv: DocumentBaseService,
              private change: ChangeDetectorRef) {
      super();
  }

  ngOnInit() {
    this.clientType.patchValue(this.formGroupApp.value?.naimUchreditel?.iurLitso ? 1 : 2);
    if (this.isReadOnly)
      this.clientType.disable();

    this.handleIurLitsoValidation();
    this.clientType.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => this.handleIurLitsoValidation()); 
  }

  currentGetForm = (param) => this.docSrv.getUchreditel(param);

  isValidItem(): boolean {
    return this.formGroupApp.valid;
  }

  handleIurLitsoValidation() {
    const iurLitsoControl = this.field('naimUchreditel.iurLitso');
    this.clientType.value === 1 ?
      iurLitsoControl.setValidators([Validators.required, Validators.maxLength(1000)]) :
      (iurLitsoControl.clearValidators(), iurLitsoControl.setErrors(null));
  }

  changeType(type: number) {
    this.clientType.patchValue(type);
    this.change.detectChanges();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
