import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { StringDateAdapterService } from '@amlCore/services';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DocTypeSearch, getDocTypes } from "../../../../core/constants";
import moment from "moment";
import { DocumentBaseService } from '@amlDocument/services';
import { DownloadFilesService } from './../../../../core/services/download-files.service';
import { AlertPanelComponent, AlertPanelService } from '@amlCore/components';
import { isFieldReq, TableReactiveUrlController, TableService, Utils } from "src/app/core";
import { getGroupType } from 'src/app/arm/documentForm/enums/documentTypeEnum';
import { ActivatedRoute, Router } from "@angular/router";
import { CommonList } from "../../model";
import { Subject } from "rxjs";
import { takeUntil } from 'rxjs/operators';
import { FileSaverService } from "ngx-filesaver";

/**
 * Компонент закачки файлов, пока используется в Архив- Запрос-Ответ 600-П
 * планируется преиспользовать в дальнейшем для любых разделов
 */

@Component({
  selector: 'app-download-files-modal',
  templateUrl: 'downloadFiles.component.html',
})
export class DownLoadFilesComponent extends TableReactiveUrlController implements OnInit, OnDestroy  {

  constructor(protected router: Router,
              protected activatedRoute: ActivatedRoute,
              protected tableService: TableService,
              public modal: NgbActiveModal,
              public dateSrv: StringDateAdapterService,
              private fb: FormBuilder,
              private downloadFilesService: DownloadFilesService,
              private alertPanelSrv: AlertPanelService,
              protected fSaver: FileSaverService,
             ){super(router, activatedRoute, null, tableService, {sort: 'messageDate', dir: 'asc'},
             null, null, null, null, null, null, null, null, null); }


  @ViewChild('alertObjSave', {static: true}) alertPanelComponent: AlertPanelComponent;
  @Input() title = "Скачать файлы";
  @Input() closeText = "Закрыть";
  @Input() sendDownloadData = "Скачать";
  @Input() data;
  @Input() groupName;

  form: FormGroup;
  docTypes: DocTypeSearch[];
  groupType: string;
  isLoading = false;
  isFieldReq = isFieldReq;
  getGroupType = getGroupType;
  isShowVisited = true;
  dataList: Array<CommonList> = [];
  private destroy$ = new Subject();

  public onPageChange() {}
  public getFilterObject() {}


  ngOnInit(): void {
    this.form = this.fb.group({
      documentTypes: [null, Validators.required],
      dateFrom: [null, Validators.required],
      dateTo: [{value: moment().format('DD.MM.YYYY'), disabled: true}],
    });
    this.docTypes = this.getDocTypesFunc();
    this.groupType = this.groupName !== 'fm' ? this.getGroupType(this.groupName) : 'sko115fz';
    this.downloadList();
    super.ngOnInit();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getDocTypesFunc(): any[] {
    let  docTypes = getDocTypes(this.groupName);
    if (this.groupName === 'elMsgFm') {
      docTypes = docTypes.filter(item => item.id !== 0);
    }
    return docTypes;
  }

  downloadForm() {
    this.alertPanelComponent.close();
    if (this.form.invalid) {
      this.alertPanelComponent.open(this.alertPanelSrv.getErrorMsg('Необходимо заполнить обязательные поля'));
      return;
    }
    this.isLoading = true;
    this.downloadFilesService.downloadFiles(this.form.getRawValue(), this.groupType).pipe(takeUntil(this.destroy$)).subscribe((data) => {
      this.isLoading = false;
      this.downloadList();
      this.alertPanelComponent.open(this.alertPanelSrv.getSuccessMsg('Ваш запрос на скачивание файлов отправлен системе. Обработка будет происходить в фоновом режиме, можете продолжать работу. По окончанию Вы увидите уведомление'));
    },
    (err) => {
      console.log(err);
      this.alertPanelComponent.open(this.alertPanelSrv.getErrorMsg(err.error));
      this.isLoading = false;
    });
  }

  /**
   * Список запросов на скачивание архива
   */
  downloadList() {
    this.isLoading = true;
    this.downloadFilesService.downloadList(this.groupType).pipe(takeUntil(this.destroy$)).subscribe((data) => {
      this.dataList = data.archives;
      this.isLoading = false;
    },
    (err) => {
      console.log(err);
      this.alertPanelComponent.open(this.alertPanelSrv.getErrorMsg(err.error));
      this.isLoading = false;
    });
  }
/**
 * Удаление из списка запросов на скачивание архива
 * @param id
 */
  deleteItem(id) {
    this.downloadFilesService.removeDownloadArchive(this.groupType, id).pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.isLoading = false;
      this.alertPanelComponent.open(this.alertPanelSrv.getSuccessMsg('Успешно удалено!'));
      this.downloadList();
    });
  }

/**
 * Скачать из списка запросов на скачивание архива
 * @param id
 */
  downloadItem(id) {
    this.downloadFilesService.downloadItem(this.groupType, id).pipe(takeUntil(this.destroy$)).subscribe((data) => {
      this.fSaver.save(data.body, Utils.getFileNameFromContentDisposition(data));
      this.isLoading = false;
    });
  }

  /**
   * Перезапуск  на скачивание архива
   * @param id
   */
  rerunItem(id) {
    this.downloadFilesService.rerunItem(this.groupType, id).pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.alertPanelComponent.open(this.alertPanelSrv.getSuccessMsg('Запрос отправлен повторно!'));
      this.downloadList();
    },
    (err) => {
      console.log(err);
      this.alertPanelComponent.open(this.alertPanelSrv.getErrorMsg(err.error));
      this.isLoading = false;
    });
  }

}
