import {Component, OnInit} from '@angular/core';
import {FormArray, FormGroup} from "@angular/forms";
import {IconsService} from "@amlCore/services";
import {ActivatedRoute, Router} from "@angular/router";
import {CommonService, DocumentBase, DocumentBaseService, DocumentTypeEnum} from "../../../../../../documentForm";

@Component({
  selector: 'app-armfm-inform-result-verify5861U-v1',
  templateUrl: './inform-result-verify5861U-v1.html',
  styleUrls: ['../../../../../../documentForm/base/documentForm.component.scss']
})
export class InformResultVerify5861UV1 extends DocumentBase implements OnInit {
  readonly documentType = DocumentTypeEnum.VERIFICATION_RESULT;
  selectedSved = [];
  form: FormGroup;

  constructor(protected documentBaseService: DocumentBaseService,
              protected iconsService: IconsService,
              protected commonSrv: CommonService,
              protected activatedRoute: ActivatedRoute,
              protected router: Router) {
    super(documentBaseService, iconsService, commonSrv, activatedRoute, router);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  initForm(): void {
    const svedKO = this.documentBaseService.getSvedKoForm(this.d.informChast?.svedKO);
    this.fGroup('informChast').addControl('svedKO', svedKO);
  }

  get getinformChastSvedKO(): FormArray {
    return this.fArray('informChast.svedKO') as FormArray;
  }

}
