import {Component, OnInit, ChangeDetectorRef} from "@angular/core";
import {FormBuilder} from "@angular/forms";
import {FmDraftFilter} from "@amlCore/models";
import {DictionaryService, StringDateAdapterService, TableService} from "@amlCore/services";
import {ActivatedRoute} from "@angular/router";
import {BaseFilterController} from "../../BaseFilterController";

@Component({
  selector: "app-sv-income-list-filter",
  templateUrl: "./svetoforIncomeListFilter.component.html",
})

export class SvetoforIncomeListFilterComponent extends BaseFilterController implements OnInit {
  public filterObjectModel = new FmDraftFilter();
  placeholder = 'Поиск по идентификатору документа';

  constructor(private readonly fb: FormBuilder,
              private readonly _changeDetectorRef: ChangeDetectorRef,
              protected tableSrv: TableService,
              private dictSrv: DictionaryService,
              public dateSrv: StringDateAdapterService,
              protected readonly activatedRoute: ActivatedRoute) {
    super(tableSrv, activatedRoute);
  }

  /**
   * Fix error type ExpressionChangedAfterItHasBeenCheckedError
   */
  ngAfterViewChecked(): void {
    this._changeDetectorRef.detectChanges();
  }

  ngOnInit() {
    this.init();
  }

  /**
   * Метод создания формы
   * @return FormGroup
   */
  public createFormModel() {
    const formConfig = new FmDraftFilter();

    return this.fb.group(formConfig);
  }

  getStartFilterModel(): FmDraftFilter {
    return {
      documentType: null,
      documentStatus: null
    } as FmDraftFilter;
  }

  /**
   * Описания полей
   */
  public getFilterDescription(): any {
    return {
      documentType: 'Документ',
      documentStatus: 'Статус',
      dateFrom: 'Дата получения с',
      dateTo: 'Дата по',
      creatorId: 'Владелец',
      inn: 'ИНН',
    };
  }
  // todo вынос на сервер или энум если оставим тут
  docTypes = [
    {id: 0, name: 'REGISTRY_REPORT', title: '794-П. Реестр рисков'},
  ]
  docStatuses = [{id: 0, name: 'CREATED', title: 'Получен'},]

  get users() {
    return this.dictSrv?.remoteDict?.usersSvetofor?.list || [];
  }
}
