import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormGroup, Validators} from "@angular/forms";
import {ITableItem, TableEditorComponent} from "@amlCore/components";
import {DocumentComponentBaseSub} from "@amlDocument/base";
import {DocumentBaseService} from "@amlDocument/services";
import {clientDopSvedType} from "../../../types";
import {DictionaryService} from "@amlCore/services";
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import {ConfirmComponent} from "@amlCore/modals";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-arm-doc-view-dop-client-info',
  templateUrl: './view-dop-client-info.html',
  styleUrls: ["../../../base/documentForm.component.scss"],
})
export class ViewDopClientInfoComponent extends DocumentComponentBaseSub implements OnInit, ITableItem, OnDestroy {
  formGroupApp: FormGroup;
  documentData: any;
  clientType: number;
  titlePanel = 'Дополнительные сведения об участнике операции (сделки) (ФЛБенефициар)';
  childTitlePanel = 'Дополнительные сведения об участнике операции (сделки) (СведЕИО)';
  initialTypeEIO: string | null = null
  private destroy$ = new Subject();
  constructor(private readonly docSrv: DocumentBaseService,
              readonly dictStaticSrv: DictionaryService,
              private readonly modalService: NgbModal) {
      super();
  }

  ngOnInit(): void {
    this.field('identBenefitsiar')?.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(value => {
      if (value !== '11' && value !== '21') {
        this.field('priznBenefitsiar')?.reset();
        this.field('priznBenefitsiar')?.clearValidators(); 
        this.field('priznBenefitsiar')?.setErrors(null);
        }
      if (value === '11' || value === '21') {
          this.field('priznBenefitsiar')?.setValidators(Validators.required);
          this.field('priznBenefitsiar')?.value !== '0' && this.field('priznBenefitsiar')?.patchValue('1');
      }
    })
    this.initialTypeEIO = this.typeEIO
    this.field('tipEIO')?.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(value => {
      switch (value) {
        case '1':
          this.iuLEIO.enable()
          this.fleio.disable()
              break;
        case '2':
          this.fleio.enable()
          this.iuLEIO.disable()
              break;
        default:
          this.fleio.disable()
          this.iuLEIO.disable()
      }
    })

    this.field('priznEIO')?.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(value => {
      switch (value) {
        case '0':
          this.formGroupApp.get('iuLEIO')?.get('ogrniuL').clearValidators();
          this.formGroupApp.get('iuLEIO')?.get('ogrniuL').updateValueAndValidity();
        break;

        case '1':
          this.formGroupApp.get('iuLEIO')?.get('ogrniuL').setValidators([Validators.required, Validators.maxLength(500)]);
          this.formGroupApp.get('iuLEIO')?.get('ogrniuL').updateValueAndValidity();
        break;
      }
      
    })
  }

  currentGetForm = (data) => this.docSrv.getBenefitsiar(data);
  childGetForm = (data) => this.docSrv.getSvedEIO(data);

  get typeInfo(): clientDopSvedType {
    if (this.documentData?.flbenefitsiar || !this.isParts) {
      this.clientType = 4;
      return 'ФЛБенефициар';
    } else if (this.documentData?.iuLEIO || this.documentData?.fleio || this.isParts) {
      return 'СведЕИО';
    }
  }

  isValidItem(): boolean {
    if (this.isParts) {
      if (this.field('tipEIO').invalid) return false;
      return this.formGroupApp.value.tipEIO === '1'
        ? this.formGroup('iuLEIO').valid
        : (this.formGroup('fleio').updateValueAndValidity(),
          this.formGroup('fleio').valid);
    }
    
    this.formGroup('flbenefitsiar').updateValueAndValidity();
    return this.formGroup('flbenefitsiar').valid && this.field('identBenefitsiar')?.valid;
  }

  beforeSaveCheck(context: TableEditorComponent): boolean {
    if (!this.initialTypeEIO || this.initialTypeEIO === this.typeEIO) {
      return true;
    }

    const modal = this.modalService.open(ConfirmComponent, {
      windowClass: "top-modal-editor",
      backdropClass: "top-modal-editor",
    });
    modal.componentInstance.text = "Вы изменили «Тип единоличного исполнительного органа»! " +
        "Все данные о предыдущем исполнительном органе будут удалены!";
    modal.componentInstance.title = "Предупреждение";
    modal.componentInstance.okBtn = "Удалить";

    modal.result.then(
        (data) => this.submitChangeEIOType(data, context),
        (cancel) => this.submitChangeEIOType(undefined, cancel)
    );

    return false;
  }

  private submitChangeEIOType(event, context?: TableEditorComponent) {
    if (event) {
      switch (this.initialTypeEIO) {
        case '1':
          this.iuLEIO.reset()
          break;
        case '2':
          this.fleio.reset()
          break;
      }
      this.initialTypeEIO = this.typeEIO
      context.save();
    }
  }

  get typeEIO(): string | null {
    return this.field('tipEIO')?.value
  }
  
  get fleio(): FormGroup {
    return this.field('fleio') ?? this.field('fleio')
  }

  get iuLEIO(): FormGroup {
    return this.field('iuLEIO') ?? this.field('iuLEIO')
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
