import {Component} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {ITableItem} from '@amlCore/components';
import {DocumentBaseService, DocumentComponentBaseSub} from "../../../../../../../documentForm";

@Component({
  selector: 'app-view-freeze-info-credit-org',
  templateUrl: './view-freeze-info-credit-org.html',
  styleUrls: ['../../../../../../../documentForm/base/documentForm.component.scss']
})
export class ViewFreezeInfoCreditOrg extends DocumentComponentBaseSub implements ITableItem{

  formGroupApp: FormGroup;
  documentData: any;
  titlePanel = `Информация о кредитной организации (филиале кредитной организации), представляющей (представляющем) сведения в уполномоченный орган`;
  isReadOnly: boolean;
  submitted: boolean;

  constructor(readonly docSrv: DocumentBaseService) {
      super();
  }

  currentGetForm = (param) => this.docSrv.getSvedKoFreeze(param);

  isValidItem(): boolean {
    return this.isFormGroupValidExcludingControls(['mery']);
  }
}
