import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TableReactiveUrlController} from "@amlCore/tableReactive";
import {AccessJson, FmDraftFilter} from "@amlCore/models";
import {RoleService, TableService, VisitedService} from "@amlCore/services";
import {Utils} from "@amlCore/utils";
import {FmFilterService, FmService} from "../../service";
import {IDataResponse} from "@amlCore/interfaces";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {forkJoin, of, Subject, Subscription} from 'rxjs';
import {CommonService} from '../../../documentForm/services/common.service';
import {ProcessDocsResponseModel} from '../../../documentForm/model/ProcessDocsResponseModel';
import {CommonList} from '../../../documentForm/model/CommonList.model';
import {DocumentTypeEnum} from '../../../documentForm/enums/documentTypeEnum';
import {catchError, first, mergeMap, takeUntil} from 'rxjs/operators';
import {CopyNotificationComponent} from "@amlCore/components";

@Component({
  selector: 'app-armfm-fm-send-list',
  templateUrl: './fm-send-list.component.html'
})
export class FmSendListComponent extends TableReactiveUrlController implements OnInit {

  isShowVisited = true;
  private destroy$ = new Subject();

  constructor(protected fmSrv: FmService,
              private fmFilterSrv: FmFilterService,
              protected activatedRoute: ActivatedRoute,
              protected visitedService: VisitedService,
              protected modalDialog: NgbModal,
              protected tableService: TableService,
              protected router: Router,
              protected commonSrv: CommonService,
              protected roleService: RoleService) {
    super(router, activatedRoute, visitedService, tableService, {sort: 'sendDate', dir: 'desc'},
        null, null, null, null, null, null, null, commonSrv, roleService);
  }

  dataList: Array<CommonList> = [];
  isAll = true;
  id: string;
  access: AccessJson;
  sCloseRecieveModal: Subscription;
  loading: false | null = false;
  goBack = Utils.goBack;

  ngOnInit() {
    // this.access = this.userSrv.getAccess(RegistriesAccessEnum.QREF_MVK);
    this.activatedRoute.params.subscribe(params => {
      this.id = params.id;
      this.activatedRoute.queryParams.subscribe(
        data => {
          this.isAll = !this.id;
          const paramsData = this.syncParams(data);
          paramsData.id = this.id;
          this.loadList(paramsData);
        });
    });
    this.fmFilterSrv.loadUserAccessList();
    this.sCloseRecieveModal = this.fmSrv.closeRecieveModal.subscribe((data) => {
      if (data) {
        this.loadList()
      }
    })
    super.ngOnInit()
  }

  onDestroy() {
    if (this.sCloseRecieveModal) {
      this.sCloseRecieveModal.unsubscribe();
    }

    this.destroy$.next();
    this.destroy$.complete();
  }

  importLoad(data?) {
    this.onPageChange({$refresh: Math.random()});
  }

  /**
   * метод получения объекта плоского фильтра
   * @param data объект фильтра с полями
   */
  public getFilterObject(data: FmDraftFilter) {
    const filter = this.tableService.getFilterSubject(data) as FmDraftFilter;
    return this.getFilterPlanObject(filter);
  }

  public onPageChange(params) {
    const filter = this.getParams({...params, ...this.filter}) as FmDraftFilter;
    filter['isAll'] = this.isAll;
    this.navigate(filter);
  }

  public onSearch() {
    const params = this.getSearchPage(this.filter) as FmDraftFilter;
    this.onPageChange(params);
  }

  private loadList(params?: any) {
    this.fmSrv.getFMSendList(this.getParams(params)).subscribe((data: IDataResponse<CommonList>) => {
      this.dataList = data.content;
      this.total = data.totalElements;
      this.resetSelectedAll();
    });
  }

  getConfig(docType) {
    return this.fmSrv?.getConfigDoc(docType);
  }

  changeLoading(expression: false | null) {
    this.loading = expression;
  }
}
