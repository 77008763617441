import {Injectable} from "@angular/core";
import {GroupMenuTypes} from "../../models/types/Navigation";

@Injectable()
export class NavCabService {
  uniqIdent = 0;
  menus = {
    showRegistry: false,
    showFm: true,
    showSvetofor: true,
    showRejection: true,
    showRequestsga: true,
    showElMsgFm: true,
    show4937: true,
    showOes: true,
    showOESobOtkazeGOZ: true,
    showArmStrateg: true,
    showArmControl6670: true,
  }

  constructor() {

  }

  setUniqIdent(id): void {
    this.uniqIdent = id;
  }


  changeVisible(type: GroupMenuTypes) {
    this.menus[type] = !this.menus[type];
    this.saveVisibleMenuToLS();
  }

  isVisible(type: GroupMenuTypes) {
    return this.menus[type];
  }

  saveVisibleMenuToLS() {
    const result = [];
    Object.keys(this.menus).forEach(key => {
      if (this.menus[key] === false) {
        result.push(key)
      }
    });
    localStorage.setItem(`navCab_${this.uniqIdent}`, JSON.stringify(result));
  }

  // Заполнить объект видимости из локал стореджа. Лучше делать после выставления уникального идентификатора uniqIdent
  fillVisibleMenuFromLS() {
    const navCab: GroupMenuTypes[] = JSON.parse(localStorage.getItem(`navCab_${this.uniqIdent}`)) || [];
    Object.keys(this.menus).forEach((menu: GroupMenuTypes) => {
      this.menus[menu] = !navCab.includes(menu);
    })
  }
}

