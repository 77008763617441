import {Component, OnInit, OnDestroy} from '@angular/core';

import {
  SharedMenuService,
  TableService,
  UserService,
} from "@amlCore/services";
import {ProfileService} from '../../client/services';
import {
  getRegistriesAccessList,
  RegistriesAccessEnum,
} from "@amlCore/enums";

import {Router} from '@angular/router';
import {Auth, OrganizationModel} from "@amlCore/models";
import {faEye} from "@fortawesome/free-solid-svg-icons/faEye";
import {faSignInAlt} from "@fortawesome/free-solid-svg-icons/faSignInAlt";
import {faSignOutAlt} from "@fortawesome/free-solid-svg-icons/faSignOutAlt";
import {faIdBadge} from "@fortawesome/free-solid-svg-icons/faIdBadge";
import {faUser} from "@fortawesome/free-solid-svg-icons/faUser";
import {faBars} from "@fortawesome/free-solid-svg-icons/faBars";
import {faAddressCard} from "@fortawesome/free-solid-svg-icons/faAddressCard";
import {faMailBulk} from "@fortawesome/free-solid-svg-icons/faMailBulk";
import {faClipboardList} from "@fortawesome/free-solid-svg-icons/faClipboardList";
import {faClipboardCheck} from "@fortawesome/free-solid-svg-icons/faClipboardCheck";
import {faCreditCard} from "@fortawesome/free-solid-svg-icons/faCreditCard";
import {faUserCheck} from "@fortawesome/free-solid-svg-icons/faUserCheck";
import {faBook} from "@fortawesome/free-solid-svg-icons/faBook";
import {faCaretDown} from "@fortawesome/free-solid-svg-icons/faCaretDown";
import {faThumbtack} from "@fortawesome/free-solid-svg-icons/faThumbtack";
import {Subject, Unsubscribable} from 'rxjs';
import {NavCabService} from "@amlClient/components";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {ConfirmComponent} from "@amlCore/modals";
import { AuthComponent } from 'src/app/auth';
import { GroupMenuTypes } from 'src/app/client/models/types/Navigation';
import {FmService} from "src/app/arm/fm";

@Component({
  selector: 'app-layout-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  private PINNED_DROPDOWN_CLASS = 'thumbtack-pinned';
  private UNPINNED_DROPDOWN_CLASS = 'thumbtack-unpinned';
  private sharedMenuSubscribe: Unsubscribable;
  private _login = '';
  public isVisibleDropdownMenu = false;
  public caretClass = 'aml-caret-up';
  public hasNoticeToBeResolve: boolean;
  public hasCheckClientResolve: boolean;
  public hasCheckTransactionResolve: boolean;
  public pinnedDropdownClass = "";
  public isOpenDictionaryMenu = false;
  public menuTitle = "";
  public registriesAccess = RegistriesAccessEnum;
  public registriesAccessIds = getRegistriesAccessList();
  public popoverOptions = {
    place: "right",
    description: `Нажмите на кнопку, если необходимо
                  закрепить меню в левой части окна`,
    trigger: "mouseenter:mouseleave"
  };
  orgInfo: OrganizationModel = null;
  public showMenu = false;

  constructor(private userService: UserService,
              private router: Router,
              private modalService: NgbModal,
              private menuSrv: SharedMenuService,
              private tableSrv: TableService,
              public profileService: ProfileService,
              private _navCabService: NavCabService,
              private fmSrv: FmService) {
  }
  icons = {
    brand: faEye,
    sign: faSignInAlt,
    requisites: faIdBadge,
    user: faUser,
    exit: faSignOutAlt,
    btnBurger: faBars,
    addressCard: faAddressCard,
    book: faBook,
    alerts: faMailBulk,
    eye: faEye,
    caret: faCaretDown,
    thumbtack: faThumbtack,
    creditCard: faCreditCard
  };
  currentUser: Auth;
  public userRoleDesc: string = sessionStorage.getItem('userRole');
  ngOnInit(): void {
    this.userService.showMenu$.subscribe((data) => {
      this.showMenu = data;
    })
    this.profileService.userRoleDesc$.subscribe((data) => {
      this.userRoleDesc = data;
    })
    this.sharedMenuSubscribe = this.tableSrv.sharedMenu().getSubscribe().subscribe((title) => {
      if (typeof title === "string") {
        this.menuTitle = title;
      }
    });

    this.menuSrv.getSubscribe().subscribe((isPinned) => {
      if (typeof isPinned === "boolean") {
        this.isVisibleDropdownMenu = !isPinned;
      }
    });

    this.userService.isAuthenticated.subscribe(
      (isAuthenticated) => {
        this._login = this.userService.getAuthenticateUser.login;
        if (!isAuthenticated) {
          if (this._login) {
            localStorage.setItem(`isPinnedMenuNav-${this._login}`, "true");
          }
          this.router.navigateByUrl('/');
        }
        this.isVisibleDropdownMenu = !JSON.parse(localStorage.getItem(`isPinnedMenuNav-${this._login}`));
        /* проверяем, если юзер авторизован и есть доступ к разделу оповещений
         * то отправляем запрос на получения признака наличия
         * хотя бы одного оповещния требующего разрешения
         */
      });
    this.userService.currentUser.subscribe((userData) => {
      this.currentUser = userData;
    });
    this.userService.orgInfo.subscribe((data: OrganizationModel) => {
      this.orgInfo = data;
    });

    const isPinned = !!JSON.parse(localStorage.getItem(`isPinnedMenuNav-${this._login}`));
    this.menuSrv.emit(isPinned);

    this.setClassPinned();
  }

  public openDictionarySubMenu(): void {
    this.isOpenDictionaryMenu = !this.isOpenDictionaryMenu;
    this.changeCaretClass(this.isOpenDictionaryMenu);
    this.setClassPinned();
  }

  // метод меняющи css классы для прибитого и не прибитого отображения скрепки
  public setClassPinned(): void {
    const isPinned = Boolean(JSON.parse(localStorage.getItem(`isPinnedMenuNav-${this._login}`)));
    if (isPinned) {
      this.pinnedDropdownClass = this.PINNED_DROPDOWN_CLASS;
    } else {
      this.pinnedDropdownClass = this.UNPINNED_DROPDOWN_CLASS;
      this.setScroll();
    }
  }

  // метод переключающий флаг togglePinned для скрепки
  public changeStatePinned(): void {
    const isPinned = !Boolean(JSON.parse(localStorage.getItem(`isPinnedMenuNav-${this._login}`)));
    localStorage.setItem(`isPinnedMenuNav-${this._login}`, (isPinned) + '');
    this.menuSrv.emit(isPinned);
    this.setClassPinned();
  }


  public goHome(): void {
    this.router.navigateByUrl('/');
  }

  public exit(): void {
    const modal = this.modalService.open(ConfirmComponent);
    modal.componentInstance.text = "Вы точно хотите выйти из кабинета?";
    modal.componentInstance.isOutputText = false;
    modal.result.then(
        data => {
          if (this.fmSrv.getDocIds().length) {
            this.fmSrv.unBlockDocuments().subscribe();
          }
          this.userService.logout().subscribe(() => {
            this.userRoleDesc = null;
            this.userService.clearAuth();
            location.reload();
          })
        },
        cancel => {return; });
  }

  private changeCaretClass(isOpen: boolean): void {
    if (isOpen) {
      this.caretClass = 'aml-caret-down';
    } else {
      this.caretClass = 'aml-caret-up';
    }
  }

  ngOnDestroy(): void {
    this.sharedMenuSubscribe.unsubscribe();
  }

  changeVisible(type: GroupMenuTypes): void {
    this._navCabService.changeVisible(type);
  }

  isNavMenuVisible(type: GroupMenuTypes): boolean {
    return this._navCabService.isVisible(type)
  }
  /**
  * Установка скролла  для меню
  */
  setScroll() {
    if (document.getElementById('navscroll')) {
      const elem = document.getElementById('navscroll');
      setTimeout(() => {
        const elem = document.getElementById('navscroll');
        elem.scrollTop = +localStorage.getItem('nav-scroll');
      }, 0);
      elem.addEventListener('scroll', () => {
        localStorage.setItem('nav-scroll', String(elem.scrollTop));
      });
    }
  }
}
