import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FmService } from '../../../arm/fm/service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertModalComponent } from 'src/app/core/modals';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-gen-identifier-btn',
  templateUrl: './gen-identifier-btn.component.html',
  styleUrls: ['./gen-identifier-btn.component.css']
})
export class GenIdentifierBtnComponent implements OnInit, OnDestroy {
  @Input() marginTop = '29px';
  @Output() btnClick = new EventEmitter<void>();
  private destroy$ = new Subject();

  constructor(private fmService: FmService, private modalService: NgbModal) { }

  ngOnInit(): void {
  }

  genIdentify() {
    const document = this.fmService.getDocument();
    const docType = document.documentType;
    const tipInf = document.form.get('sluzhChast')?.get('tipInf');
    const id = document.documentId;   

    if (!tipInf.value) {
      const modalRef = this.modalService.open(AlertModalComponent, {
        centered: true
      });
      const text = `<span class='text-danger'>Необходимо заполнить обязательное поле "Тип информации"</span>`
      modalRef.componentInstance.text = text;
    } else {
      this.fmService.genIdentifier(docType, id, tipInf.value ).pipe(takeUntil(this.destroy$)).subscribe(data => {
        this.btnClick.emit(data);
      });
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
