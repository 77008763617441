import {Component, OnInit, ChangeDetectorRef} from "@angular/core";
import {FormBuilder} from "@angular/forms";
import {FmDraftFilter} from "@amlCore/models";
import {DictionaryService, StringDateAdapterService, TableService} from "@amlCore/services";
import {ActivatedRoute} from "@angular/router";
import {BaseFilterController} from "../../BaseFilterController";

@Component({
  selector: "app-arm-4937-draft-list-filter",
  templateUrl: "./arm4937DraftListFilter.component.html",
})

export class Arm4937DraftListFilterComponent extends BaseFilterController implements OnInit {
  public filterObjectModel = new FmDraftFilter();
  placeholder = 'Идентификатор документа, имя xml-документа';

  constructor(private readonly fb: FormBuilder,
              private readonly _changeDetectorRef: ChangeDetectorRef,
              protected tableSrv: TableService,
              private dictSrv: DictionaryService,
              public dateSrv: StringDateAdapterService,
              protected readonly activatedRoute: ActivatedRoute) {
    super(tableSrv, activatedRoute);
  }

  /**
   * Fix error type ExpressionChangedAfterItHasBeenCheckedError
   */
  ngAfterViewChecked(): void {
    this._changeDetectorRef.detectChanges();
  }

  ngOnInit() {
    this.init();
  }

  /**
   * Метод создания формы
   * @return FormGroup
   */
  public createFormModel() {
    const formConfig = new FmDraftFilter();

    return this.fb.group(formConfig);
  }

  getStartFilterModel(): FmDraftFilter {
    return {
      documentType: null,
      documentStatus: null,
    } as FmDraftFilter;
  }

  /**
   * Описания полей
   */
  public getFilterDescription(): any {
    return {
      documentType: 'Документ',
      dateFrom: 'Дата документа с',
      dateTo: 'Дата по',
      creatorId: 'Владелец',
      documentStatus: 'Статус',
    };
  }
  // todo вынос на сервер или энум если оставим тут
  docTypes = [
    {id: 11, name: 'nfo-operations', title: '4937-У. ФЭС об операциях, подлежащих обязательному контролю'},
    {id: 12, name: 'nfo-obstruction-facts', title: '4937-У. ФЭС о фактах воспрепятствования со стороны государства (территории)'},
    {id: 13, name: 'nfo-suspicious-activity', title: '4937-У. ФЭС  о подозрительной деятельности'},
    {id: 14, name: 'nfo-refuse-operation', title: '4937-У. ФЭС о случаях отказа от проведения операции'},
    {id: 15, name: 'verification-result', title: '4937-У. ФЭС о результатах проверки'},
    {id: 16, name: 'freezing-funds', title: '4937-У. ФЭС о принятых мерах  по замораживанию (блокированию)'},
  ]

  docStatuses = [{id: 0, name: 'CREATED', title: 'Создан'},]

  get users() {
    return this.dictSrv?.remoteDict?.usersSvetofor?.list || [];
  }
}
