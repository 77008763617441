import {Component, OnInit} from '@angular/core';
import {FormArray, FormGroup} from "@angular/forms";
import {IconsService, UserService} from "@amlCore/services";
import {ActivatedRoute, Router} from "@angular/router";
import {takeUntil} from "rxjs/operators";
import {CommonService, DocumentBaseService} from "@amlDocument/services";
import {FmService} from "../../../../service";
import {DocumentBase, DocumentTypeEnum} from "../../../../../documentForm";

@Component({
  selector: 'app-armfm-oper-details',
  templateUrl: './operation-details-5861U.components.html',
  styleUrls: ['../../../../../documentForm/base/documentForm.component.scss']
})
export class OperationDetails5861UComponents extends DocumentBase implements OnInit {
  readonly documentType = DocumentTypeEnum.OPERATIONS;
  clientType: string;
  form: FormGroup;
  documentTable;

  indexSved: number;
  indexOsnov: number;
  indexMember: number;

  selectEIODopClientForm: FormArray;
  selectBenefitsiarDopClientForm: FormArray;

  constructor(
    protected documentBaseService: DocumentBaseService,
    protected iconsService: IconsService,
    protected commonSrv: CommonService,
    protected fmSrv: FmService,
    protected activatedRoute: ActivatedRoute,
    protected router: Router
  ) {
    super(documentBaseService, iconsService, commonSrv, activatedRoute, router);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  initForm(): void {
    if(this.documentId){
      this.fmSrv.getDocumentTable(this.documentId, this.documentType)
        .pipe(takeUntil(this.destroy$))
        .subscribe(data => {
          this.initTableData(data);
          this.disableForm();
        });
    } else {
      this.initTableData([]);
      this.disableForm();
    }
  }

  initTableData(data){
    this.documentTable = data;
    const operationDetails = this.documentBaseService.getOperationDetailsForm(data);
    this.fGroup('informChast').addControl('svedKO', operationDetails);
  }

  get getFormSvedKO(): FormArray {
    return this.fArray('informChast.svedKO');
  }

  get getFormSvedOper(): FormArray {
    return this.getFormSvedKO?.get([this.indexSved])?.get('operatsiia') as FormArray;
  }

  get getFormOsnov(): FormArray {
    return this.getFormSvedOper?.get([this.indexOsnov])?.get('osnovanieOp') as FormArray;
  }

  get getFormMembers(): FormArray {
    return this.getFormSvedOper?.get([this.indexOsnov])?.get('uchastnikOp') as FormArray;
  }

  onSelectedSvedRow(index) {
    this.clearDopSved();
    this.indexSved = index;
  }

  onSelectedOsnovRow(index) {
    this.clearDopSved();
    this.indexOsnov = index;
  }

  onSelectedDopClientInfoRow(index?: number) {
    this.clearDopSved();
    const svedKO = this.form.get(`informChast.svedKO`).value[this.indexSved] ?? this.documentTable[this.indexSved];
    const currentMember = svedKO?.operatsiia[this.indexOsnov]?.uchastnikOp[index];
    if (!currentMember) return;
    this.indexMember = index;
    if (!this.clientType) {
      this.getFormMembers.valueChanges
        .pipe(takeUntil(this.destroy$))
        .subscribe(item => {
          const currentClientType = item[this.indexMember]?.tipUchastnika;
          if (currentClientType && currentClientType !== this.clientType)
            this.clientType = currentClientType;
        });
    }
    this.clientType = currentMember?.tipUchastnika;
    this.initDopClientFormAtChangeClientType(index);
  }

  private initDopClientFormAtChangeClientType(selectedIndexMembers: number): void {
    if (!this.clientType) return console.log('тип участника не задан!');
    // const getFullData = (data) => data?.length && data;
    const getFormDopInfo = (groupName): FormArray => this.getFormMembers?.at(selectedIndexMembers)?.get(groupName) as FormArray;
    let benefitsiarPathForm = '';
    if (this.clientType === '1') {
      this.selectEIODopClientForm = getFormDopInfo('uchastnikIuL.svedEIO');
      benefitsiarPathForm = 'uchastnikIuL.benefitsiarIuL';
    }
    if (this.clientType === '2' || this.clientType === '3' || this.clientType === '4')
      benefitsiarPathForm = 'uchastnikFLIP.benefitsiarFLIP';
    if (this.clientType === '5')
      benefitsiarPathForm = 'uchastnikINBOIuL.benefitsiarINBOIuL';

    this.selectBenefitsiarDopClientForm = getFormDopInfo(benefitsiarPathForm);
  }

  private clearDopSved() {
    this.selectEIODopClientForm = null;
    this.selectBenefitsiarDopClientForm = null;
  }
}
