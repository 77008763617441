import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TableReactiveUrlController} from "@amlCore/tableReactive";
import {AccessJson, FmDraftFilter} from "@amlCore/models";
import {RoleService, TableService, VisitedService} from "@amlCore/services";
import {Utils} from "@amlCore/utils";
import {IDataResponse} from "@amlCore/interfaces";
import {NgbModal, NgbModalOptions} from "@ng-bootstrap/ng-bootstrap";
import {DeleteComponent} from 'src/app/core/components/delete';
import {AlertService} from "@amlCore/components";
import {CommonService} from '../../../services/common.service';
import {getDocumentConfig, getGroupType} from '../../../enums/documentTypeEnum';
import {ProcessDocsResponseModel} from '../../../model/ProcessDocsResponseModel';
import {CommonFilterService} from '../../../services/common-filter.service';

@Component({
  selector: 'app-arm-deleted-list',
  templateUrl: './deleted-list.component.html'
})
export class DeletedListComponent extends TableReactiveUrlController implements OnInit {

  isShowVisited = true;
  type: string;
  groupName: string;
  private readonly _MODAL_CONFIG = {
    size: 'lg',
    backdrop: 'static',
    centered: true,
  } as NgbModalOptions;

  constructor(protected activatedRoute: ActivatedRoute,
              protected visitedService: VisitedService,
              protected modalDialog: NgbModal,
              protected tableService: TableService,
              private alertSrv: AlertService,
              protected router: Router,
              protected commonSrv: CommonService,
              protected commonFilterSrv: CommonFilterService,
              protected roleService: RoleService) {
    super(router, activatedRoute, visitedService, tableService, {sort: 'messageDate', dir: 'desc'}, null, null, null,
        null, null, null, null, commonSrv, roleService);
  }

  dataList: Array<any> = [];
  isAll = true;
  id: string;
  access: AccessJson;
  goBack = Utils.goBack;
  disabled = false;

  ngOnInit() {
    this.groupName = this.activatedRoute.snapshot?.pathFromRoot[2].routeConfig.path;
 
    this.type = this.activatedRoute.snapshot?.pathFromRoot[2].routeConfig.path === 'strateg5392u'
                ? 'otkazgoz'
                : this.activatedRoute.snapshot?.pathFromRoot[2].routeConfig.path;

    this.activatedRoute.params.subscribe(params => {
      this.id = params.id;
      this.activatedRoute.queryParams.subscribe(
        data => {
          this.isAll = !this.id;
          const paramsData = this.syncParams(data);
          paramsData.id = this.id;
          this.loadList(paramsData);
        });
    });
    this.commonFilterSrv.loadUserAccessList(this.type);
    super.ngOnInit()
  }

  importLoad(data?) {
    this.onPageChange({$refresh: Math.random()});
  }

  /**
   * метод получения объекта плоского фильтра
   * @param data объект фильтра с полями
   */
  public getFilterObject(data: FmDraftFilter) {
    const filter = this.tableService.getFilterSubject(data) as FmDraftFilter;
    return this.getFilterPlanObject(filter);
  }

  public onPageChange(params) {
    const filter = this.getParams({...params, ...this.filter}) as FmDraftFilter;
    filter['isAll'] = this.isAll;
    this.navigate(filter);
  }

  public onSearch() {
    const params = this.getSearchPage(this.filter) as FmDraftFilter;
    this.onPageChange(params);
  }

  private loadList(params?: any) {
    this.commonSrv.getDeletedList(this.type, this.getParams(params)).subscribe((data: IDataResponse<any>) => {
      this.dataList = data.content;
      this.total = data.totalElements;
      this.resetSelectedAll();
    });
  }

  /**
   * Открытие мод. окна "Удаление документов"
   */
 deleteDocumentModal(): void {
  const selected = this.getSelectedModel(this.dataList) as Array<any>;
  const modalRef = this.modalDialog.open(DeleteComponent, this._MODAL_CONFIG);
  modalRef.componentInstance.open({
    group: getGroupType(this.type),
    item: selected.map(i => {
      return {
        id: i.id,
        name: i.documentType.name,
        date: i.messageDate?.replace('00:00:00', ''),
        type: i.documentType.type,
        process: true
      };
    }),
  });
  modalRef.result.then((isDelete) => {
    if (isDelete) {
      this.importLoad();
      this.isSelectedAll = false;
    }
  });
}

  /* Вернуть в черновики*/
  returnToDraft() {
    const selected = this.getSelectedModel(this.dataList) as Array<any>
    this.commonSrv.deletedToDrafts(selected.map(i => i.id), this.type).subscribe((data: ProcessDocsResponseModel) => {
      this.importLoad();
      this.isSelectedAll = false;
    });
  }

  getConfig(docType) {
    return getDocumentConfig(docType);
  }

  get groupType() {
    return this.type;
  }

  hideColumn(type: string) {
    const arr = ['rejection', 'otkazgoz'];
    return !(arr.indexOf(type) !== -1);
  }

  showColumn(type: string) {
    const arr = ['otkazgoz'];
    return (arr.indexOf(type) !== -1);
  }
}
