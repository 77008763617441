import { Component, OnDestroy } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { CommonService } from "../../../services";
import { IconsService } from '@amlCore/services';
import { FormArray, FormGroup } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-correct-replace-remove-modal',
  templateUrl: 'correctReplaceRemove.component.html',
  styleUrls: ['correctReplaceRemove.component.scss']
})

export class CreateCorrectReplaceRemoveDocumentsComponent implements OnDestroy {
  constructor(private commonService: CommonService,
              private iconsService: IconsService,
              private activeModal: NgbActiveModal,
              private fb: FormBuilder,
              ) {}

  selectedList: Array<any>;
  group: string;
  icons = this.iconsService.getIcons();
  formArray: FormArray;
  isLoading = false;
  destroy$ = new Subject();
  records = [];
  isServerError = false;
  successResult = false;

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  open(param: any) {
    this.selectedList = param.list;
    this.group = param.group;
    this.formArray = this.fb.array([]);
    if (this.selectedList.length) {
      this.selectedList.forEach((item) => {
        this.formArray.push(this.fb.group({
          id: item.id,
          documentName: item.documentName,
          documentStatus: item.documentStatus.name,
          fixResult: null,
          records: this.getRecords(item),
        }));
      });
    }
  }

  /**
   * Получаем записи по документу
   */
  getRecords(item): FormArray {
    this.isLoading = true;
    const arr = this.fb.array([]);
    let obj = this.fb.group({});
    this.commonService.getRecordsByDocId(item.id, this.group).pipe(takeUntil(this.destroy$)).subscribe((data) => {
      this.records.push(data);
      for (const [key, value] of Object.entries(data.recordsMap)) {
        obj = this.fb.group({
          newStatus: null,
          recordKey: key,
          status: value['acceptedState'] === undefined ? {id: 0, name : 'Статус отсутствует'} : value['acceptedState'],
        });
        arr.push(obj);
        this.isLoading = false;
      }
    },
    (err) => {
      console.log('err', err);
      obj = this.fb.group({
        error: err.error
      });
      arr.push(obj);
      this.isLoading = false;
    });
    return arr;
  }

  onClose(): void {
    this.activeModal.close();
  }

  /**
   * Операции замены\испр\удаления
   */
  sendFix() {
    const obj = {};
    this.getFormArray.value.filter(item => item.records[0].error === undefined).forEach((item) => {
      item.records.filter((item2) => item2.newStatus !== null).forEach(element => {
        const recordMap = {};
        recordMap[element.recordKey] = element.newStatus;
        obj[item.id] = recordMap;
      });
    });
    this.isLoading = true;
    const recordsUpdateMap = {};
    recordsUpdateMap['recordsUpdateMap'] = obj;
    this.commonService.changeRecords(recordsUpdateMap, this.group).pipe(takeUntil(this.destroy$)).subscribe((response) => {
      this.isLoading = false;
      this.getFormArray.controls.forEach((elem, i) => {
        // проверка успешных id
        for (const entry of Object.entries(response.success)) {
          if (elem.value.id === entry[0]) {
            elem.patchValue({fixResult: {success: true, new_id: entry[1]}});
          }
        }
        // проверка ошибочных id
        for (const entry of Object.entries(response.errors)) {
          if (elem.value.id === entry[0]) {
            elem.patchValue({fixResult: {success: true, new_id: entry[0]}});
          }
        }

      });
      this.successResult = true;
    },
    (err) => {
      console.log(err);
      this.isServerError = true;
      this.isLoading = false;
    });
  }

  get getFormArray(): FormArray {
    return this.formArray as FormArray;
  }

  isCorrectStatusDoc(records) {
    return records[0]?.error === undefined;
  }
}
