import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ITable} from "@amlCore/components";
import {ViewInfoCreditOrgComponent} from "./view-info-credit-org.component";
import {DocumentSubForm} from "../../base";
import {DocumentBaseService} from "../../services";
import {DocumentTypeEnum} from "../../enums";


@Component({
    selector: 'app-arm-doc-table-info-org',
    templateUrl: './table-info-credit-org.component.html',
    styleUrls: ["../../base/documentForm.component.scss"],
})
export class TableInfoCreditOrgComponent extends DocumentSubForm implements OnInit, OnChanges, ITable {
  @Input() documentType = DocumentTypeEnum.OPERATIONS;
  viewForm = ViewInfoCreditOrgComponent;
  pageSize = 10;
  page = 0;

  constructor(
    protected readonly modalService: NgbModal,
    private readonly docSrv: DocumentBaseService
  ) {
    super(modalService);
  }

  ngOnInit() {
    this.currentGetFormGroup = (data) => {return this.isOperations 
      ? this.docSrv.getOperationDetails.call(this.docSrv, data)
      : this.isSuspiciousActivity 
          ? this.docSrv.getSvedKoSa.call(this.docSrv, data) 
          : this.docSrv.getSvedPredstav.call(this.docSrv, data)};
    super.ngOnInit();
  }

  ngOnChanges(change: SimpleChanges) {
    super.ngOnChanges(change);
  }

  get isOperations(){
    return this.documentType === DocumentTypeEnum.OPERATIONS
  }

  get isSuspiciousActivity(){
    return this.documentType === DocumentTypeEnum.SUSPICIOUS_ACTIVITY;
  }

}
