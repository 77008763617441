import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ITableItem} from "@amlCore/components";
import {FormGroup} from "@angular/forms";
import {DocumentComponentBaseSub} from "../../base";
import {DocumentBaseService} from "../../services";
import {DocumentTypeEnum} from '../../enums';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-arm-view-info-credit-org',
    templateUrl: './view-info-credit-org.component.html',
    styleUrls: ["../../base/documentForm.component.scss"],
})
export class ViewInfoCreditOrgComponent extends DocumentComponentBaseSub implements ITableItem , OnInit, OnDestroy {
    @Input() formGroupApp: FormGroup;
    @Input() documentData: any;
    @Input() titlePanel = `Информация о КО (филиале КО)`;

    private destroy$ = new Subject();

    constructor(readonly docSrv: DocumentBaseService) {
        super();
    }

    ngOnInit(): void {
        this.formGroup('infFilial')?.get('bikfl')?.valueChanges?.pipe(takeUntil(this.destroy$))?.subscribe(value => {
            const priznakPredstavSved = this.formGroup('priznakPredstavSved')?.value;

            if (!value && value !== '' && priznakPredstavSved == 1) {
                this.formGroup('infFilial')?.get('bikfl')?.setValue('');
            }
        })

        this.handleInfFilialDisable( this.formGroup('priznakPredstavSved')?.value == 1 && !this.isReadOnly)

        this.formGroup('priznakPredstavSved')?.valueChanges.pipe(distinctUntilChanged(), takeUntil(this.destroy$)).subscribe((value) =>  this.handleInfFilialDisable(value == 1))
    }

    currentGetForm = (data) => {return this.isOperations
    ? this.docSrv.getOperationDetails.call(this.docSrv, data)
    : this.isSuspiciousActivity
        ? this.docSrv.getSvedKoSa.call(this.docSrv, data)
        : this.docSrv.getSvedPredstav.call(this.docSrv, data)};

    isValidItem(): boolean {
        return !this.formGroup('infFilial').invalid;
    }

    handleInfFilialDisable(condition) {
        condition ? this.formGroup('infFilial')?.enable() : (this.formGroup('infFilial')?.disable());
    }

    get isOperations(){
        return this.documentType === DocumentTypeEnum.OPERATIONS;
    }

    get isSuspiciousActivity(){
        return this.documentType === DocumentTypeEnum.SUSPICIOUS_ACTIVITY;
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
      }

}
