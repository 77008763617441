import {Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ImportDoc, ImportDocsResponseModel} from "../../model/ImportDocsResponseModel";
import {ApiService, IconsService} from "@amlCore/services";
import {circularIteratorObject, getServiceName} from "@amlCore/utils";
import {FileSaverService} from "ngx-filesaver";
import {HttpParams} from '@angular/common/http';
import {docModule, InputFileComponent} from "../../../../core";
import moment from "moment";

/**
 * Новый компонент импорта
 * ИСПОЛЬЗОВАНИЕ:
 * При вызове модального окна, необходимо передать docModule
 * Пример: componentInstance.docModuleImport = 'control6670';
 * ВАЖНО:
 * Данный компонент не умеет работать с импортом 407 вложений. Доработать это в соответствующих задачах !
 */
@Component({
    selector: 'app-import-document-v2',
    templateUrl: './importDocumentsV2.component.html',
    styleUrls: ['./importDocumentsV2.component.scss']
})
export class ImportDocumentsV2Component implements OnInit, OnDestroy {
    @Input() url ='/api/v1/documents/import';
    @Input() typeFileToImport = 'ФЭС';
    @Input() nominativeTypeFileToImport = 'ФЭС';
    @Input() group_type?;
    @Input() docModuleImport: docModule = 'fm';

    @ViewChild('inputFile') inputFile: InputFileComponent;

    loading = false;
    accept = '.xml';
    importedData: [] = [];
    importPercent = 0;
    icons = this._iconsService.getIcons();
    orgInn = sessionStorage.getItem('orgInn');
    overSizeFilesArr: any = [];
    upploadingFiles = false;
    filesCount = 0;
    importFiles: File[] = []; // импортируемые файлы
    importFilesService: ImportDoc[] = [];
    needApprovDocs = [];
    needApprovAttachments = [];
    needFillPrescribed = [];
    queryParams: HttpParams = new HttpParams();
    noImportFlag = false;
    loaded = false;
    vidInf407Attachment: string = null;
    isInnInQuerryArray = ['otkazgoz', 'p407', 'strateg'];
    notPassMask: File[] = [];
    textToExport: string[] = [];
    @ViewChild('needFillPrescribedModal') fillPrescribedTemplateRef: TemplateRef<any>;

    constructor(private _activeModal: NgbActiveModal,
                private _iconsService: IconsService,
                protected fSaver: FileSaverService) {
    }

    ngOnInit(): void {
        if (this.group_type && this.isInnInQuerryArray.indexOf(this.group_type) === -1) {
            this.url += `?inn=${this.orgInn}`;
        }
    }

    onClose(): void {
        this._activeModal.close(true);
        this.loading = false;
    }

    startImport(repeatSend: boolean): void {
        const tempObj = {};
        let countTryImported  = 0;
        this.importFilesService = this.importFiles.map((file, index) => {
            const serviceName = getServiceName(file.name, this.docModuleImport);
            return {
                file,
                serviceName: serviceName || '',
                importUrl: serviceName ? `/api/v1/documents/${serviceName}/import` : '',
                loading: false,
                success: false,
                tryImported: false,
                indexFile: index,
                importRes: {} as ImportDocsResponseModel,
                isError: false,
            };
        });
        const iterateObject = circularIteratorObject(this.importFilesService);
        const step = (iterate) => {
            const next = iterate.next();
            if (this.loading) {
                importFileStep(iterate, next, tempObj, repeatSend);
            }
        };
        const importFileStep = (iterateObjFunc: any, objectWithFile: any, hashTable: {},  repeat) => {
            if (!hashTable[objectWithFile.object.serviceName]) {
                hashTable[objectWithFile.object.serviceName] = 0;
            }
            if (!objectWithFile.object.tryImported) {
                objectWithFile.object.loading = true;
            }
            if (!hashTable[objectWithFile.object.serviceName] && objectWithFile.object.serviceName && !objectWithFile.object.tryImported) {
                hashTable[objectWithFile.object.serviceName]++;
                this.inputFile.sendOnlyFormData([objectWithFile.object.file], this.overSizeFilesArr
                    , repeat, objectWithFile.object.importUrl)
                    .subscribe((data) => {
                        this.importFilesService[objectWithFile.object.indexFile].importRes = data;
                        this.textToExport.push(data.text);
                        objectWithFile.object.success = true;
                        hashTable[objectWithFile.object.serviceName]--;
                        objectWithFile.object.loading = false;
                        objectWithFile.object.tryImported = true;
                        countTryImported++;
                    }, error => {
                        objectWithFile.object.success = false;
                        hashTable[objectWithFile.object.serviceName]--;
                        objectWithFile.object.loading = false;
                        objectWithFile.object.tryImported = true;
                        objectWithFile.object.isError = true;
                        countTryImported++;
                    });
            }
            if (!objectWithFile.object.serviceName && !objectWithFile.object.tryImported) {
                this.notPassMask.push(objectWithFile.object.file);
                countTryImported++;
            }
            if (!objectWithFile.object.serviceName) {
                objectWithFile.object.success = false;
                objectWithFile.object.tryImported = true;
            }
            if (countTryImported < this.importFilesService.length) {
                setTimeout(() => {
                    step(iterateObjFunc);
                }, 100)
            } else {
                this.inputFile.loader = false;
                this.loading = false;
            }
        };

        step(iterateObject);
    }

    /**
     * Обработчик начала импорта
     */
    onSendStart(files: File[]): void {
        this.loaded = false;
        this.loading = true;
        this.filesCount = files.length;
        if (files.length > 0) {
            for (const item of files) {
                this.importFiles.push(item);
            }
        } else {
            //заканчиваем импорт
            setTimeout(() => {
                this.upploadingFiles = false;
                this.loading = false;
            }, 1000);
        }
    }

    /**
     * Обработчик конца импорта
     */
    onFinishImport(event?: []): void {
        if (event) {
            this.importedData.push(...event);
        }
    }

    /**
     * Получаем файлы првышающие размер
     */
    overSizeFiles(files) {
        this.overSizeFilesArr = [];
        this.overSizeFilesArr = files;
    }

    ngOnDestroy(): void {
        this.loading = false;
    }

    /**
     * Экспорт результатов импорта для общего файла или одного
     */
    exportImportResults(documentIndex?: number): void {
        let textForTxt = '';
        if (Number.isInteger(documentIndex)) {
            textForTxt = this.importFilesService[documentIndex].importRes.text;
        } else {
            textForTxt = this.textToExport.join(" ");
        }
        const blob = new Blob([textForTxt], {type: "text/plain"});
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = this.generateFileName(!Number.isInteger(documentIndex));
        link.click();
        link.remove();
    }

    /**
     * Генерация имени файла
     * @param isAll - параметр, в зависимости от которого будет выдаваться имя для всех документов или для одного
     */
    generateFileName(isAll: boolean = false ): string {
        const date = moment();
        return isAll ? `all_import_${date.format("YYYYMMDD")}_${date.format("HHMM")}` :
            `document_import_${date.format("YYYYMMDD")}_${date.format("HHMM")}`;
    }

    /**
     * Нажатие "Выбрать файлы для импорта"
     * обнуляем результаты предыдущего импорта
     */
    getFiles() {
        this.overSizeFilesArr = [];
        this.upploadingFiles = true;
        this.importPercent = 0;
        this.filesCount = 0;
        this.importFiles = [];
        this.importFilesService = [];
        this.notPassMask = [];
        this.textToExport = [];
    }
}
