import {Component, OnInit} from '@angular/core';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ITable} from "@amlCore/components";
import {DocumentSubForm} from "@amlDocument/base";
import {DocumentBaseService} from "@amlDocument/services";
import {DocumentTypeEnum} from "../../../enums";
import {ViewNormativeActObstructionFactComponent} from "./view-normative-act-obstruction-fact.component";

@Component({
  selector: 'app-arm-doc-table-norm-act-obstruction-fact',
  templateUrl: './table-normative-act-obstruction-fact.component.html',
  styleUrls: ['../../../base/documentForm.component.scss'],
})
export class TableNormativeActObstructionFactComponent extends DocumentSubForm implements OnInit, ITable {
  documentType = DocumentTypeEnum.OBSTRUCTION_FACTS; // todo
  viewForm = ViewNormativeActObstructionFactComponent;
  pageSize = 10;
  page = 0;

  constructor(protected readonly modalService: NgbModal,
              private readonly docSrv: DocumentBaseService) {
    super(modalService);
  }

  currentGetFormGroup = (data) => this.docSrv.getStatia.call(this.docSrv, data);
  childGetFormGroup = (data) => this.docSrv.getChast.call(this.docSrv, data);

  ngOnInit() {
    super.ngOnInit();
  }

  onPageChange(e) {}
}
