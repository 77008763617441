import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {DocumentComponentBaseSub} from "@amlDocument/base";
import {DocumentConfigService, DocumentTypeEnum, getDocumentConfig} from "../../../enums";
import {QrefTypeinfo3484010206ViewComponent} from "../../../../../dictionaries";

@Component({
    selector: 'app-arm-doc-view-service-part[formGroupApp][documentData][isReadOnly][submitted]',
    templateUrl: './service-part.component.html',
    styleUrls: ["../../../base/documentForm.component.scss"],
})
export class ServicePartComponent extends DocumentComponentBaseSub implements OnInit {
    @Input() formGroupApp: FormGroup;
    @Input() documentData: any;
    @Input() documentType: DocumentTypeEnum;
    // Если РП решат перейти к формату справочников для всех 5861, доработать логику
    @ViewChild("tipInf") tipInf: QrefTypeinfo3484010206ViewComponent;
    configuration: DocumentConfigService = null;
    selectTipInf = false;

    is5392 = false;

    ngOnInit(): void {
        if (this.documentType) {
            this.configuration = getDocumentConfig(this.documentType);
            if (this.configuration.tipInf) this.selectTipInf = true;
        }

        if (this.documentType === DocumentTypeEnum.OES_OTKAZ_GOZ)
          this.is5392 = true;
    }
}
