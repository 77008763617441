import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormGroup, Validators} from "@angular/forms";
import {ITableItem} from "@amlCore/components";
import {DocumentComponentBaseSub} from "@amlDocument/base";
import {DocumentBaseService} from "@amlDocument/services";
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-arm-doc-view-sved-oper',
  templateUrl: './view-sved-oper.component.html',
  styleUrls: ["../../../base/documentForm.component.scss"],
})
export class ViewSvedOperComponent extends DocumentComponentBaseSub implements ITableItem, OnInit, OnDestroy {
  formGroupApp: FormGroup;
  documentData: any;
  showInfoOper = true;
  showTransfer = false;
  titlePanel = `Сведения об операции`;
    icons = {
      info: ['fas', 'info-circle']
  };

  private destroy$ = new Subject();

    constructor(readonly docSrv: DocumentBaseService) {
        super();
  }

  ngOnInit(): void {
    this.field('naznacheniePlatezha').valueChanges.pipe(takeUntil(this.destroy$)).subscribe(value => {
      if (!value && value !== '') {
        this.field('naznacheniePlatezha').setValue('');
      }
    });
  }

  currentGetForm = (data) => this.docSrv.getOperatsiiaFm(data);

  isValidItem(): boolean {
    return this.isFormGroupValidExcludingControls(['uchastnikOp', 'osnovanieOp']);
  }

  genIdentify(data) {
    this.field('nomerZapisi').setValue(data);
    this.field('nomerZapisi').markAsDirty();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
