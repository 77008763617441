import {Component} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {ITableItem} from "@amlCore/components";
import {DocumentComponentBaseSub} from "@amlDocument/base";
import {DocumentBaseService} from "@amlDocument/services";

@Component({
  selector: 'app-arm-doc-view-main-obstruction-fact',
  templateUrl: './view-main-obstruction-fact.component.html',
  styleUrls: ['../../../base/documentForm.component.scss'],
})
export class ViewMainObstructionFactComponent extends DocumentComponentBaseSub implements ITableItem {
  formGroupApp: FormGroup;
  documentData: any;
  titlePanel = `Сведения о фактах воспрепятствования`;
  currentGetForm = (param) => this.docSrv.getFaktVosprepiat(param);

  isValidItem(): boolean {
    return this.formGroupApp.valid;
  }

  constructor(private readonly docSrv: DocumentBaseService) {
    super();
  }

  genIdentify(data) {
    this.field('nomerZapisi').setValue(data);
    this.field('nomerZapisi').markAsDirty();
  }
}
