import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AccessJson} from "@amlCore/models";
import {FormModelService, StringDateAdapterService, TableService, UserService, VisitedService} from "@amlCore/services";
import {FmService} from "../../service";
import {FmList} from "../../model/FmList.model";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {faSpinner} from "@fortawesome/free-solid-svg-icons/faSpinner";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons/faInfoCircle";
import {FileSaverService} from 'ngx-filesaver';
import {Utils} from "@amlCore/utils";
import {Subject} from 'rxjs';
import moment from 'moment';

@Component({
  selector: 'app-armfm-fm-report-list',
  templateUrl: './fm-report-list.component.html',
  styleUrls: ['./fm-report-list.component.scss'],
})
export class FmReportListComponent implements OnInit, OnDestroy {


  isShowVisited = true;
  svedOper5861uForm: FormGroup;
  svedOper5861uAllForm: FormGroup;
  refuseContractForm: FormGroup;
  refuseOperationForm: FormGroup;
  countSendedMessagesForm: FormGroup;
  sendedFromFesForm: FormGroup;
  factOfObstructionForm: FormGroup;
  suspiciousActivityForm: FormGroup;
  registryForm: FormGroup;
  today = new Date();
  public formModelService: FormModelService;
  constructor(protected fmSrv: FmService,
              private fSaver: FileSaverService,
              protected activatedRoute: ActivatedRoute,
              protected visitedService: VisitedService,
              protected modalDialog: NgbModal,
              protected tableService: TableService,
              protected router: Router,
              private fb: FormBuilder,
              public dateSrv: StringDateAdapterService,
              private userService: UserService,
              ) {
    this.tableService.sharedMenu().emit(this.activatedRoute.snapshot.data.pageTitle);
  }

  dataList: Array<FmList> = [];
  isAll = true;
  id: string;
  access: AccessJson;
  disabled: boolean;
  toggleIconSvedOper5861u: boolean;
  toggleIconSvedOper5861uAll: boolean;
  toggleIconRefuseContract: boolean;
  toggleIconRefuseOperation: boolean;
  toggleIconCountSendedMessages: boolean;
  toggleIconSendedFromFes: boolean;
  toggleIconFactOfObstruction: boolean;
  toggleIconSuspiciousActivity: boolean;
  toggleIconRegistry: boolean;

  spinnerSvedOper5861u: boolean;
  spinnerSvedOper5861uAll: boolean;
  spinnerRefuseContract: boolean;
  spinnerRefuseOperation: boolean;
  spinnerCountSendedMessages: boolean;
  spinnerSendedFromFes: boolean;
  spinnerFactOfObstruction: boolean;
  spinnerSuspiciousActivity: boolean;
  spinnerRegistry: boolean;

  public errorArray = {};
  icons = {
    spinner: faSpinner,
    info: faInfoCircle
  };
  orgName: string;

  private destroy$ = new Subject<void>();
  currentDate = new Date().toLocaleDateString();
  ngOnInit() {
    const emptyForm = {
      start: ['', Validators.required],
      end: ['', Validators.required],
      creatorFio: ['', [Validators.required, Validators.maxLength(255)]],};

    this.svedOper5861uForm = this.fb.group(emptyForm);
    this.svedOper5861uAllForm = this.fb.group(emptyForm);
    this.refuseContractForm = this.fb.group(emptyForm);
    this.refuseOperationForm = this.fb.group(emptyForm);
    this.countSendedMessagesForm = this.fb.group(emptyForm);
    this.sendedFromFesForm = this.fb.group(emptyForm);
    this.factOfObstructionForm = this.fb.group(emptyForm);
    this.suspiciousActivityForm = this.fb.group(emptyForm);
    this.registryForm = this.fb.group(emptyForm);
}
  /**
   * Очищает форму при открытии аккордеона
   * прописывает конечной дате текущую дату
   * @param FormGroup текущая группа формы
   */
  openReport(FormGroup: FormGroup) {
    FormGroup.reset();
    FormGroup.patchValue({'end': this.currentDate});
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  submitForm(formGroupName: string, formGroup: FormGroup ) {
    this.disabled = true;
    let params = {};
    switch (formGroupName) {
      case 'svedOper5861uForm' :
        this.spinnerSvedOper5861u = true;
        this.fmSrv.reportOperationsInfo(formGroup.value).subscribe(
          data => {
            if (data.type === 4) {
              this.spinnerSvedOper5861u = false;
              this.disabled = false;
              this.fSaver.save(data.body, Utils.getFileNameFromContentDisposition(data));
            }
          },
          error => {
            this.spinnerSvedOper5861u = false;
            this.disabled = false;
            console.warn(error);
          });
        break;

      case 'refuseOperationList' :
        this.spinnerRefuseOperation = true;
        params = JSON.parse(JSON.stringify(formGroup.value));
        params['orgName'] = sessionStorage.getItem('orgName');
        this.fmSrv.reportRefuseList(params).subscribe(
          data => {
            if (data.type === 4) {
              this.spinnerRefuseOperation = false;
              this.disabled = false;
              this.fSaver.save(data.body, Utils.getFileNameFromContentDisposition(data));
            }
          },
          error => {
            this.spinnerRefuseOperation = false;
            this.disabled = false;
            console.warn(error);
          });
        break;

      case 'countSendedMessagesForm' :
        this.spinnerCountSendedMessages = true;
        this.fmSrv.reportCountSendedMessages(formGroup.value).subscribe(
          (data) => {
            if (data.type === 4) {
              this.spinnerCountSendedMessages = false;
              this.disabled = false;
              this.fSaver.save(data.body, Utils.getFileNameFromContentDisposition(data));
            }
          },
          error => {
            this.spinnerCountSendedMessages = false;
            this.disabled = false;
            console.warn(error);
          });
        break;

      case 'factOfObstructionForm' :
        this.spinnerFactOfObstruction = true;
        this.fmSrv.reportfactOfObstruction(formGroup.value).subscribe(
          (data) => {
            this.spinnerFactOfObstruction = false;
            if (data.type === 4) {
              this.spinnerFactOfObstruction = false;
              this.disabled = false;
              this.fSaver.save(data.body, Utils.getFileNameFromContentDisposition(data));
            }
          },
          error => {
            this.spinnerFactOfObstruction = false;
            this.disabled = false;
            console.warn(error);
          });
        break;

      case 'refuseContractForm' :
        this.spinnerRefuseContract = true;
        params = JSON.parse(JSON.stringify(formGroup.value));
        params['orgName'] = sessionStorage.getItem('orgName');
        this.fmSrv.reportRefuseContract(params).subscribe(
          (data) => {
            if (data.type === 4) {
              this.spinnerRefuseContract = false;
              this.disabled = false;
              this.fSaver.save(data.body, Utils.getFileNameFromContentDisposition(data));
            }
          },
          error => {
            this.spinnerRefuseContract = false;
            this.disabled = false;
            console.warn(error);
          });
        break;

      case 'sendedFromFesForm' :
          this.spinnerSendedFromFes = true;
          this.fmSrv.reportSendedFromFesList(formGroup.value).subscribe(
            (data) => {
              if (data.type === 4) {
                this.spinnerSendedFromFes = false;
                this.disabled = false;
                this.fSaver.save(data.body, Utils.getFileNameFromContentDisposition(data));
              }
            },
            error => {
              this.spinnerSendedFromFes = false;
              this.disabled = false;
              console.warn(error);
            });
        break;

      default: return;
    }
  }

  clearForm(formGroup: FormGroup) {
    formGroup.reset();
  }

  disableSubmitBtn(formGroup: FormGroup) {
    return !formGroup.valid;
  }

  f(field: string, formGroup: FormGroup ) {
    return formGroup.get(field);
  }

   /**
   * compareDateStartEnd(start: string , end: string,  errorType: string )
   * Метод сравнивает два значения ДАТ - start - начальное и end - конечное;
   * errorType - назначение проверяемого поля;
   */
  compareDateStartEnd(start: string, end: string, errorType: string, formGroup: FormGroup) {
    delete this.errorArray[errorType];
    const startDateError = 'Дата начала периода не может быть больше даты конца периода!';
    if (start && end && moment(start, "DD.MM.YYYY HH.mm.ss").isAfter(moment(end, "DD.MM.YYYY HH.mm.ss"))) {
      this.errorArray[errorType] = startDateError;
    }
    formGroup.get('start').updateValueAndValidity();
    formGroup.get('end').updateValueAndValidity();
    formGroup.get('creatorFio').updateValueAndValidity();
  }
}


