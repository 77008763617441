import {Component, Input, OnInit, Optional} from "@angular/core";
import { BaseFilterController } from "../../BaseFilterController";
import { FormBuilder } from "@angular/forms";
import {
  DictionaryService,
  ResourceService,
  StringDateAdapterService,
  TableService,
} from "@amlCore/services";
import { ActivatedRoute } from "@angular/router";
import { AmlStrategKoFilter } from "src/app/core/models/amlStrategKoFilter.model";
import { Strateg655PFilterService } from 'src/app/arm/strateg655p/service/strateg655p-filter.service';
import {Strateg5392UFilterService} from "src/app/arm/strateg5392U/service/strateg5392u-filter.service";
import {DocTypeSearch, getDocTypes} from "../../../constants";

@Component({
  selector: "app-amlstrategko-sended-list-filter",
  templateUrl: "./amlstrategkoSendedListFilter.component.html",
})
export class AmlStrategSendedListFilterComponent
  extends BaseFilterController
  implements OnInit
{
  public filterObjectModel = new AmlStrategKoFilter();
  placeholder = "по идентификатору документа, по имени xml-документа";
  creatorList = [];
  docTypes: DocTypeSearch[] = []
  @Input() docType: 'strateg655p' | 'strateg5392u' = 'strateg5392u' 

  constructor(
    private readonly fb: FormBuilder,
    private readonly resourceSrv: ResourceService,
    private dictSrv: DictionaryService,
    protected tableSrv: TableService,
    public dateSrv: StringDateAdapterService,
    protected readonly activatedRoute: ActivatedRoute,
    @Optional() private strateg655PFilterService: Strateg655PFilterService,
    @Optional() private strateg5392UFilterService: Strateg5392UFilterService
  ) {
    super(tableSrv, activatedRoute);
  }

  ngOnInit() {
    this.init();
    this.loadCreatorList();
    this.docType === "strateg655p" ? this.strateg655PFilterService.loadUserAccessList() : this.strateg5392UFilterService.loadUserAccessList()
    this.docTypes = getDocTypes(this.docType)
  }

  //Получение списка пользователей
  private loadCreatorList() {
    const customParams = {
      sort: "fullName",
      dir: "asc",
      page: "0",
      size: "200",
    };
    this.resourceSrv.getUserList(customParams).subscribe(({ content }) => {
      this.creatorList = content || [];
    });
  }

  /**
   * Метод создания формы
   * @return FormGroup
   */
  public createFormModel() {
    const formConfig = new AmlStrategKoFilter();
    return this.fb.group(formConfig);
  }

  getStartFilterModel(): AmlStrategKoFilter {
    return {
      documentType: null,
      documentStatus: null,
    } as AmlStrategKoFilter;
  }

  /**
   * Описания полей
   */
   public getFilterDescription(): any {
    return {
      documentType: 'Документ',
      documentStatus: 'Статус',
      dateFrom: 'Дата документа С',
      dateTo: 'Дата документа По',
      signDateFrom: 'Дата подписи С',
      signDateTo: 'Дата подписи По',
      containerName: 'Наименование транспортного контейнера',
      creatorId: 'Владелец документа',
    };
  }

  
  docStatuses = [
    {id: 2, name: 'SENDED', title: 'Отправлен'},
    {id: 99, name: 'ACCEPTED', title: 'Принят'},
    {id: 5, name: 'REJECTED', title: 'Отвергнут'},
    {id: 6, name: 'UPLOADEDSHIPPING', title: 'Выгружен для отправки'},
  ]
  
  get users() {
    return this.dictSrv.remoteDict.strateg.list || [];
  }
}
