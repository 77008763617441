import {Component, OnInit, ChangeDetectorRef} from "@angular/core";
import {FormBuilder} from "@angular/forms";
import {FmDraftFilter} from "@amlCore/models";
import {DictionaryService, StringDateAdapterService, TableService} from "@amlCore/services";
import {ActivatedRoute} from "@angular/router";
import { BaseFilterController } from "../../BaseFilterController";

@Component({
  selector: 'app-rejection-deleted-list-filter',
  templateUrl: './rejection-deleted-list-filter.component.html'
})
export class RejectionDeletedListFilterComponent extends BaseFilterController implements OnInit {
  public filterObjectModel = new FmDraftFilter();
  placeholder = 'Поиск по идентификатору документа, по имени xml-документа';

  constructor(private readonly fb: FormBuilder,
    private readonly _changeDetectorRef: ChangeDetectorRef,
    protected tableSrv: TableService,
    private dictSrv: DictionaryService,
    public dateSrv: StringDateAdapterService,
    protected readonly activatedRoute: ActivatedRoute) {
    super(tableSrv, activatedRoute);
  }

  /**
   * Fix error type ExpressionChangedAfterItHasBeenCheckedError
   */
  ngAfterViewChecked(): void {
    this._changeDetectorRef.detectChanges();
  }

  ngOnInit() {
    this.init();
  }

  /**
   * Метод создания формы
   * @return FormGroup
   */
  public createFormModel() {
    const formConfig = new FmDraftFilter();

    return this.fb.group(formConfig);
  }

  getStartFilterModel(): FmDraftFilter {
    return {
      documentType: null,
      documentStatus: null
    } as FmDraftFilter;
  }

  /**
   * Описания полей
   */
  public getFilterDescription(): any {
    return {
      documentType: 'Тип документа',
      dateFrom: 'Дата создания с',
      dateTo: 'Дата по',
      creatorId: 'Владелец',
    };
  }
  docTypes = [
    {id: 1, name: 'KVIT_ARJ_REJECTION', title: 'Квитанция о непринятии архивного файла Банка России'},
    {id: 2, name: 'KVIT_REJECTION', title: 'Квитанция о принятии/непринятии информации по отказам'}
  ]

  get users() {
    return this.dictSrv?.remoteDict?.usersRejection?.list || [];
  }
}  