import {Component, Input} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {DocumentComponentBaseSub} from "../../base";

@Component({
    selector: 'app-arm-doc-view-info-credit-org-using-bank-account',
    templateUrl: './info-credit-org-using-bank-account.component.html',
    styleUrls: ["../../base/documentForm.component.scss"],
})
export class InfoCreditOrgUsingBankAccountComponent extends DocumentComponentBaseSub {
    @Input() formGroupApp: FormGroup;
    @Input() documentData: any;
}
