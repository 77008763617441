import {Directive, EmbeddedViewRef, Input, OnChanges, OnDestroy, OnInit, Optional, TemplateRef, ViewContainerRef} from '@angular/core';
import {FormControl, FormGroupDirective} from "@angular/forms";

/**
 * Директория для всех FormControl отображающихся по условию (текущий)
 * @param isShow - состояние формы (показан/скрыт) (boolean)
 */
@Directive({selector: '[appShowFormControl]'})
export class ShowFormControlDirective implements OnInit, OnDestroy, OnChanges {
  @Input() set appShowFormControl(show: boolean) {
    this.isShow = show;
  }
  @Input() set appShowFormControlControl(control: FormControl | string) {
    this.formControl = typeof control === 'string'
      ? this.parentForm?.form?.get(control) as FormControl
      : control;
  }
  @Input() set appShowFormControlIsReadOnly(isReadOnly: boolean) {
    this.isReadOnly = isReadOnly;
  }
  @Input() set appShowFormControlIsAlwaysShow(isAlwaysShow: boolean) {
    this.isAlwaysShow = isAlwaysShow;
  }
  private isShow: boolean;
  private isReadOnly: boolean;
  private isAlwaysShow: boolean;
  private formControl: FormControl;
  private currentComp: EmbeddedViewRef<any>;

  constructor(private templateRef: TemplateRef<any>,
              private viewContainer: ViewContainerRef,
              @Optional() private parentForm: FormGroupDirective) { }

  ngOnInit() {
    this.showFormControl();
  }

  ngOnChanges() {
    if (this.formControl)
        this.showFormControl();
  }

  private showFormControl() {
    if (!this.formControl) return;
    if (this.isShow) {
      if (!this.isReadOnly)
        this.formControl.enable({ onlySelf: true });

      this.showTemplate(true);
    } else {
      this.showTemplate(false);

      if (!this.isReadOnly && this.formControl.enabled)
        this.formControl.disable({ onlySelf: true });
    }
    this.formControl.updateValueAndValidity({ onlySelf: true });
  }

  private showTemplate(isShow: boolean) {
    if (this.isAlwaysShow)
        return this.createEmbeddedView();

    isShow
      ? this.createEmbeddedView()
      : this.destroyView();
  }

  private createEmbeddedView() {
    if (!this.currentComp)
      this.currentComp = this.viewContainer.createEmbeddedView(this.templateRef);
  }

  private destroyView() {
    this.currentComp = null;
    this.viewContainer.clear();
  }

  ngOnDestroy() {
    this.destroyView();
  }
}
