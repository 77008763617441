import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ReceiveDocResponseModel} from './ReceiveDocResponseModel';
import {IconsService} from '@amlCore/services';
import {FmService} from '../../service';
import {Utils} from "@amlCore/utils";
import {FileSaverService} from "ngx-filesaver";


@Component({
  selector: 'app-receive-documents',
  templateUrl: './receiveDocuments.component.html',
  styleUrls: ['./receiveDocuments.component.scss'],
})
export class ReceiveDocumentsComponent implements OnInit {
  url: string;
  accept: string;
  group: string;
  formDataName = 'files';
  loading = false;
  errorMsg = '';
  success: boolean;
  docsResultList: ReceiveDocResponseModel[] = [];
  icons = this._iconsService.getIcons();

  constructor(private _activeModal: NgbActiveModal,
              private _iconsService: IconsService,
              protected fmSrv: FmService,
              protected fSaver: FileSaverService) { }

  ngOnInit(): void {
  }

  open(param: any) {
    this.url = param.url || '/api/v1/documents/sended/receive';
    this.accept = param.accept || '.zip';
    this.group = param.group || '';
    if (param.formDataName) {
      this.formDataName = param?.formDataName
    }
  }

  onClose(): void {
    this._activeModal.close();
  }

  getFileList(response: ReceiveDocResponseModel[]): void {
    this.errorMsg = '';
    if (response && !response.some(r=> !r.success)){
      this.fmSrv.closeRecieveModal.next(true);
    }
    this.docsResultList = response;
    this.success = true;
  }

  callBackError(response: any): void {
   this.errorMsg = '';
   console.log(response);
   if (response.error) {
      this.success = false;
      this.errorMsg = response.error;
   }
  }

  /**
   * Экспорт результатов импорта
   */
  downloadReportSendedReceiveResult(fileName?: string): void {
    this.fmSrv.reportSendedFromReceiveList(this.docsResultList, this.group,fileName).subscribe(response => {
        if (response.type === 4) {
          this.fSaver.save(response.body, Utils.getFileNameFromContentDisposition(response));
        }
    });
  }

  /**
   * Нажатие "Выбрать файлы для получения"
   * обнуляем результаты предыдущего получения
   */
  getFiles() {
    if (this.docsResultList) {
      this.docsResultList = [];
    }
    this.success = false;
  }
}
