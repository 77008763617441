import { BaseFilterModel } from "./baseFilter.model";

export class AmlStrategKoFilter extends BaseFilterModel {
  public documentType = null;
  public documentStatus = null;
  public dateFrom = null;
  public dateTo = null;
  public createDateFrom = null;
  public createDateTo = null;
  public signDateFrom = null;
  public signDateTo = null;
  public containerName = null;
  public creatorId = null;
}
