import {Injectable} from "@angular/core";
import {ApiService, UserService} from "@amlCore/services";
import {IDataResponse} from "@amlCore/interfaces";
import {Observable} from "rxjs";
import moment from "moment";
import {DocumentConfig, DocumentTypeEnum, getDocumentConfig, getGroupType, GroupType} from "../enums";
import { CommonList } from "../model";

@Injectable()
export class CommonService {
    constructor(private api: ApiService, protected userSrv: UserService) {}

    private _docIds: string[] = [];
    versiiaFormata = "2.0";
    versProg = "ARM FM";

    /**
     * Подготовка начальных данных для формы создания нового документа
     */
    prepareInitDocumentData(documentType: DocumentTypeEnum): any {
        const orgInfo = this.userSrv.getOrgInfo();
        const configuration = getDocumentConfig(documentType);

        switch (documentType) {
            case DocumentTypeEnum.OES_OTKAZ_GOZ:
                return {
                    document: this._getDocumentDataOesOtkazGoz(
                        orgInfo,
                        configuration
                    ),
                };

            default:
                return {
                    document: this._getDocumentDataFm(orgInfo, configuration),
                };
        }
    }

    // получить данные по документу
    getDocInfoById(id, type) {
        const config = getDocumentConfig(type);
        return this.api.get(config.api + id);
    }

    /**
     * Блокирование документов
     * @param type
     * @param ids
     */
    blockDocuments(ids: string[], group?: string) {
        return group === 'rejection' ?
        this.api.post(`${DocumentConfig.REGISTRY_REJECTION.api}block`, {documents: ids}) : group ?
        this.api.post(`/api/v1/documents/${group}/block`, {documents: ids}) : this.api.post(`/api/v1/documents/block`, {documents: ids})
    }

    /**
     * Разблокировка документов
     * @param type
     * @param ids
     */
    unBlockDocuments(ids?: string[], group?: string) {
        const sentIds = ids?.length ? ids : this._docIds;
        return group === 'rejection' ?
        this.api.post(`${DocumentConfig.REGISTRY_REJECTION.api}unblock`, {documents: sentIds}) : group ? this.api.post(`/api/v1/documents/${group}/unblock`, {documents: sentIds}) :
        this.api.post(`/api/v1/documents/unblock`, {documents: sentIds});
    }

    /**
     * Отправка файла
     */
    sendDocuments(ids: string[], type: string): Observable<any> {
        return  (this.api.post(type ? `/api/v1/documents/${type}/wait/send` : `/api/v1/documents/wait/send`, {
            documents: ids,
        })) ;
    }

    /**
     *  Информация по отправке
     */
    sendDocumentsInfo(ids: string[], type: string): Observable<any> {
        return  this.api.post(type ? `/api/v1/documents/${type}/wait/send/info` : `/api/v1/documents/wait/send/info`);
    }

    /**
     *  проверка документов
     */
    checkDocuments(ids: string[], group?: string): Observable<any> {
        return group
            ? this.api.post(`/api/v1/documents/${group}/check`, {
                  documents: ids,
              })
            : this.api.post(`/api/v1/documents/check`, { documents: ids });
    }

    /**
     *  экспорт результатов проверки документов
     */
    exportCheckDocuments(params: any) {
        return this.api.byte(`/api/v1/documents/check/export`, params);
    }
    /**
     * Удалить документ
     * @param documentType тип документа
     * @param documentId id документа
     */
    deleteDocument(documentType: DocumentTypeEnum, documentId: string): Observable<any> {
        const typeConf = getDocumentConfig(documentType);
        return this.api.delete(`${typeConf.api}${documentId}`);
    }

    /**
     * Удалить несколько документов
     * @param documentType тип документа
     * @param documentIds массив id документов
     */
    deleteDocuments(documentType: DocumentTypeEnum, documentIds: Array<String>): Observable<any> {
        const typeConf = getDocumentConfig(documentType);
        return this.api.deleteArray(`${typeConf.api}`, documentIds);
    }

    /**
     * Переместить в Удаленные
     * @param documentIds массив id документов
     * @param group переданная группа
     */
    moveToDeleted(documentIds: Array<String>, group: string = ''): Observable<any> {
        const currentGroup = getGroupType(group)
        return currentGroup ? this.api.post(`/api/v1/documents/${currentGroup}/moveToDeleted`,  documentIds)
            : this.api.post(`/api/v1/documents/moveToDeleted`,  documentIds);
    }

    /**
     * Переместить из раздела Удаленные в Черновики
     * @param group группа из url
     * @param fromDirectory из какой директории возвращаем в черновики
     * @param documentIds массив id документов
     */
    deletedToDrafts(documentIds: Array<String>, group: string = '', fromDirectory: string = 'deleted'): Observable<any> {
        const currentGroup = getGroupType(group)
        return currentGroup ? this.api.post(`/api/v1/documents/${currentGroup}/${fromDirectory}/moveToDraft`, documentIds)
            : this.api.post(`/api/v1/documents/${fromDirectory}/moveToDraft`, documentIds);
    }

    /**
     * Получение списка удалённых документов
     * @param group название раздела/группы, откуда поступает запрос
     * @param params параметры для фильтрации
     * @returns
     */
    getDeletedList(group, params ): Observable<any> {
        const currentGroup = getGroupType(group)
        if (params.hasOwnProperty('documentStatus')) {
            params['documentStatus'] = [params['documentStatus']];
        }
        if (group === 'rejection') {
            return this.api.post(`/api/v1/documents/registryRejection/removed/`, params);
        }
        return currentGroup ? this.api.post(`/api/v1/documents/${currentGroup}/deleted/`, params)
                            : this.api.post(`/api/v1/documents/deleted/`, params);
    }

    /**
     * Получение списка ахивированных документов
     * @param params
     * @returns
     */
    getArchiveList(group, params): Observable<IDataResponse<CommonList>> {
        const currentGroup = getGroupType(group);

        if (params.hasOwnProperty('documentStatus')) {
            params['documentStatus'] = [params['documentStatus']];
        }
        return currentGroup ? this.api.post(`/api/v1/documents/${currentGroup}/archive`, params)
        : this.api.post(`/api/v1/documents/archive/`, params);

    }

    /**
     * Получение списка готовых к отправке документов
     * @param group - группа документа
     * @param params - параметры для поиска
     */
    getSendReadyList(group, params): Observable<IDataResponse<CommonList>> {
        const currentGroup = getGroupType(group);

        if (params.hasOwnProperty('documentStatus')) {
            params['documentStatus'] = [params['documentStatus']];
        }
        return currentGroup ? this.api.post(`/api/v1/documents/${currentGroup}/wait/`, params)
            : this.api.post(`/api/v1/documents/wait/`, params);

    }

    /**
     * Получение списка черновиков
     * @param group - группа документа
     * @param params - параметры для поиска
     */
    getDraftList(group, params): Observable<IDataResponse<CommonList>> {
        const currentGroup = getGroupType(group);

        if (params.hasOwnProperty('documentStatus')) {
            params['documentStatus'] = [params['documentStatus']];
        }
        return currentGroup ? this.api.post(`/api/v1/documents/${currentGroup}/draft/`, params)
            : this.api.post(`/api/v1/documents/draft/`, params);

    }

    /**
     * Получение списка отправленных
     * @param group - группа документа
     * @param params - параметры для поиска
     */
    getSendedList(group, params): Observable<IDataResponse<CommonList>> {
        const currentGroup = getGroupType(group);

        if (params.hasOwnProperty('documentStatus')) {
            params['documentStatus'] = [params['documentStatus']];
        }
        return currentGroup ? this.api.post(`/api/v1/documents/${currentGroup}/sended/`, params)
            : this.api.post(`/api/v1/documents/sended/`, params);

    }

    /**
     * Запрос на перемещение документов в папку Архив
     * @param documentIds id документов, которые нужно перенести в папку Архив
     * @param group группа
     * @returns
     */
    moveToArchive(documentIds: Array<String>, group = ''): Observable<any> {
        return group ? this.api.post(`/api/v1/documents/${group}/archive/moveToArchive`, documentIds)
            : this.api.post(`/api/v1/documents/archive/moveToArchive`, documentIds)
    }

    /**
     * Запрос на создании копии документа
     * @param documentId id документа, копию которого нужно создать
     * @param group название сервиса из Фм, к которому относится документ (verificationResult, freezing и т.д.)
     * @returns
     */
    createCopyDocuments(documentId: string, group: DocumentTypeEnum): Observable<any> {
        const config = getDocumentConfig(group);
        return this.api.get(`${config.api}copy/${documentId}`);
    }

    /**
     * Копирование документа в папке Архив
     * @param documentId  id документа
     * @param group группа
     * @returns
     */

    copyArchiveDoc(group = '', documentId: string): Observable<any> {
        const currentGroup = getGroupType(group);
        // ToDo временно стоит костыль для плавного перехода с fm='' на fm='sko115fz'
        if (group === 'fm') {
            return this.api.get(`/api/v1/documents/sko115fz/archive/copy/${documentId}`)
        }
        return currentGroup ? this.api.get(`/api/v1/documents/${currentGroup}/archive/copy/${documentId}`) :
            this.api.get(`/api/v1/documents/archive/copy/${documentId}`)
    }

    /**
     * Копирование документа в папке Отправленные
     * @param documentId  id документа
     * @returns
     */

    copySended5392Doc(documentId: string): Observable<any> {
        return this.api.get(`/api/v1/documents/oes/otkaz/goz/copy/${documentId}`)
    }

    private _getDocumentDataOesOtkazGoz(orgInfo, configuration) {
        return {
            sluzhChastKO: {
                dataSoob: moment().format("DD/MM/YYYY"),
                tipInf: configuration?.tipInf[0],
                versProg: "Comita AML 1.0",
                versForm: '1.2',
                idkorr: orgInfo?.idLk,
                bikkonaprv: orgInfo?.code,
            },
        };
    }

    private _getDocumentDataFm(orgInfo, configuration) {
        return {
            informChast: {
                infBank: {
                    regNomKO: orgInfo?.regNumber,
                    bikko: orgInfo?.code,
                    nomFlPeredaiushchij: orgInfo?.filial,
                    okatoko: orgInfo?.oktmo?.substring(0, 2),
                },
            },
            sluzhChast: {
                versiiaFormata: this.versiiaFormata,
                versProg: this.versProg,
                tipInf: configuration?.tipInf[0],
                idkorr: orgInfo?.idLk,
                dataSoobshcheniia: moment().format("DD/MM/YYYY"),
            },
        };
    }

  getRecordsByDocId(documentId, group?: string): Observable<any> {
    let groupName = '';
    switch (group) {
      case 'fm' : {
        groupName = 'sko115fz';
        break;
      }
    }
    return this.api.get(`/api/v1/documents/${groupName}/recordsInfo/${documentId}`);
  }

  changeRecords(params, group): Observable<any> {
    let groupName = '';
    console.log('group - ', group);

    switch (group) {
      case 'fm' : {
        groupName = 'sko115fz';
        break;
      }
    }
    return this.api.post(` /api/v1/documents/${groupName}/changeRecords`, params);
  }
}
