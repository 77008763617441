import {AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, OnInit} from "@angular/core";
import {FormArray, FormControl, FormGroup, Validators} from "@angular/forms";
import {BehaviorSubject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {DocumentBaseService, DocumentComponentBaseSub} from "../../../../../../../documentForm";
import {ITableItem, TableEditorComponent} from "@amlCore/components";
import {ConfirmComponent} from "@amlCore/modals";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import * as selectItems from '../../../../../../../../../json/selectItems.json';
import {ICodeName} from "../../../../../../../../dictionaries/models";

@Component({
    selector: "app-armfm-v-sved-refuse-v1",
    templateUrl: "./view-sved-refuse.html",
    styleUrls: ['../../../../../../../documentForm/base/documentForm.component.scss']
})
export class ViewSvedRefuse
    extends DocumentComponentBaseSub
    implements ITableItem, OnInit, OnDestroy, AfterViewChecked
{
    showInfoRefuse = true;
    showInfoClient = true;

    formGroupApp: FormGroup;
    documentData: any;
    titlePanel = `Сведения о случаях отказа`;
    //для директивы смены валидации
    ogrniulValidators = [Validators.required, Validators.maxLength(500)];
    secondOgrniulValidators = [Validators.maxLength(500)];
    initialClientType: number;
    //получаем тип клиента
    controlValue$ = new BehaviorSubject(null);
    clientSelectItems = selectItems.clientInfo;
    priznakKlientaItems: Array<ICodeName>;

    currentGetForm = (param) => this.docSrv.getSvedOtkaz(param, [], this.documentType);

    isValidItem(): boolean {
        return this.formGroupApp.valid;
    }

    constructor(private readonly docSrv: DocumentBaseService, 
                private readonly modalService: NgbModal, 
                private readonly changeDetectorRef: ChangeDetectorRef,
            ) {
        super();
    }

    ngOnInit(): void {
        this.initialClientType = this.clientTypeNumber;
        this.enableTargetControls(String(this.initialClientType))
        
        this.controlValue$.next(this.field("tipKlienta").value);
        this.field("tipKlienta")
            .valueChanges.pipe(takeUntil(this.componentDestroyed$))
            .subscribe((value) => {
                if (value == 2 || value == 3) {
                    const controlSvedFLIP = this.formGroupApp.get('svedFLIP') as FormGroup;
                    controlSvedFLIP.removeControl('tipFLChastnaiaPraktika'); 
                } else {
                    const controlSvedFLIP = this.formGroupApp.get('svedFLIP') as FormGroup;
                    controlSvedFLIP.addControl('tipFLChastnaiaPraktika', new FormControl('', [Validators.required, Validators.max(1)])); 
                }                
                this.controlValue$.next(value);
                this.enableTargetControls(value)
            });

        this.removeControl();
        this.priznakKlientaItems = this.clientSelectItems.priznakKlienta;
    }

    removeControl(): void {
        const removeControls = [
            "priznValOperatsii",
            "uchastnik",
            "kodVal",
            "sumRub",
            "sumOperatsii",
            "kodPriznOperatsii",
            "kharakterOp",
        ];

        removeControls.forEach((control) => {
            this.formGroupApp.removeControl(control);
        });
        this.formGroupApp.updateValueAndValidity();
    }

    //Iul

    get svedIuLForm(): FormGroup {
        return this.formGroupApp?.get("svedIuL") as FormGroup;
    }

    get ogrniuLControl(): FormControl {
        return this.svedIuLForm?.get("ogrniuL") as FormControl;
    }

    //FLIP

    get svedFLIPForm(): FormGroup {
        return this.formGroupApp?.get("svedFLIP") as FormGroup;
    }

    //INBOIuL

    get svedKlientINBOIuLForm(): FormGroup {
        return this.formGroupApp?.get("svedINBOIuL") as FormGroup;
    }

    get uchreditelForm(): FormArray {
        return this.svedKlientINBOIuLForm?.get("uchreditel") as FormArray;
    }

    get regINBOIuLForm(): FormArray {
        return this.svedKlientINBOIuLForm?.get("regINBOIuL") as FormArray;
    }

    get clientTypeNumber(): number {
        return Number(this.field("tipKlienta").value);
    }

    private enableTargetControls(typeClient: string) {
        switch (typeClient) {
            case '1':
                this.svedIuLForm.enable();
                this.svedFLIPForm.disable();
                this.svedFLIPForm.disable();
                this.svedKlientINBOIuLForm.disable();
                break;
            case '5':
                this.svedIuLForm.disable();
                this.svedFLIPForm.disable();
                this.svedFLIPForm.disable();
                this.svedKlientINBOIuLForm.enable();
                break;
            case '0':
                this.svedIuLForm.disable();
                this.svedFLIPForm.disable();
                this.svedFLIPForm.disable();
                this.svedKlientINBOIuLForm.disable();
                break;
            default:
                this.svedIuLForm.disable();
                this.svedFLIPForm.enable();
                this.svedKlientINBOIuLForm.disable();
        }
    }

    beforeSaveCheck(context: TableEditorComponent): boolean {
        if (!this.initialClientType || this.initialClientType === this.clientTypeNumber) {
            return true;
        }

        const modal = this.modalService.open(ConfirmComponent, {
            windowClass: "top-modal-editor",
            backdropClass: "top-modal-editor",
        });
        modal.componentInstance.text = "Вы изменили «Тип клиента»! Все данные о предыдущем клиенте будут удалены!";
        modal.componentInstance.title = "Предупреждение";
        modal.componentInstance.okBtn = "Удалить";

        modal.result.then(
            (data) => this.submitChangeClientType(data, context),
            (cancel) => this.submitChangeClientType(undefined, cancel)
        );

        return false;

    }

    private submitChangeClientType(event, context?: TableEditorComponent) {
        if (event) {
            // Удаление данных при смене лица и сохранении формы
            switch (String(this.clientTypeNumber)) {
                case '1':
                    this.svedFLIPForm.reset();
                    this.svedKlientINBOIuLForm.reset();
                    break;
                case '5':
                    this.svedIuLForm.reset();
                    this.svedFLIPForm.reset();
                    break;
                case '0':
                    this.svedIuLForm.reset();
                    this.svedFLIPForm.reset();
                    this.svedKlientINBOIuLForm.reset();
                    break;
                default:
                    this.svedIuLForm.reset();
                    this.svedKlientINBOIuLForm.reset();
            }
            this.initialClientType = this.clientTypeNumber
            context.save();
        }
    }

    ngAfterViewChecked(): void {
        this.changeDetectorRef.detectChanges();
    }

    genIdentify(data) {
        this.field('nomerZapisi').setValue(data);
        this.field('nomerZapisi').markAsDirty();
      }
}
