import {Component} from "@angular/core";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {faSpinner} from "@fortawesome/free-solid-svg-icons/faSpinner";
import {Observable} from "rxjs";
import {first} from "rxjs/operators";
import {CommonService} from "../../../arm/documentForm/services/common.service";

@Component({
  selector: 'app-delete-window',
  templateUrl: './delete.component.html',
  styleUrls: ['./delete.component.scss']
})
export class DeleteComponent {

  block: (ids, group) => Observable<any>;
  unblock: (ids, group) => Observable<any>;
  listDelete: deleteList[] = [];
  deleteProgress = false;
  isDelete = false; // признак что подписывали документы
  stopDeleted = false;
  group: string;
  icons = {
    spinner: faSpinner
  }
  constructor(
    public modal: NgbActiveModal,

    protected commonSrv: CommonService) {
  }

  open(param: any) {
    this.listDelete = param.item;
    this.group = param.group || '';
    this.block = param.block || this.commonSrv.blockDocuments.bind(this.commonSrv);
    this.unblock = param.unblock || this.commonSrv.unBlockDocuments.bind(this.commonSrv);
  }

  /**
   * Назад
   */
  goBack() {
    if (this.deleteProgress) {
      return;
    }
    this.modal.close(this.isDelete);
  }

  stopDelete() {
    this.stopDeleted = true;
    this.deleteProgress = false;
  }

  getAllDeleteDocs(): deleteList[] {
    return this.listDelete.filter(i => !i.success);
  }

  deleteDocumentButton() {
    this.stopDeleted = false;
    let needDelete = this.getAllDeleteDocs();
    const needDeleteIds = needDelete.map(item => item.id);
    if (needDelete.length === 0) {
      return;
    }
    let _iterate = this.iteratorObject(needDelete);
    this.deleteProgress = true;
    const that = this;
    const step = (iterate) => {
      const next = iterate.next();
      if (!next.done) {
        deleteProgress(iterate, next.object);
      } else {
        that.deleteProgress = false;
      }
    }

    const deleteProgress = (iterate, item) => {
      if (this.stopDeleted) {
        return;
      }

      if (this.group === 'otkazgoz') {
        this.commonSrv.deleteDocuments(item.type, [item.id]).subscribe((result) => {
          item.success = true;
          item.process = false;
          item.error = null;
          step(iterate)
        }, error => {
          this.unblock([item.id], this.group).pipe(first()).subscribe(() => {})
          item.error = error;
          step(iterate)
        })
      } else {
        this.commonSrv.deleteDocument(item.type, item.id).subscribe((result) => {
          item.success = true;
          item.process = false;
          item.error = null;
          step(iterate)
        }, error => {
          this.unblock([item.id], this.group).pipe(first()).subscribe(() => {})
          item.error = error;
          step(iterate)
        })
      }

    }
    // блокируем при удалении
    this.block(needDeleteIds, this.group).subscribe((result) => {
      this.isDelete = true;
      deleteProgress(_iterate, _iterate.next().object)
    },
    error => {
      for (const item of needDelete) {
        let id;
        if (needDelete.length === 1 || (error.error).indexOf(item.id) !== -1) {
          id = item.id;
          needDelete = needDelete.filter(item=>item.id !== id);
          item.error = error?.error ?? 'Документ используется другим пользователем!';
        }
      }
      _iterate =  this.iteratorObject(needDelete);
      step(_iterate);
    })
  }

  iteratorObject(array) {
    let nextIndex = 0;
    return {
      next: function (): any {
        return nextIndex < array.length ?
          {object: array[nextIndex++], done: false} :
          {done: true};
      }
    }
  }
}

export type deleteList = {
  id: string;
  process?: boolean;
  success?: boolean;
  error?: any;
  date?: string;
  name: string;
}
