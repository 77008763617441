import {Component} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {ITableItem} from "@amlCore/components";
import {DocumentComponentBaseSub} from "@amlDocument/base";
import {DocumentBaseService} from "@amlDocument/services";

@Component({
  selector: 'app-arm-doc-view-legal-req-obstruction-fact',
  templateUrl: './view-legal-requirements-obstruction-fact.component.html',
  styleUrls: ['../../../base/documentForm.component.scss'],
})
export class ViewLegalRequirementsObstructionFactComponent extends DocumentComponentBaseSub implements ITableItem {
  formGroupApp: FormGroup;
  documentData: any;
  titlePanel = 'Требования законодательства РФ';
  currentGetForm = (param) => this.docSrv.getZakonodatelstvoRf(param);

  isValidItem(): boolean {
    return this.formGroupApp.valid;
  }

  constructor(private readonly docSrv: DocumentBaseService) {
      super();
  }
}
