import {Injectable} from "@angular/core";
import {DictionaryService, ResourceService} from "@amlCore/services";
import {AccessEnum} from "../../documentForm/enums";


@Injectable()
export class Strateg5392UFilterService {
  tryLoaded = {
    accessUserList: false,
  }
  dictionary = this.dictSrv;
  constructor(private dictSrv: DictionaryService,
              private resourceSrv: ResourceService) {
  }

  loadUserAccessList(): void {
    if (this.tryLoaded.accessUserList === false && this.dictSrv?.remoteDict?.strateg?.list?.length === 0) {
      const customParams = {sort: "fullName", dir: "asc", page: "0", size: "500"};
      this.resourceSrv.getUserList(customParams).subscribe(data => {
        this.dictSrv.remoteDict.strateg.list = data.content;
      })
    }
    this.tryLoaded.accessUserList = true;
  }
}
