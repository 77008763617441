import {Component, OnDestroy} from "@angular/core";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {CryptographService} from "../../../cryptography";
import {Observable, of, Subject} from "rxjs";
import {faSpinner} from "@fortawesome/free-solid-svg-icons/faSpinner";
import {SignService} from "../sign/sign.service";
import {Utils} from "@amlCore/utils";
import {FileSaverService} from "ngx-filesaver";
import {catchError, takeUntil} from "rxjs/operators";
import {MoveToSendResponse} from "../../../arm/documentForm/model";

@Component({
  selector: 'app-sign-elMsg-window',
  templateUrl: './sign-elMsg.component.html',
  styleUrls: ['./sign-elMsg.component.scss']
})
export class SignElMsgComponent implements OnDestroy {

  listSign: signList[] = [];
  notCheckedList: signList[] = [];
  newArr = [];
  getHash: (id, type, oid) => Observable<any>;
  sendSign: (id, type, signature) => Observable<any>;
  sign: (id, type) => Observable<any>;
  block: (ids, type) => Observable<void>;
  unblock: (ids, type) => Observable<void>;
  moveToSend: (section, ids) => Observable<MoveToSendResponse>;
  signProgress = false;
  isSign = false; // признак что подписывали документы
  stopSigned = false;
  docsResult: any;
  sectionType = ''
  needCert = true
  moveToSendError = false
  
  private stopSign$ = new Subject<boolean>()
  icons = {
    spinner: faSpinner
  }
  constructor(public modal: NgbActiveModal, 
              private cryptographService: CryptographService, 
              private signSrv: SignService,
              private fSaver: FileSaverService) {
  }


  open(param: any) {
    param.item.forEach(element => {
      element.checked === true ?
          this.listSign.push(element) :
          this.notCheckedList.push(element);
    })
    this.sendSign = param.sendSign;
    this.sign = param.sign;
    this.getHash = param.getHash;
    this.sectionType = param.sectionType
    this.block = param.block || (() => of({}));
    this.unblock = param.unblock || (() => of({}));
    this.moveToSend = param.moveToSend || (() => of({}));
    this.needCert = param.needCert
    this.newArr = this.newArrFunc(this.listSign);
  }

  /**
   * Назад
   */
  goBack() {
    if (this.signProgress || this.existLocked()) {
      return;
    }
    this.modal.close(this.isSign);
  }

  stopSign(): void {
    this.stopSigned = true;
    this.signProgress = false;
    this.stopSign$.next(true)
  }

  getAllSignDocs(): signList[] {
    return this.listSign.filter(i => i.process && !i.lock);
  }

  getIdsSignDocs(): string[] {
    return Array.from(new Set( this.listSign.map(item => item.id)))
  }

  existLocked(): boolean {
    return this.listSign.filter(i => i.lock).length > 0;
  }

  signDocument() {
    this.stopSigned = false;
    const needSign = this.getAllSignDocs();
    if (needSign.length === 0) {
      return;
    }
    const cert = this.cryptographService.crypt.getSelectedCert();
    if (!cert && this.needCert) {
      needSign.forEach(doc => {doc.error = this.errorCatchCert(null) })
      return;
    }
    this.needCert ? this.signWithCert(needSign, cert) : this.moveToSendsReady()
  }

  errorCatchCert = (error) => {
    let result;
    let code: string;
    if (error?.message?.indexOf('0x8010006E') > 0) {
      result = `Отсутствует закрытый ключ`
      code = `0x8010006E`;
    } else if (error?.message?.indexOf('0x000004C7') > 0) {
      result = `Операцию с ключами или сертификатами была отменена пользователем`
      code = `0x000004C7`;
    } else {
      result = `В профиле пользователя не назначен сертификат ключа проверки электронной подписи, или выбранный сертификат не валиден!`
      code = null;
    }
    return {message: result, code}
  }

  iteratorObject(array) {
    let nextIndex = 0;
    return {
      next: function (): any {
        return nextIndex < array.length ?
          {object: array[nextIndex++], done: false} :
          {done: true};
      }
    }
  }

  newArrFunc(arr: signList[]): any[] {
    const newArr = Array.from(new Set( arr.map(item => item.type)))
    let arr2 = [];
    let arr3 = [];

    for (let item1 of newArr) {
      arr2 = [];
      for (let item of arr ) {
        if (item.type === item1) {
          arr2.push(item)
        }
      }
      arr3.push(arr2)
    }
    return arr3;
  }

  /**
   * Запрос информации по подписи
   */
  checkSignState(): void {
    this.signSrv.signDocumentsInfo(this.sectionType).pipe().subscribe((data) => {
          this.docsResult = Object.keys(data?.signDocs).length !== 0 ? data : null;
        },
        err => {
        });
  }

  /**
   * Экспорт результатов подписи
   */
  exportSignResults(): void {
    this.signSrv.downloadSignResults(this.sectionType).subscribe((value) => {
          if (value.type === 4) {
            this.fSaver.save(value.body, Utils.getFileNameFromContentDisposition(value));
          }
        }
    );
  }

  checkErrorMessage(errorText: string): boolean {
    return errorText !== 'В профиле пользователя не назначен сертификат ключа проверки электронной подписи, или выбранный сертификат не валиден!';
  }

  /**
   * Экспорт результата подписи для конкретного файла
   */
  downloadFileImportResult(docName: string): void{
    this.signSrv.downloadSignResults(this.sectionType,docName).subscribe((value) => {
          if (value.type === 4) {
            this.fSaver.save(value.body, Utils.getFileNameFromContentDisposition(value));
          }
        }
    );
  }
  
  signWithCert(needSign, cert) {
    const _iterate = this.iteratorObject(needSign);
    const oid = this.cryptographService.crypt.getAlgorithmCert();
    this.signProgress = true;
    const that = this;
    const step = (iterate) => {
      const next = iterate.next();
      if (!next.done) {
        blockAndSign(iterate, next.object);
      } else {
        that.signProgress = false;
        this.checkSignState()
      }
    }
    this.isSign = true;
    const blockAndSign = (iterate, item) => {
      this.block([item.id], this.sectionType).pipe(
          catchError(err => {
            item.process = false;
            item.error = err.error
            item.lock = false
            step(iterate)
            return of()
          })
      ).subscribe(() => {
        if (this.stopSigned || item.lock) {
          return;
        }
        item.lock = true;
        that.getHash(item.id, item.type, oid).subscribe((data) => {
          that.cryptographService.crypt.generateSignature(Object.entries(JSON.parse(data))[0][1], cert).then(signature => {
            that.sendSign(item.id, item.type, signature).subscribe((result) => {
              item.signature = data;
              item.success = result;
              item.process = false;
              item.error = item.success === true ? null : 'Возникла ошибка! Документ не подписан!';
              item.lock = false;
              step(iterate)
            }, error => {
              item.error = error ? error : 'Возникла ошибка';
              item.lock = false;
              item.process = false;
              step(iterate)
            })

            // разблокировка подписываемого документа после успешной отправки на подпись
            that.unblock([item.id], this.sectionType).subscribe((result) => {
            });

          }, error => {
            item.error = this.errorCatchCert(error);
            item.lock = false;
            item.process = false;
            step(iterate)
            // разблокировка подписываемого документа после ошибки получения generateSignature
            that.unblock([item.id], this.sectionType).subscribe((result) => {
            });
          })
        }, error => {
          item.error = error;
          item.lock = false;
          item.process = false;
          step(iterate);
          // разблокировка подписываемого документа после ошибки получения хеша
          that.unblock([item.id], this.sectionType).subscribe((result) => {
          });
        })
      })
    }

    blockAndSign(_iterate, _iterate.next().object)
  }

  moveToSendsReady() {
    const docIds = this.getIdsSignDocs()
    this.block(docIds, this.sectionType).subscribe(() => {
      this.signProgress = true;
      this.moveToSendError = false
      this.isSign = true;
      this.moveToSend(this.sectionType, docIds).pipe(
          catchError(() => {
            this.signProgress = false
            this.stopSigned = true
            this.moveToSendError = true
            return of()
          }),
          takeUntil(this.stopSign$)
      ).subscribe((responseData: MoveToSendResponse) => {
        const response = responseData.response
        this.signProgress = false
        this.listSign = this.listSign.map(listItem => {
          if (response[listItem.id]) {
            response[listItem.id].message === 'success' ? listItem.success = true : 
                response[listItem.id].message === 'error' ? listItem.error = true : null
          }
          listItem.process = false
          return listItem
        })
        this.unblock(docIds, this.sectionType).subscribe()
      })
    })
  }

  checkBlockError(errorText: string = ''): boolean {
    return !errorText?.includes('Документ открыт другим пользователем')
  }

  ngOnDestroy(): void {
    this.signSrv.signDocumentsEnd(this.sectionType);
  }

}

type signList = {
  id: string;
  process?: boolean;
  lock?: boolean;
  success?: boolean;
  error?: any;
  date?: string;
  name: string;
  type?: string;
  signSignature?: boolean;
}
