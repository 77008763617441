import {Component, OnInit, forwardRef} from "@angular/core";
import {BaseFilterController} from "../BaseFilterController";
import {FormBuilder, NG_VALUE_ACCESSOR} from "@angular/forms";
import {DictionaryService, StringDateAdapterService, TableService} from "@amlCore/services";
import {ActivatedRoute} from "@angular/router";
import {CommonArchiveFilter} from "../../models/commonArchiveFilter.model";
import {DocTypeSearch, getDocTypes} from "../../constants";

@Component({
    selector: "app-control6670-common-list-filter",
    templateUrl: "./commonControl6670Filter.component.html",
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CommonControl6670FilterComponent),
            multi: true,
        },
    ],
})

export class CommonControl6670FilterComponent extends BaseFilterController implements OnInit {
    public filterObjectModel = new CommonArchiveFilter();
    placeholder = 'Поиск по идентификатору документа, по имени xml-документа, по идентификатору записи';
    docTypes: DocTypeSearch[];
    constructor(private readonly fb: FormBuilder,
                protected tableSrv: TableService,
                private dictSrv: DictionaryService,
                public dateSrv: StringDateAdapterService,
                protected readonly activatedRoute: ActivatedRoute) {
        super(tableSrv, activatedRoute);
    }

    ngOnInit() {
        this.docTypes = getDocTypes('control6670');
        this.init();
    }

    /**
     * Метод создания формы
     * @return FormGroup
     */
    public createFormModel() {
        const formConfig = new CommonArchiveFilter();

        return this.fb.group(formConfig);
    }

    getStartFilterModel(): CommonArchiveFilter {
        return {
            documentType: null,
            documentStatus: null
        } as CommonArchiveFilter;
    }

    /**
     * Описания полей
     */
    public getFilterDescription(): any {
        return {
            documentType: 'Тип документа',
            documentStatus: 'Статус',
            dateFrom: 'Дата с',
            dateTo: 'Дата по',
            creatorId: 'Владелец',
            createDateFrom: 'Дата импорта с',
            createDateTo: "Дата импорта по"
        };
    }
    get users() {
        return this.dictSrv?.remoteDict?.control6670?.list || [];
    }
}
