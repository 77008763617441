// Предписанные поля для 407-Положение
import {FormArray, FormGroup} from "@angular/forms";

export const prescribedFields_P407_ANSWER = [
    //Вкладка Информационная часть
    "infChast.svedIuL.adrIuL.region",
    "infChast.svedIuL.inNaimIuL",
    "infChast.svedIuL.sokrNaimIuL",
    "infChast.svedIuL.adrIuL.indeks",
    "infChast.svedIuL.adrIuL.dom",
    "infChast.svedIuL.adrIuL.kodSubieektaPoOKATO",
    "infChast.svedIuL.adrIuL.ulitsa",
    "infChast.svedIuL.adrIuL.korp",
    "infChast.svedIuL.adrIuL.of",
    "infChast.svedIuL.adrIuL.region",
    //Вкладка Сведения об адресах ФЛ/ЮЛ/ИП
    "infChast.razdel1.adrReg.region",
    "infChast.razdel1.adrReg.indeks",
    "infChast.razdel1.adrReg.kodSubieektaPoOKATO",
    "infChast.razdel1.adrReg.ulitsa",
    "infChast.razdel1.adrReg.dom",
    "infChast.razdel1.adrReg.korp",
    "infChast.razdel1.adrReg.of",
    //Вкладка Сведения о бенефициарном владельце
    "infChast.razdel4.mestoRozhdFL",
    "infChast.razdel5.razdel51.svedBenefits.innfl",
    "infChast.razdel5.razdel51.svedBenefits.mestoRozhdFL.kodSubieektaPoOKATO",
    "infChast.razdel5.razdel51.benefitsAdrReg.region",
    "infChast.razdel5.razdel51.svedBenefits.mestoRozhdFL.region",
  ]

export const getPrescribedFieldsStrateg5392 = (documentForm: FormGroup | FormArray): string[] => {
    const prescribedFields = [];
    const addAddressFields = (defaultString: string): void => {
        prescribedFields.push(`${defaultString}.dom`);
        prescribedFields.push(`${defaultString}.indeks`);
        prescribedFields.push(`${defaultString}.kodSubieektaPoOKATO`);
        prescribedFields.push(`${defaultString}.adrVladelets.korp`);
        prescribedFields.push(`${defaultString}.adrVladelets.of`);
        prescribedFields.push(`${defaultString}.adrVladelets.rajon`);
        prescribedFields.push(`${defaultString}.adrVladelets.ulitsa`);
    };

    const vladeletsFileds = documentForm.get('razdel2.vladelets') as FormArray;
    vladeletsFileds.value.forEach((vladeletsItem, index) => {
        addAddressFields(`razdel2.vladelets.${index}.adrVladelets`);
        addAddressFields(`razdel2.vladelets.${index}.adrVladeletsInoj`);
        const svedeniia = documentForm.get(`razdel2.vladelets.${index}.razdel3.svedeniia`) as FormArray;
        svedeniia.value.forEach((svedeniiaItem, indexSved) => {
            addAddressFields(`razdel2.vladelets.${index}.razdel3.svedeniia.${indexSved}.adrBankPoluchatel`);
            addAddressFields(`razdel2.vladelets.${index}.razdel3.svedeniia.${indexSved}.adrPoluchatel`);
            addAddressFields(`razdel2.vladelets.${index}.razdel3.svedeniia.${indexSved}.adrPoluchatelInoj`);
        });
    });
    return prescribedFields;
};
