import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { FormArray, Validators } from "@angular/forms";
import { DocumentBaseService } from "../../services";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { DictionariesService } from "src/app/dictionaries/service/dictionaries.service";
import { ICodeName } from "src/app/dictionaries";

@Component({
  selector: "app-arm-doc-multiple-sved-drag-metal",
  template: `
    <h5 class="typography-h5 typography-blue mt-3">
      Сведения о драгоценных металлах, драгоценных камнях, ювелирных изделиях и
      ломе из таких изделий
    </h5>
    <div class="form-group col-xl-6 pl-0 pr-0">
      <app-multiple-field-description
        [dictionary]="dictionary"
        [showLabel]="true"
        [submitted]="submitted"
        [placeholderMap]="placeholderStuffMap"
        [formArray]="formGroupApp"
        [createFormGroup]="docSrv.getSvedDragMetall"
        fieldNameDescription="naimDragMetall"
        fieldNameValue="kodDragMetall"
        maxlengthDescription="250"
      ></app-multiple-field-description>
    </div>
  `,
  styleUrls: ["../../base/documentForm.component.scss"],
})
export class MultipleSvedDragMetalComponent implements OnInit,  OnDestroy{
  @Input() readonly formGroupApp: FormArray;
  @Input() readonly documentData: any;
  @Input() readonly submitted: any;
  placeholderStuffMap = new Map([
    ["kodDragMetall", "Код"],
    ["naimDragMetall", "Наименование"],
  ]);
  private $destroy = new Subject();

  getDictionary = this.dictSrv.getQrefDmet4936List();
  dictionary: ICodeName[];
  constructor(readonly docSrv: DocumentBaseService, private dictSrv: DictionariesService) {}

  ngOnInit(): void {

    this.getDictionary.pipe(takeUntil(this.$destroy)).subscribe(data => {
      this.dictionary = data.content;
    });

    this.formGroupApp.valueChanges
        .pipe(takeUntil(this.$destroy))
        .subscribe((value) =>
            value.forEach((control, index) => {
              const currentGroupNaim =
                  this.formGroupApp.controls[index].get("naimDragMetall");
              const currentGroupKod =
                  this.formGroupApp.controls[index].get("kodDragMetall");

              currentGroupNaim?.value
                  ? currentGroupKod.setValidators([Validators.minLength(3), Validators.maxLength(3), Validators.required])
                  : (currentGroupKod.clearValidators(),
                      currentGroupKod.setErrors(null));
            })
        );
  }


  ngOnDestroy() {
    this.$destroy.next();
    this.$destroy.complete();
  }
}
