import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ITable} from "@amlCore/components";
import {ViewClientInfoComponent} from "./view-client-info.component";
import {DocumentSubForm} from "@amlDocument/base";
import {DocumentBaseService} from "@amlDocument/services";
import {DocumentTypeEnum} from "../../../enums";


@Component({
  selector: 'app-arm-doc-table-client-info',
  templateUrl: './table-client-info.component.html',
  styleUrls: ["../../../base/documentForm.component.scss"],
})
export class TableClientInfoComponent extends DocumentSubForm implements OnInit, OnChanges, ITable {
  @Input() readonly documentType: DocumentTypeEnum;
  viewForm = ViewClientInfoComponent;

  constructor(
    protected readonly modalService: NgbModal,
    private readonly docSrv: DocumentBaseService
  ) {
    super(modalService);
  }

  currentGetFormGroup = (data) => this.isSuspiciousActivity
      ? this.docSrv.getKlientSa(data)
      : this.docSrv.getUchastnikOpFm(data)
  childGetFormGroup = (data) => this.docSrv.getUchastnik(data);

  ngOnInit() {
    super.ngOnInit();
  }

  ngOnChanges(change: SimpleChanges) {
    super.ngOnChanges(change);
  }

  get isDopUchastniki() {
    return this.documentType === DocumentTypeEnum.OPERATIONS
      || this.documentType === DocumentTypeEnum.NFO_OPERATIONS 
      || this.documentType === DocumentTypeEnum.SUSPICIOUS_ACTIVITY;
  }

  get isSuspiciousActivity() {
    return this.documentType === DocumentTypeEnum.SUSPICIOUS_ACTIVITY;
  }
}
