import { Injectable } from '@angular/core';
import {ApiService} from "./api.service";
import {FileSaverService} from "ngx-filesaver";
import {Utils} from "@amlCore/utils";

@Injectable({
  providedIn: 'root'
})
export class SaveFileService {

  constructor(private api: ApiService, private fileSaver: FileSaverService) { }

  /**
   * Сохранение файлов через fileSaver для невидимых элементов, для ссылок <a /a> использовать директиву appSaveFile
   */
  saveFiles(fileUrl: string, fileName?: string): void {
    this.api.byteGet(fileUrl).subscribe(data => {
      this.fileSaver.save(data.body, fileName || Utils.getFileNameFromContentDisposition(data))
    })
  }
}
