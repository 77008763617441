import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ConfirmDeactivateGuard, NoAuthGuard} from './services';
import {ClientComponent} from "./client.component";
import {typePageEnum} from '@amlCore/enums';
import {AccessRouteGuard} from '@amlCore/services';
import {ProfileComponent} from "./components/profile";

const childRoutes: Routes = [
  {
    path: '',
    component: ClientComponent
  },
  {
    path: '',
    loadChildren: () => import('../registries/registries.module').then(m => m.RegistriesModule)
  },
  {
    path: 'fm',
    loadChildren: () => import('../arm/fm/fm.module').then(m => m.FmModule)
  },
  {
    path: 'svetofor',
    loadChildren: () => import('../arm/svetofor/svetofor.module').then(m => m.SvetoforModule)
  },
  {
    path: 'rejection',
    loadChildren: () => import('../arm/rejection/rejection.module').then(m => m.RejectionModule)
  },
  {
    path: 'requestsga',
    loadChildren: () => import('../arm/requestsga/requestsga.module').then(m => m.RequestsgaModule)
  },
  {
    path: 'elMsgFm',
    loadChildren: () => import('../arm/elMsgFm/elMsgFm.module').then(m => m.elMsgFmModule)
  },
  {
    path: 'arm4937',
    loadChildren: () => import('../arm/arm4937/arm4937.module').then(m => m.arm4937Module)
  },
  {
    path: 'strateg5392u',
    loadChildren: () => import('../arm/strateg5392U/strateg5392u.module').then(m => m.Strateg5392UModule)
  },
  {
    path: 'strateg655p',
    loadChildren: () => import('../arm/strateg655p/strateg655p.module').then(m => m.Strateg655PModule)
  },
  {
    path: 'control6670',
    loadChildren: () => import('../arm/control6670/control6670.module').then(m => m.Control6670Module)
  }
];

const routes: Routes = [
  {
    path: 'registries',
    loadChildren: () => import('../registries/registries.module').then(m => m.RegistriesModule)
  },
  {
    path: 'cabinet',
    component: ClientComponent,
    canActivate: [NoAuthGuard]
  },
  {
    path: 'cabinet',
    component: ClientComponent,
    children: childRoutes
  },
  {
    path: 'fm',
    loadChildren: () => import('../arm/fm/fm.module').then(m => m.FmModule)
  },
  {
    path: 'svetofor',
    loadChildren: () => import('../arm/svetofor/svetofor.module').then(m => m.SvetoforModule)
  },
  {
    path: 'rejection',
    loadChildren: () => import('../arm/rejection/rejection.module').then(m => m.RejectionModule)
  },
  {
    path: 'requestsga',
    loadChildren: () => import('../arm/requestsga/requestsga.module').then(m => m.RequestsgaModule)
  },
  {
    path: 'elMsgFm',
    loadChildren: () => import('../arm/elMsgFm/elMsgFm.module').then(m => m.elMsgFmModule)
  },
  {
    path: 'arm4937',
    loadChildren: () => import('../arm/arm4937/arm4937.module').then(m => m.arm4937Module)
  },
  {
    path: 'strateg5392u',
    loadChildren: () => import('../arm/strateg5392U/strateg5392u.module').then(m => m.Strateg5392UModule)
  },
  {
    path: 'strateg655p',
    loadChildren: () => import('../arm/strateg655p/strateg655p.module').then(m => m.Strateg655PModule)
  },
  {
    path: 'control6670',
    loadChildren: () => import('../arm/control6670/control6670.module').then(m => m.Control6670Module)
  },
  {
    path: 'profile',
    component: ProfileComponent,
    data: {access: 'cryptography'},
    canActivate: [AccessRouteGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
  onSameUrlNavigation: 'reload',
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class ClientRoutingModule {
}
