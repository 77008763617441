import {Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ImportDocumentsService} from "./importDocuments.service";
import {ImportDocsResponseModel} from "../../model/ImportDocsResponseModel";
import {ApiService, IconsService} from "@amlCore/services";
import {Utils} from "@amlCore/utils";
import {FileSaverService} from "ngx-filesaver";
import {HttpParams} from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import {of} from 'rxjs';
import {InputFileComponent} from "../../../../core/liteComponent";
import {AlertPanelComponent, AlertPanelService} from "@amlCore/components";

@Component({
  selector: 'app-import-document',
  templateUrl: './importDocuments.component.html',
  styleUrls: ['./importDocuments.component.scss']
})
export class ImportDocumentsComponent implements OnInit, OnDestroy {
  @Input() url='/api/v1/documents/import';
  @Input() url_start='/api/v1/documents/import/start';
  @Input() url_info='/api/v1/documents/import/info';
  @Input() url_download='/api/v1/documents/import/download';
  @Input() url_end='/api/v1/documents/import/end';
  @Input() typeFileToImport = 'ФЭС';
  @Input() nominativeTypeFileToImport = 'ФЭС';
  @Input() group_type?;
  @Input() maxSizeFile = 0;

  @ViewChild("alertObjSave") alertObjToSave: AlertPanelComponent;
  @ViewChild('inputFile') inputFile: InputFileComponent;

  loading = false;
  accept: string = '.xml';
  importedData: []=[];
  importPercent:number = 0;
  icons = this._iconsService.getIcons();
  docsResult: ImportDocsResponseModel;
  orgInn = sessionStorage.getItem('orgInn');
  overSizeFilesArr: any = [];
  upploadingFiles = false;
  filesCount = 0;
  importFiles: File[] = []; // импортируемые файлы
  needApprovDocs = [];
  needApprovAttachments = [];
  needFillPrescribed = [];
  queryParams: HttpParams = new HttpParams();
  noImportFlag = false;
  loaded = false;
  vidInf407Attachment: string = null;
  isInnInQuerryArray = ['otkazgoz', 'p407', 'strateg'];

  @ViewChild('needFillPrescribedModal') fillPrescribedTemplateRef: TemplateRef<any>;

  constructor(private _activeModal: NgbActiveModal,
              private _iconsService: IconsService,
              protected _importDocumentsSrv: ImportDocumentsService,
              protected fSaver: FileSaverService,
              private api: ApiService,
              private readonly modalService: NgbModal,
              protected alertPanelSrv: AlertPanelService) {
  }

  ngOnInit(): void {
    if (this.group_type && this.isInnInQuerryArray.indexOf(this.group_type) === -1) {
      this.url += `?inn=${this.orgInn}`;
    }
  }

  onClose(): void {
    this._activeModal.close(this.importedData);
    this.loading = false;
  }

  /**
   * Запрос информации по импорту
   */
  checkImportState(import_url_info?: string): void {
    if(!this.loading) {
      return;
    }
    this._importDocumentsSrv.importDocumentsInfo(import_url_info)
    .pipe(catchError(err => of({}))).subscribe((data) => {
      if (data.needApprovDocs) {
        this.needApprovDocs = data.needApprovDocs;
      }
      if (data.needApprovAttachments) {
        this.needApprovAttachments = data.needApprovAttachments;
      }
      if (data.needFillPrescribed) {
        this.needFillPrescribed = data.needFillPrescribed;
        this.openFillPrescribedModal();
      }
      this.noImportFlag = false;
      this.docsResult = data;
      this.importPercent = data.percent;
      // убираем из массива неимпортированных документов те, которые у которых нет предписанных полей
      this.docsResult.notImported = this.docsResult?.notImported?.filter(
          (notImportedElement) => !this.needFillPrescribed?.includes(notImportedElement));
      // если импорт завершен (completed=true)
      if(data.completed){
        this.loading = false;
        this.upploadingFiles = false;
        this.loaded = true

      } else if(this.loading){
        setTimeout(() => {
          this.checkImportState(import_url_info);
        }, 3000);
      }
    },
    (err) => {
      this.loading = false;
      console.log(err)
    });
  }

  startImport(repeatSend: boolean): void {
    if (this.importFiles.length > 0) {

      this._importDocumentsSrv.importDocumentsStart(this.url_start, this.importFiles.map((file) => file.name))
        .subscribe(() => {
          this.inputFile.doSend(this.importFiles, this.overSizeFilesArr, repeatSend);
          // проверка информации по отправке
          setTimeout(() => {
            this.checkImportState(this.url_info);
          }, 1000);
        });
    }
  }

  /**
   * Обработчик начала импорта
   */
  onSendStart(files: File[]): void {
    this.loaded = false;
    this.loading = true;
    this.filesCount = files.length;
    if (files.length > 0) {
      for (const item of files) {
        this.importFiles.push(item);
      }
    } else {
      //заканчиваем импорт
      setTimeout(() => {
        this.upploadingFiles = false;
        this.loading = false;
        if (!this.docsResult) {
          this.docsResult = this.overSizeFilesArr;
          this.docsResult.imported = 0;
          this.docsResult.completed = true;
          this.docsResult.size = 0;
        }
      }, 1000);
    }
  }

  /**
   * Обработчик конца импорта
   */
  onFinishImport(event?: []): void {
    if (event) {
      this.importedData.push(...event);
      if (event.length !== this.overSizeFilesArr.length) {
        this.checkImportState(this.url_info);
      }
    }
  }

  /**
   * Получаем файлы првышающие размер
   */
  overSizeFiles(files) {
    this.overSizeFilesArr = [];
    this.overSizeFilesArr = files;
  }

  ngOnDestroy(): void {
    this.loading = false;
    this._importDocumentsSrv.importDocumentsEnd(this.url_end);
  }

  /**
   * Экспорт результатов импорта
   */
  exportImportResults(): void {
    this._importDocumentsSrv.downloadImportResults(this.url_download).subscribe((value) => {
        if (value.type === 4) {
          this.fSaver.save(value.body, Utils.getFileNameFromContentDisposition(value));
        }
      }
    );
  }

  /**
   * Экспорт результата импорта для конкретного файла
   */
  downloadFileImportResult(docName: string): void{
    this._importDocumentsSrv.downloadImportResults(this.url_download, docName).subscribe((value) => {
        if (value.type === 4) {
          this.fSaver.save(value.body, Utils.getFileNameFromContentDisposition(value));
        }
      }
    );
  }

  /**
   * Нажатие "Выбрать файлы для импорта"
   * обнуляем результаты предыдущего импорта
   */
  getFiles() {
    this.alertObjToSave.close();
    this.overSizeFilesArr = [];
    this.upploadingFiles = true;
    this.importPercent = 0;
    this.filesCount = 0;
    this.importFiles = [];
    if (this.docsResult) {
      this.docsResult = null;
    }
  }

  /**
   * Кнопка ДА (импортировать с параметром force=true)
   */
  onForceImport() {
    const formData = this.getForceImportFiles();
    this.loading = true;
    this.loaded = false
    this.api.sendFormData(this.url + '?force=true', formData, this.queryParams).subscribe(() => {
      this.needApprovDocs = [];
      this.needApprovAttachments = [];
      this.needFillPrescribed = [];
      this.noImportFlag = false;
      this.checkImportState(this.url_info);
    });
  }
  /**
   * Кнопка Нет
   */
  noImport() {
    this.noImportFlag = true;
  }
  /**
  * Получение файлов которые нужно с параметром force=true импортировать
  * @returns
  */
  getForceImportFiles(): FormData {
    let arr = [];
    this.needApprovDocs.forEach(element => {
      arr = [...arr,...this.importFiles.filter(item => item.name === element)];
    });
    this.needApprovAttachments.forEach(element => {
      arr = [...arr,...this.importFiles.filter(item => item.name === element)];
    });
    const formData: FormData = new FormData();
    for (const item of arr) {
      formData.append('files', item);
    }
    return formData;
  }

  /**
   * Получение файлов, которые нужно импортировать с параметром fillPrescribed=true
   * @returns
   */
  getFillPrescribedFiles(): FormData {
    let arr = [];
    this.needFillPrescribed.forEach(element => {
      arr = [...arr,...this.importFiles.filter(item => item.name === element)];
    });
    const formData: FormData = new FormData();
    for (const item of arr) {
      formData.append('files', item);
    }
    return formData;
  }

  private openFillPrescribedModal() {
    this.modalService.open(this.fillPrescribedTemplateRef, { ariaLabelledBy: 'modal-basic-title' }).result.then(
      () => this.onFillPrescribed(),
      () => this.noImport()
    );
  }

  /**
   * Импортировать с параметром fillPrescribed=true
   */
  onFillPrescribed() {
    const formData = this.getFillPrescribedFiles();
    this.loading = true;
    this.api.sendFormData(this.url + '?fillPrescribed=true', formData, this.queryParams).subscribe(() => {
      this.needApprovDocs = [];
      this.needApprovAttachments = [];
      this.needFillPrescribed = [];
      this.noImportFlag = false;
      this.checkImportState(this.url_info);
    });
  }

  onError(error): void{
    this.alertObjToSave.open(this.alertPanelSrv.getErrorMsg(error));
  }
}
