import {Injectable} from "@angular/core";
import {ApiService, UserService} from "@amlCore/services";
import {BehaviorSubject, Observable, Subject} from "rxjs";
import {IDataResponse} from "@amlCore/interfaces";
import {FmList} from "../model/FmList.model";
import {ReceiveDocResponseModel} from "../components";
import {DocumentConfig, DocumentTypeEnum, getDocumentConfig} from "../../documentForm/enums/documentTypeEnum";
import moment from "moment";
import { FormGroup } from "@angular/forms";


@Injectable({ providedIn: "root" })
export class FmService {
  public closeRecieveModal = new Subject();
  private _docIds: string[] = [];
  constructor(private api: ApiService,
  protected userSrv: UserService) {}
  versiiaFormata = "2.0";
  versProg = "ARM FM";
  docTypes = [
    {id: 0, name: 'OPERATIONS', title: '5861-У. Сведения об операциях'},
    {id: 1, name: 'OBSTRUCTION_FACTS', title: '5861-У. ФЭС о фактах воспрепятствования'},
    {id: 2, name: 'SUSPICIOUS_ACTIVITY', title: '5861-У. ФЭС о подозрительной деятельности'},
    {id: 3, name: 'REFUSE_CONTRACT', title: '5861-У. Отказ от заключения договора (расторжение)'},
    {id: 4, name: 'REFUSE_OPERATION', title: '5861-У. Отказ в совершении операции'},
    {id: 5, name: 'VERIFICATION_RESULT', title: '5861-У. Сведения о результатах проверки'},
    {id: 6, name: 'FREEZING_FUNDS', title: '5861-У. Сведения о принятых мерах по замораживанию (блокированию)'}
  ];

  private documentSubject = new BehaviorSubject(null);

  setDocument(document) {
    this.documentSubject.next(document);
  }

  getDocument() {
    return this.documentSubject.value;
  }

  /**
   * получить список документов в папке Черновики
   */
  getFMList(params: object): Observable<IDataResponse<FmList>> {
    return this.api.post(`/api/v1/documents/draft/`, params);
  }
  getFMWaitList(params: object): Observable<IDataResponse<FmList>> {
    return this.api.post(`/api/v1/documents/wait/`, params);
  }
  getFMDeletedList(params: object): Observable<IDataResponse<FmList>> {
    return this.api.post(`/api/v1/documents/deleted/`, params);
  }
  getFMSendList(params: object): Observable<IDataResponse<FmList>> {
    if (params.hasOwnProperty('documentStatus')) {
      params['documentStatus'] = [params['documentStatus']]
    }
    return this.api.post(`/api/v1/documents/sended/`, params);
  }
  getFMIncomeList(params: object): Observable<IDataResponse<FmList>> {
    return this.api.post(`/api/v1/documents/income/`, params);
  }
  // получить данные по документу
  getDocInfoById(id, type) {
    const config = this.getConfigDoc(type)
    return this.api.get(config.api + id);
  }

  // получить данные по таблице документа (опционально если документ такое требует)
  getDocumentTable(id, type): Observable<any> {
    const config = this.getConfigDoc(type)
    return this.api.get(config.apiTable + id);
  }

  // получить конфигурацию по документу, его урл на фронте и какие ендпоинты у него есть для сервера
  getConfigDoc(type): any {
    return DocumentConfig[type] || {};
  }

  getHash(id, type, oid) {
    return this.api.get('/api/v1/documents/sign/dateForSign', {documentIds: id, certOid: oid}, null, 'text');
  }

  sendSign(id, type, signature) {
    return this.api.post(`api/v1/documents/sign/${id}`, {signature});
  }

  /**
   * Удалить документ
   */
  deleteDocument(documentType: string, documentId: string): Observable<any> {
    const typeConf = this.getConfigDoc(documentType);
    if (documentType == 'OES_OTKAZ_GOZ') {
      return this.api.delete(`${typeConf.api}${documentId}`);
    }
    return this.api.delete(`/api/v1/documents/${typeConf.serviceName}/${documentId}`);
  }

  deleteDocuments(documentType: string, documentIds: Array<String>): Observable<any> {
    const typeConf = this.getConfigDoc(documentType);
    return this.api.deleteArray(`/api/v1/documents/${typeConf.serviceName}/`, documentIds);
  }

  /**
   * Переместить в Удаленные
   * @deprecated метод перенесён в documentsForm
   */
  moveToDeleted(documentIds: Array<String>): Observable<any> {
    return this.api.post(`/api/v1/documents/moveToDeleted`,  documentIds);
  }

  /**
   * Переместить из Удаленных из Черновики
   * @deprecated метод перенесён в documentsForm
   */
  deletedToDrafts(documentIds: Array<String>): Observable<any> {
    return this.api.post(`/api/v1/documents/deleted/moveToDraft`, documentIds);
  }

  /**
   * Сохранить документ
   */
  saveDocument(documentType: string, documentId: string, needAttention: boolean, document): Observable<any> {
    const typeConf = this.getConfigDoc(documentType);
    return this.api.post(`${typeConf.api}edit/${documentId}${needAttention?'/'+needAttention:''}`, document);
  }

  /**
   * Создать документ
   */
  createDocument(documentType: string, needAttention: boolean, document): Observable<any> {
    const typeConf = this.getConfigDoc(documentType);
    return this.api.post(`${typeConf.api}edit/new${needAttention?'/'+needAttention:''}`, document);
  }


  sendDocuments(ids: string[], sectionType = ''): Observable<any> {
    const section = sectionType ? `${sectionType}/` : '';
    return this.api.post(`/api/v1/documents/${section}wait/send`, {documents: ids});
  }

  /**
  *  Информация по отправке
  */
  sendDocumentsInfo(ids: string[], sectionType = ''): Observable<any> {
    const section = sectionType ? `${sectionType}/` : '';
    return this.api.post(`/api/v1/documents/${section}wait/send/info`);
  }

  /**получить документ */
  receiveDocuments(): Observable<any> {
    return this.api.post(`/api/v1/documents/sended/receive`);
  }

/**
 * Скачать документ
 */
  downloadDocument(id: string): Observable<any> {
    return this.api.get(`/api/v1/documents/container/download/${id}`, null, null, 'blob');
  }

  /**
   *  проверка документов
   * @deprecated проверка документов вынесена в общий модуль
   */
  checkDocuments(ids: string[], group?: string): Observable<any> {
    return group ? this.api.post(`/api/v1/documents/${group}/check`, {documents: ids}) : this.api.post(`/api/v1/documents/check`, {documents: ids});
  }

  /**
   *  экспорт результатов проверки документов
   * @deprecated проверка документов вынесена в общий модуль
   */
  exportCheckDocuments(params: any, group?: string) {
    return group ? this.api.byte(`/api/v1/documents/${group}/check/export`, params) : this.api.byte(`/api/v1/documents/check/export`, params) ;
  }

  /**
   *  Формирование отчёта Отчет по сведениям об операциях 5861-У
   */
   reportOperationsInfo(params: any): Observable<any> {
    return this.api.byte(`/api/v1/documents/operations/reports/operationsInfo`, params);
  }

   /**
   *  Формирование отчёта Отчет по количеству отправленных сообщений
   */
    reportCountSendedMessages(params: any): Observable<any> {
      return this.api.byte(`/api/v1/documents/operations/reports/codesCount`, params);
    }

    /**
   *  Формирование отчёта Отчет по сведениям о фактах воспрепятствования
   */
     reportfactOfObstruction(params: any): Observable<any> {
      return this.api.byte("/api/v1/documents/obstruction/reports/obstructionList", params);
    }

  /**
  *  Формирование отчёта Отчет по отказам в выполнении операциия
  */
  reportRefuseList(params: any): Observable<any> {
    return this.api.byte("api/v1/documents/refuseOperation/reports/refuseList", params);
  }

  /**
  *  Формирование отчёта Отчет по отказам в заключении договоров
  */
   reportRefuseContract(params: any): Observable<any> {
    return this.api.byte("/api/v1/documents/refuseContract/reports/refuseContractsInfo", params);
  }

  /**
  *  Формирование отчёта Отчет о направленных ФЭС за период 5861-У
  */
   reportSendedFromFesList(params: any): Observable<any> {
    return this.api.byte("/api/v1/documents/reports/documentsList", params);
  }

  /**
   * Формирование отчёта о результатах импорта
  */
  reportSendedFromReceiveList(docsResultList: ReceiveDocResponseModel[], group: string,  params?: string) {
    let url = group ? `/api/v1/documents/${group}/sended/receive/download` : '/api/v1/documents/sended/receive/download';
    if (params) {
      url += `?fileName=${encodeURIComponent(params)}`;
    }
    return this.api.byte(url, docsResultList);
  }

  /**
   * Блокирование документов
   * @param type
   * @param ids
   */
  blockDocuments(ids:string[], type?: string) {
    return type ? this.api.post(`/api/v1/documents/${type}/block`, {documents: ids}) :
        this.api.post(`/api/v1/documents/block`, {documents: ids});
  }

  /**
   * Разблокировка документов
   * @param type
   * @param ids
   */
  unBlockDocuments(ids?: string[], type?: string) {
    const sentIds = ids?.length ? ids : this._docIds
    return type ? this.api.post(`/api/v1/documents/${type}/unblock`, {documents: sentIds}) :
        this.api.post(`/api/v1/documents/unblock`, {documents: sentIds});
  }

  getInitDocData(type) {
    const config = this.getConfigDoc(type)
    return this.api.get(`${config.api}/create`);
  }

  /**
   * Подготовка начальных данных для формы создания документа
   */
  prepareInitDocumentData(documentType: DocumentTypeEnum): any {
    const orgInfo = this.userSrv.getOrgInfo();
    const configuration = getDocumentConfig(documentType);
    const document = {
      informChast: {
        infBank: {
          regNomKO: orgInfo?.regNumber,
          bikko: orgInfo?.code,
          nomFlPeredaiushchij: orgInfo?.filial,
          okatoko: orgInfo?.oktmo?.substring(0,2),
        }
      },
      sluzhChast: {
        versiiaFormata: this.versiiaFormata,
        versProg: this.versProg,
        tipInf: configuration?.tipInf[0],
        idkorr: orgInfo?.idLk,
        dataSoobshcheniia: moment().format('DD/MM/YYYY')
      }
    }
    return {
      document: document
    }
  }

  /**
   * Сохранение id документа в сервис
   */
  saveDocIds(id: string[]): void {
    this._docIds = id
  }

  /**
   * Получение id документа их сервиса
   */
  getDocIds(): string[] {
    return this._docIds
  }
  /**
   * Удаление id документа из стора
   */
  removeDocIdStorage() : void {
    window.sessionStorage.removeItem('documentId')
  }

  /**
   * Добавление id документа в стор
   */
  setDocIdStorage(documentId: string) : void {
    window.sessionStorage.setItem('documentId', documentId)
  }

  /**
   * Получение токена для предотвращения отправки лишних запросов
   */
  getToken(): string {
    return window.sessionStorage.getItem('Auth-Token') || ''
  }

  //Запрос на генерацию нового идентификатора  записи

  genIdentifier(docType, id: string, tipInf: string) {
    const config = this.getConfigDoc(docType);
    return this.api.get(`${config.api}genIdentify/${id}/${tipInf}`, null, null, 'text');
  }
}
