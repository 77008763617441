import {Component, OnInit, OnDestroy, forwardRef, EventEmitter, Output, Input} from "@angular/core";
import {DictionaryService} from '@amlCore/services';
import {
  FormBuilder,
  FormGroup,
  ControlValueAccessor,
  NG_VALUE_ACCESSOR, AbstractControl
} from "@angular/forms";
import {Unsubscribable} from "rxjs";
import {ClientTypeEnum} from "@amlCore/enums";
import {IDictItemType} from "@amlCore/interfaces";

@Component({
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SubjectComponent),
      multi: true,
    }
  ],
  selector: "app-subject",
  templateUrl: "./subject.component.html",
})

export class SubjectComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Output() changeClientType = new EventEmitter();
  @Input() pageName = '';
  private subjectFormModelSubscrible: Unsubscribable;
  private clientTypeSubscrible: Unsubscribable;
  public subjectFormModel: FormGroup;
  public clientTypeEnum = ClientTypeEnum;
  public clientTypeList: IDictItemType[];
  public typeSubjects: IDictItemType[];
  private defaultTypePart = '';
  onChange(result: any) {}
  onTouch() {}

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  constructor(protected dictSrv: DictionaryService,
              private fb: FormBuilder) {}

  ngOnInit() {
    this.dictSrv.loadTypeClient().then(result => {
      this.clientTypeList = result.filter(item => item.code != 0);
    });
    this.dictSrv.getRemoteData('typesubjects').then((result) => {
       this.typeSubjects = result.map((item: any) => {return {id: item.code, name: item.name};});
    });
    this.createSubjectFormModel();
  }

  public selectedClientType(data) {
    const code = data?.code;
    if (!code) {
      this.changeClientType.emit(false);
      return;
    }
    const listClientTypes = ["legalEntity", "individualPerson", "foreignStructure"];
    const clientTypeMap = {
      1: "legalEntity",
      5: "foreignStructure",
    };
    const currentClientType = (clientTypeMap[code] || "individualPerson");
    listClientTypes
      .filter(type => type !== currentClientType )
      .forEach(type => {
        this.f(type).disable();
      });
    this.f(currentClientType).enable();
    this.changeClientType.emit(true);
  }

  private createSubjectFormModel() {
    const defaultClientTypeId = '1';
    this.subjectFormModel = this.fb.group({
        clientType: [defaultClientTypeId], // тип клиента
        typePart: [this.defaultTypePart], // тип субъекта
        legalEntity: this.fb.group({
          orgName: [null], // наименование организации
          ogrn: [null], // огрн
          inn: [null], // инн
          kpp: [null], // кпп
        }),
        individualPerson: this.fb.group({
          lastName: [null], // отчество
          firstName: [null], // фамилия
          middleName: [null], // имя
          birthDate: [null], // дата рождения
          series: [null], // серия документа
          numDoc: [null], // номер документа
          inn: [null], // ИНН
          ogrn: [null], // ОГРН и ОГРНИП
        }),
        foreignStructure: this.fb.group({
          orgName: [null], // наименование организации
          regNum: [null],  // регистрационный номер
        })
    });

    this.subjectFormModelSubscrible = this.subjectFormModel.valueChanges.subscribe((data) => {
      this.updateValue(data);
    });
    this.clientTypeSubscrible = this.f('clientType').valueChanges.subscribe((typeCode: string) => {
      this.selectedClientType({code: typeCode});
    });
  }

  writeValue(outsideValue) {
    if (outsideValue) {
      this.subjectFormModel.patchValue(outsideValue);
    } else {
      this.subjectFormModel.reset(this.subjectFormModel.value);
    }
  }

  updateValue(insideValue) {
    this.onChange(insideValue);
  }

  f(filed: string): AbstractControl {
    return this.subjectFormModel.get(filed);
  }

  ngOnDestroy() {
    this.subjectFormModelSubscrible.unsubscribe();
    this.clientTypeSubscrible.unsubscribe();
  }

}
