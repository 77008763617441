import {Injectable} from '@angular/core';
import {ApiService} from "@amlCore/services";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ImportDocumentsService {

  constructor(private _api: ApiService) { }

  /**
   *  Подготовка информации по импорту
   */
  importDocumentsStart(url = null, fileNames: string[]): Observable<any> {
    const url_info = url ? url : '/api/v1/documents/import/start';
    return this._api.post(url_info, fileNames);
  }

  /**
   *  Информация по импорту
   */
  importDocumentsInfo(url = null): Observable<any> {
    const url_info = url ? url : '/api/v1/documents/import/info';
    return this._api.post(url_info);
  }

  /**
   *  Сигнал о завершении импорта (закрытие окна)
   */
  importDocumentsEnd(url = null): void {
    const url_end = url ? url : '/api/v1/documents/import/end';
    this._api.post(url_end).subscribe(data => {});
  }

  /**
   *  Экспорт результатов импорта документов
   */
  downloadImportResults(url = null, params?: any) {
    let url_download =  url ? url : '/api/v1/documents/import/download';
    if(params) {
      url_download +=`?fileName=${encodeURIComponent(params)}`;
    }
    return this._api.byte(url_download);
  }
}
