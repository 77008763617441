import {Component, Input} from '@angular/core';
import {FormGroup, Validators} from "@angular/forms";
import {DocumentComponentBaseSub} from "@amlDocument/base";
import {DocumentBaseService} from "@amlDocument/services";

@Component({
    selector: 'app-arm-doc-view-info-ul',
    templateUrl: './view-info-ul.component.html',
    styleUrls: ["../../../base/documentForm.component.scss"],
})
export class ViewInfoUlComponent extends DocumentComponentBaseSub {
    @Input() readonly formGroupApp: FormGroup;
    @Input() readonly documentData: any;
    @Input() readonly documentType: any;

    constructor(readonly docSrv: DocumentBaseService) {
        super();
    }

    get prizn() {
      return this.priznKlient ?? this.priznUchastnika;
    }

    get priznKlient() {
      return this.documentType === 'REFUSE_CONTRACT' ?
      this.formGroupApp.parent?.get('priznakKlienta')?.value :
      this.formGroupApp.parent?.parent?.get('priznKlient')?.value
    }

    get priznUchastnika() {
      return this.formGroupApp.parent?.parent?.get('priznUchastnika')?.value;
    }
}
