import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TableReactiveUrlController} from "@amlCore/tableReactive";
import {AccessJson, FmDraftFilter} from "@amlCore/models";
import {RoleService, TableService, VisitedService} from "@amlCore/services";
import {Utils} from "@amlCore/utils";
import {IDataResponse} from "@amlCore/interfaces";
import {NgbModal, NgbModalOptions} from "@ng-bootstrap/ng-bootstrap";
import {CommonService} from '../../../services/common.service';
import {CommonFilterService} from '../../../services/common-filter.service';
import { CommonList } from '../../../model';
import { Subject } from 'rxjs';
import { getDocumentConfig, getGroupType } from '../../../enums/documentTypeEnum';
import { ImportDocumentsComponent } from '../../importDocuments/importDocuments.component';
import { DownLoadFilesComponent } from '../../downloadFiles';

@Component({
  selector: 'app-arm-archive-list',
  templateUrl: './archive-list.component.html'
})
export class ArchiveListComponent extends TableReactiveUrlController implements OnInit, OnDestroy {

  isShowVisited = true;
  groupName: string;
  dataList: Array<CommonList> = [];
  isAll = true;
  id: string;
  access: AccessJson;
  goBack = Utils.goBack;
  loading: false | null = false;
  private destroy$ = new Subject();
  private readonly _MODAL_CONFIG = {
    size: "lg",
    backdrop: "static",
    centered: true,
  } as NgbModalOptions;

  constructor(protected activatedRoute: ActivatedRoute,
              protected visitedService: VisitedService,
              protected modalDialog: NgbModal,
              protected tableService: TableService,
              protected router: Router,
              protected commonSrv: CommonService,
              protected commonFilterSrv: CommonFilterService,
              protected roleService: RoleService) {
    super(router, activatedRoute, visitedService, tableService, {sort: 'messageDate', dir: 'desc'},
        null, null, null, null, null, null, null, commonSrv, roleService);
  }

  ngOnInit() {
    this.groupName = this.activatedRoute.snapshot?.pathFromRoot[2].routeConfig.path;

    this.activatedRoute.params.subscribe(params => {
      this.id = params.id;
      this.activatedRoute.queryParams.subscribe(
        data => {
          this.isAll = !this.id;
          const paramsData = this.syncParams(data);
          paramsData.id = this.id;
          this.loadList(paramsData);
          this.total = [];
        });
    });
    this.commonFilterSrv.loadUserAccessList(this.groupName);
    super.ngOnInit()
  }

  importLoad(data?) {
    this.onPageChange({$refresh: Math.random()});
  }

  /**
   * метод получения объекта плоского фильтра
   * @param data объект фильтра с полями
   */
  public getFilterObject(data: FmDraftFilter) {
    const filter = this.tableService.getFilterSubject(data) as FmDraftFilter;
    return this.getFilterPlanObject(filter);
  }

  public onPageChange(params) {
    const filter = this.getParams({...params, ...this.filter}) as FmDraftFilter;
    filter['isAll'] = this.isAll;
    this.navigate(filter);
  }

  public onSearch() {
    const params = this.getSearchPage(this.filter) as FmDraftFilter;
    this.onPageChange(params);
  }

  /**
   * Скачать документы
   */
  downloadDocument() {
    const modal = this.modalDialog.open(DownLoadFilesComponent, {...this._MODAL_CONFIG, size: 'xl'});
    modal.componentInstance.groupName = this.groupName;
    modal.result.then((data) => {
      console.log('result ', data);
    });
  }

  /**
   * Импортирование документов в папку архива
   */
  importDocument() {
    const modal = this.modalDialog.open(
      ImportDocumentsComponent,
      this._MODAL_CONFIG
    );
    const group = getGroupType(this.groupName);
    const groupUrl = group === '' ? group : group + '/'
    modal.componentInstance.url = `/api/v1/documents/${groupUrl}import` + '?fromExternalSystem=true';
    modal.componentInstance.url_start = `/api/v1/documents/${groupUrl}import/start`;
    modal.componentInstance.url_info = `/api/v1/documents/${groupUrl}import/info`;
    modal.componentInstance.url_download = `/api/v1/documents/${groupUrl}import/download`;
    modal.componentInstance.url_end = `/api/v1/documents/${groupUrl}import/end`;
    modal.result.then((data) => {
      this.importLoad(data);
    });
  }

  creatingFix() {

  }

  private loadList(params?: any) {
    this.commonSrv.getArchiveList(this.groupName, this.getParams(params)).subscribe((data: IDataResponse<CommonList>) => {
      this.dataList = data.content;
      this.total = data.totalElements;
      this.resetSelectedAll();
    });
  }

  getConfig(docType) {
    return getDocumentConfig(docType);
  }

  changeLoading(expression: false | null) {
    this.loading = expression;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

}


